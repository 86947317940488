import React from "react";
import styled from "@emotion/styled";

import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";

import {
  Grid,
  AppBar as MuiAppBar,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";

import NavbarUserDropdown from "./NavbarUserDropdown";
import Settings from "../Settings";
import { withTheme, useTheme } from "@emotion/react"; // Import the useTheme hook
import { useMediaQuery } from "@mui/material"; // Import the useMediaQuery hook

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: rgba(50, 50, 93, 0.025) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.2) 0px 1px 3px -1px;
`;

type NavbarProps = {
  title: string;
  children?: React.ReactNode;
};

const PageNavbar: React.FC<NavbarProps> = ({ title, children }) => {
  const theme = useTheme();
  const isXlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <React.Fragment>
      <AppBar position="sticky">
        <Toolbar>
          <Grid container alignItems="center">
            <Grid
              item
              sx={{ display: { xs: "block", xl: "none" }, marginX: 2 }}
            ></Grid>

            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                pl: isXlUp ? 13 : 12,
              }}
            >
              <Typography
                variant={isMdUp ? "h3" : "h5"}
                sx={{ display: { xs: "none", sm: "block" } }}
                color="WindowText"
              >
                {title}
              </Typography>
            </Grid>

            <Grid item xs>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                flexWrap="wrap"
              >
                {children}
              </Stack>
            </Grid>
            <Grid item sx={{ ml: 4 }}>
              <NavbarNotificationsDropdown />
              <Settings />
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(PageNavbar);
