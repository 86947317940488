import CarePlanEmpty from "./carePlans/CarePlanEmpty";
import CarePlan01 from "./carePlans/CarePlan01";
import CarePlanAssesment from "./carePlans/CarePlanAssesment";

import { Box } from "@mui/material";

const CarePlanContent = (thisPatient: any) => {
  switch (thisPatient.id) {
    case 1:
      return <CarePlan01 />;
    case 2:
      return <CarePlanAssesment />;
    default:
      return <CarePlanEmpty />;
  }
};
function AssesmentCarePlan(patient: any) {
  const thisPatient = patient.patient;
  return <Box mb={6}>{CarePlanContent(thisPatient)}</Box>;
}

export default AssesmentCarePlan;
