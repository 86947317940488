import React from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Slide,
  Stack,
  TextField,
} from "@mui/material";

import { AttachFile, PersonAdd } from "@mui/icons-material";

interface NewReferralProps {
  patient: string;
  rationale: string;
  presenting_complaint: string;
  pmh: string;
  latest_obs: string;
}

function NewReferral() {
  const [open, setOpen] = React.useState(false);
  const [formValues, setFormValues] = React.useState<NewReferralProps>({
    patient: "",
    rationale: "",
    presenting_complaint: "",
    pmh: "",
    latest_obs: "",
  });

  const handlePatientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, patient: event.target.value });
  };

  const handleRationaleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormValues({ ...formValues, rationale: event.target.value });
  };

  const HandlePresentingComplaintChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormValues({ ...formValues, presenting_complaint: event.target.value });
  };

  const HandlePMHChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, pmh: event.target.value });
  };

  const HandleLatestObsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormValues({ ...formValues, latest_obs: event.target.value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFormValues({
      patient: "",
      rationale: "",
      presenting_complaint: "",
      pmh: "",
      latest_obs: "",
    });
    setOpen(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    console.log(formValues);
    handleClose();
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<PersonAdd />}
        onClick={handleClickOpen}
      >
        New Referral
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Slide}
        keepMounted
        onClose={handleClose}
        aria-describedby="Create a new referral"
        fullWidth={true}
        maxWidth="sm"
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) =>
            handleSubmit(event),
        }}
      >
        <DialogTitle sx={{ py: 5, fontWeight: 800, fontSize: "1.5em" }}>
          Create a new referral
        </DialogTitle>
        <DialogContent dividers>
          <Stack spacing={6}>
            <DialogContentText>
              Enter patient details to add refer them for MDT
            </DialogContentText>
            <FormControlLabel
              required
              control={<Checkbox />}
              label="Request for urgent MDT"
            />

            <TextField
              autoFocus
              id="patient"
              value={formValues.patient}
              label="Patient"
              type="text"
              fullWidth
              variant="filled"
              required
              onChange={handlePatientChange}
            />
            <TextField
              autoFocus
              id="rationale"
              value={formValues.rationale}
              label="Rationale"
              type="text"
              multiline
              fullWidth
              variant="filled"
              required
              onChange={handleRationaleChange}
            />
            <TextField
              id="presenting_complaint"
              value={formValues.presenting_complaint}
              label="Presenting Complaint"
              type="text"
              fullWidth
              multiline
              variant="filled"
              required
              onChange={HandlePresentingComplaintChange}
            />
            <TextField
              id="pmh"
              value={formValues.pmh}
              label="Previous Medical History"
              type="text"
              fullWidth
              multiline
              variant="filled"
              onChange={HandlePMHChange}
            />
            <TextField
              id="latest_obs"
              value={formValues.latest_obs}
              label="Latest Observations"
              type="text"
              fullWidth
              multiline
              variant="filled"
              onChange={HandleLatestObsChange}
            />
            <Button
              type="button"
              variant="outlined"
              color="primary"
              startIcon={<AttachFile />}
            >
              Attach Documents
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default NewReferral;
