import React from "react";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  Collapse,
  Divider,
  Typography,
} from "@mui/material";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { AiIcon } from "../../../icons/TLSvgIcons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReportCard from "../patientView/reports/ReportCard";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const RiskMatrixContainer = styled(Box)(({ theme }) => ({
  display: "grid",

  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
  gridTemplateRows: "69px 69px 69px 69px 69px 69px",
}));

const RiskMatrixCell = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  padding: 5,
}));
const VTE = () => {
  const content = `
### DVT: 1 (Low Probability)

- Cancer (active or within 6 months) —> 1

### PE: 5 (Moderate Probability)
- Cancer (active or within 6 months) —> 1
- Malignancy (on treatment or palliative) —> 1
- PE is the most likely diagnosis —> 3

### Summary
The patient has a low probability of DVT and a moderate probability of PE. Given her advanced cancer, chronic respiratory illness, and palliative care needs, careful monitoring and potential prophylactic measures are recommended to manage her VTE risk while focusing on her overall quality of life.

  `;
  return (
    <ReportCard
      title="VTE Risk Assessment: Low/Moderate"
      content={content}
      open={false}
      padding={2}
    />
  );
};
const ASA = () => {
  const content = `A serious condition that has an impact on a person's overall health`;
  return (
    <ReportCard title="ASA: 3" content={content} open={false} padding={2} />
  );
};

const CFS = () => {
  const content = `
### Clinical Frailty Scale (CFS): 6-7 

(Moderately to Severely Frail)

The patient demonstrates significant functional impairment, physiological compromise, and complex care needs, indicating moderate to severe frailty. They require assistance with activities of daily living and mobility tasks, and their overall health status places them at high risk of adverse outcomes.
  `;
  return (
    <ReportCard
      title="Clinical Frailty Scale (CFS): 6-7 "
      content={content}
      open={false}
      padding={2}
    />
  );
};

const CCI = () => {
  const content = `
### Charlson Comorbidity Index (CCI): 8 points

- COPD —> 1
- NSCLC —> 6
- T2DM —> 1
  `;
  return (
    <ReportCard
      title="Charlson Comorbidity Index: 8"
      content={content}
      open={false}
      padding={2}
    />
  );
};

const RiskAssessment01 = () => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const comboHeader = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 3,
      }}
    >
      <AiIcon />
      Draft Risk Assessment
    </Box>
  );

  return (
    <React.Fragment>
      <Typography variant="h4" gutterBottom>
        Risks
      </Typography>
      <Card variant="outlined" sx={{ mb: 2, borderColor: "#FFCEA2" }}>
        <CardHeader
          sx={{
            backgroundColor: "#FFFBF7",
            color: "#b35500",
            p: 2,
          }}
          title={comboHeader}
          action={
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider />
          <RiskMatrixContainer>
            <RiskMatrixCell sx={{ bgcolor: "primary.main" }}></RiskMatrixCell>
            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                INSIGNIFICANT
              </Typography>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                (1)
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                MINOR
              </Typography>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                (2)
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                SIGNIFICANT
              </Typography>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                (3)
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                MAJOR
              </Typography>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                (4)
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                SEVERE
              </Typography>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                (5)
              </Typography>
            </RiskMatrixCell>

            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                ALMOST CERTAIN (5)
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FEFE00",
              }}
            ></RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FFA500",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FF0000",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#AA0000",
                color: "white",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                Impaired functional status
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#AA0000",
                color: "white",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>

            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                LIKELY (4)
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FEFE00",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FEFE00",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FFA500",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FF0000",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                Cancer progression and complications
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#AA0000",
                color: "white",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                Respiratory failure / complications-COPD & lung CA
              </Typography>
            </RiskMatrixCell>

            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                MODERATE (3)
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#92D051",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FEFE00",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FEFE00",
              }}
            >
              <Typography
                aria-owns={open ? "athritis" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FFA500",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FF0000",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>

            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                UNLIKELY (2)
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#00be53",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#92D051",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FEFE00",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                Cardiovascular complications
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FEFE00",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FFA500",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>

            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                RARE (1)
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#00be53",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#00be53",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#92D051",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FEFE00",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FEFE00",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              ></Typography>
            </RiskMatrixCell>
          </RiskMatrixContainer>
        </Collapse>
      </Card>
      <CCI />
      <VTE />
      <CFS />
      <ASA />
    </React.Fragment>
  );
};

export default RiskAssessment01;
