import * as React from "react";

import { Box, Typography } from "@mui/material";

import * as icons from "../../../../patient/timeline/TimelineIcons";
import ReportCard from "./ReportCard";
import XRayPicture from "../../../../../assets/images/x-ray.png";
import EKG from "../../../../../assets/images/ekg.png";
import PETScan from "../../../../../assets/images/cardiac-pet-scan.png";
import PreOpLabs from "../../../../patient/mdt/PreOpLabs";

const MDTReports02 = () => {
  return (
    <Box>
      <Typography variant="h4" sx={{ pl: 2, mt: 4, mb: 4 }}>
        Report Timeline
      </Typography>
      {/* <Typography variant="body2" sx={{ pl: 2, mb: 1 }}>
        January 20th, 2019
      </Typography> */}

      <ReportCard
        title={
          new Date("2020-01-20").toLocaleDateString() +
          " - Hospitalisation (NSTEMI)"
        }
        content="NSTEMI in Left Anterior Descending coronary distribution with preserved LVEF (50%); successfully stented without complications."
        color="#777777"
        bgColor="#F8F9FC"
        icon="local_hospital"
        open={false}
        key={1}
        date="2020-05-20"
        time="13:00"
        by="Dr Eric Ries"
      />

      <ReportCard
        title={
          new Date("2024-04-15").toLocaleDateString() +
          " -  GP Visit & Referral"
        }
        content="Regular annual checkup, noteworthy for right hip discomfort limiting his ability to exercise, and making climbing up to his second story flat difficult. Referral to orthopaedics."
        color="#777777"
        bgColor="#F8F9FC"
        speciality="G.P."
        icon="medical_services"
        open={false}
        key={3}
        date="2024-04-15"
        time="13:00"
        by="Dr Nick Taylor"
      />
      <ReportCard
        title={
          new Date("2024-04-29").toLocaleDateString() + "-  Orthopaedic Consult"
        }
        content="Hip series shows degenerative changes to R hip consistent with osteoarthritis. Candidate for hip arthroplasty if he can be cleared from a CV perspective."
        color="#777777"
        bgColor="#F8F9FC"
        icon="local_hospital"
        speciality="Orthopaedic Surgeon"
        open={false}
        key={4}
        date="2024-04-29"
        time="13:00"
        by="Mr Cash"
      />
      <ReportCard
        title={new Date("2024-04-29").toLocaleDateString() + " -  Hip X-Rays"}
        content="Osteoarthritic changes, R hip."
        color="#777777"
        bgColor="#F8F9FC"
        speciality="Staff Radiologist"
        icon={<icons.XrayIcon />}
        picture={XRayPicture}
        open={false}
        key={5}
        date={new Date("2024-04-29").toLocaleDateString()}
        time="13:00"
        by="Dr John Ferrier"
        url="/"
      />
      <ReportCard
        title={
          new Date("2024-05-10").toLocaleDateString() + " - Cardiac PET scan"
        }
        content="Pharmacologic cardiac stress test/PET scan: no focal ischemic abnormalities"
        color="#777777"
        bgColor="#F8F9FC"
        speciality="Cardiologist"
        icon={<icons.MonitorHeart />}
        picture={PETScan}
        open={false}
        key={6}
        date={new Date("2024-05-10").toLocaleDateString()}
        time="13:00"
        by="Dr Mark Appleby"
        url="/"
      />
      <PreOpLabs />

      <ReportCard
        title={new Date("2024-01-10").toLocaleDateString() + " -  EKG"}
        content="Normal sinus rhythm; old anterior wall MI; no apparent acute ischemia."
        color="#777777"
        bgColor="#F8F9FC"
        icon={<icons.MonitorHeart />}
        speciality="Cardiologist"
        picture={EKG}
        open={false}
        key={7}
        date={new Date("2024-05-10").toLocaleDateString()}
        time="13:00"
        by="Dr Mark Appleby"
        url="/"
      />
    </Box>
  );
};

export default MDTReports02;
