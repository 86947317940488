import * as React from "react";

import { Box, Typography } from "@mui/material";

const MDTReportsEmpty = () => {
  return (
    <Box>
      <Typography variant="h4" sx={{ pl: 2, mt: 4, mb: 4 }}>
        Report Timeline
      </Typography>
      {/* <Typography variant="body2" sx={{ pl: 2, mb: 1 }}>
        January 20th, 2019
      </Typography> */}
    </Box>
  );
};

export default MDTReportsEmpty;
