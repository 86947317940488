import React from "react";

import { Box, Stack, Typography } from "@mui/material";

import AiApprovalBox from "../../../../aiBox/AiApprovalBox";
import PictureSwitch from "../../../../patient/mdt/PictureSwitch";
import NursePic from "../../../../../assets/images/avatars/nurse.jpg";

import MarkPic from "../../../../../assets/images/avatars/MarkAppleby.jpg";
import DCashPic from "../../../../../assets/images/avatars/mr-david-cash.jpg";

import IvanovaPic from "../../../../../assets/images/avatars/dobrinka-ivanova.png";
import NickPic from "../../../../../assets/images/avatars/NickTaylor2.png";
// import GuestPic from "../../../../../assets/images/avatars/guest-user.jpg";
import ScottPic from "../../../../../assets/images/avatars/Scott.png";

const Content = () => {
  return (
    <div>
      <p>
        The main priorities for this patient are optimal symptomatic management,
        discussing goals of care, advance care planning, and providing her with
        support to maximise her remaining quality of life through a palliative
        care approach given her advanced illnesses. Close communication between
        her oncology, pulmonary, and palliative care teams will be essential.
      </p>

      <h3>Possible interventions to reduce risks:</h3>
      <ul>
        <li>
          Smoking cessation counsellilng/therapy, even at this stage, can
          provide some benefit.
        </li>
        <li>Pulmonary rehabilitation to optimise remaining lung function.</li>
        <li>
          Nutritional support for weight maintenance during cancer treatment.
        </li>
        <li>
          Careful medication review to ensure no drugs worsen respiratory
          status.
        </li>
        <li>
          Advance care planning to ensure her wishes regarding code status,
          intubation, etc. are documented.
        </li>
        <li>
          Hospice care evaluation if she opts to pursue comfort measures only.
        </li>
      </ul>
    </div>
  );
};

function CarePlan01() {
  return (
    <Box sx={{}}>
      <Typography variant="h4" sx={{ mb: 1 }}>
        Care Plan
      </Typography>
      <Stack
        direction="row"
        spacing={0}
        alignItems="center"
        flexWrap="wrap"
        mb={2}
      >
        <PictureSwitch checked={false} picture={ScottPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={DCashPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={MarkPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={IvanovaPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={NickPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={NursePic}></PictureSwitch>
      </Stack>

      <AiApprovalBox approved={false} approvedBy="" content={<Content />} />
    </Box>
  );
}

export default CarePlan01;
