const caseListData = [
  {
    id: 1,
    client_name: "Mary Johnson",
    case_name: "Johnson vs. NHS",
    lead_solicitor: "James Oliver",
    case_type: "Medical Negligence",
    case_stage: "Pre-action stage",
    next_deadline: "2024-11-18",
    next_hearing: "2024-12-15",
    key_documents: "Expert Witness Report, Lab Results",
  },
  {
    id: 2,
    client_name: "John Smith",
    case_name: "Smith vs. Private Health Services",
    lead_solicitor: "Sarah Hughes",
    case_type: "Personal Injury",
    case_stage: "Evidence Gathering",
    next_deadline: "2024-11-20",
    next_hearing: "2024-11-29",
    key_documents: "Incident Report, Witness Statements",
  },
  {
    id: 3,
    client_name: "Elijah Bailey",
    case_name: "Bailey vs. NHS",
    lead_solicitor: "David Clark",
    case_type: "Medical Negligence",
    case_stage: "Letter of Claim",
    next_deadline: "2024-11-23",
    next_hearing: "2024-11-28",
    key_documents: "Medical Records, Treatment Plan",
  },
  {
    id: 4,
    client_name: "Jane Doe",
    case_name: "Doe vs. NHS",
    lead_solicitor: "Emily Carter",
    case_type: "Medical Malpractice",
    case_stage: "Case Management Conference",
    next_deadline: "2024-11-25",
    next_hearing: "2024-12-30",
    key_documents: "Medical Reports, Imaging Scans",
  },
  {
    id: 5,
    client_name: "Michael Brown",
    case_name: "Brown vs. Private Clinic",
    lead_solicitor: "Michael Stone",
    case_type: "Medical Malpractice",
    case_stage: "Letter of Response",
    next_deadline: "2024-11-27",
    next_hearing: null,
    key_documents: "Surgical Records, Anesthesia Logs",
  },
  {
    id: 6,
    client_name: "Linda Green",
    case_name: "Green vs. NHS",
    lead_solicitor: "Anna Thompson",
    case_type: "Product Liability",
    case_stage: "Discovery",
    next_deadline: "2024-11-22",
    next_hearing: null,
    key_documents: "Product Analysis, Usage Records",
  },
];

export default caseListData;
