import { Box } from "@mui/material";
import MDTReportsEmpty from "./reports/MDTReportsEmpty";
import MDTReports01 from "./reports/MDTReports01";
import MDTReports02 from "./reports/MDTReports02";

const ReportContent = (thisPatient: any) => {
  switch (thisPatient.id) {
    case 1:
      return <MDTReports01 />;
    case 2:
      return <MDTReports02 />;
    default:
      return <MDTReportsEmpty />;
  }
};

function ReportTimeline(patient: any) {
  const thisPatient = patient.patient;

  return <Box>{ReportContent(thisPatient)}</Box>;
}

export default ReportTimeline;
