import React from "react";
import { SidebarItemsType } from "../../../types/sidebar";

import { AdminPanelSettings, Groups } from "@mui/icons-material";
import TLIcon from "../../icons/TLIcon";

const PatientIcon = () => <TLIcon name="personal_injury" />;
const RamsayIcon = () => <TLIcon name="RamsayIcon" />;

const homeSection = [
  {
    href: "/tlc/",
    icon: RamsayIcon,
    title: "TLC Dashboard",
    amount: 0,
    category: "Home",
  },
] as SidebarItemsType[];

const listsSection = [
  {
    href: "/patients",
    icon: PatientIcon,
    title: "PATIENTS",
    amount: 0,
    children: [],
  },
] as SidebarItemsType[];

const mdtSection = [
  {
    href: "/mdts",
    icon: Groups,
    title: "MDT MEETINGS",
    children: [],
  },
] as SidebarItemsType[];

const screensSection = [
  {
    href: "/admin",
    icon: AdminPanelSettings,
    title: "ADMIN",
    children: [],
  },
] as SidebarItemsType[];

const tlcItems = [
  {
    title: "",
    pages: homeSection,
    custom: false,
  },
  {
    title: "Lists",
    pages: listsSection,
    custom: false,
  },

  {
    title: "MDT",
    pages: mdtSection,
    custom: true,
  },
  {
    title: "Demo",
    pages: screensSection,
    custom: false,
  },
];

export default tlcItems;
