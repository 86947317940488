import React, { useState, useEffect, ReactNode } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { MessageCircle } from "react-feather";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { faker } from "@faker-js/faker";

import PageNavbar from "../../components/navbar/PageNavbar";

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Stack,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { orange, green, red } from "@mui/material/colors";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)<{ pb?: number }>`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const Divider = styled(MuiDivider)(spacing);

const TaskWrapper = styled(Card)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-bottom: ${(props) => props.theme.spacing(4)};
  cursor: grab;

  &:hover {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)};
  }
`;

const MessageCircleIcon = styled(MessageCircle)`
  color: ${(props) => props.theme.palette.grey[500]};
  vertical-align: middle;
`;

const TaskBadge = styled.div`
  background: ${(props) => props.color};
  width: 40px;
  height: 6px;
  border-radius: 6px;
  display: inline-block;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const TaskNotifications = styled.div`
  display: flex;
  position: absolute;
  bottom: ${(props) => props.theme.spacing(4)};
  right: ${(props) => props.theme.spacing(4)};
`;

const TaskNotificationsAmount = styled.div`
  color: ${(props) => props.theme.palette.grey[500]};
  font-weight: 600;
  margin-right: ${(props) => props.theme.spacing(1)};
  line-height: 1.75;
`;

const Typography = styled(MuiTypography)(spacing);

interface LaneProps {
  column: {
    title: string;
    description: string;
  };
  children: ReactNode;
}

interface TaskProps {
  item: {
    badges: any;
    name: string;
    notifications: any;
    mrn: string;
    dob: string;
    Procedure: string;
    Surgeon: string;
    Anaesthetist: string;
  };
}

const mockItems1 = [
  {
    id: "1",
    name: "DOE, Jane",
    badges: [green[600]],
    notifications: 0,
    mrn: "456 789 1234",
    dob: "1956-01-01",
    Procedure: "Right Total Hip Replacement",
    Surgeon: "Mrs. Alice Thompson",
    Anaesthetist: "Dr. Mark Johnson",
  },
  {
    id: "2",
    name: "ABARA, Sekani",
    badges: [green[600]],
    notifications: 0,
    mrn: "012 345 6789",
    dob: "1951-03-05",
    Procedure: "Left Knee Arthroscopy",
    Surgeon: "Mr. David Cash",
    Anaesthetist: "Dr. Naomi Smith",
  },
  {
    id: "3",
    name: "JOHNSON, Sara",
    badges: [orange[600]],
    notifications: 0,
    mrn: "234 567 8901",
    dob: "1996-09-23",
    Procedure: "Left ACL Reconstruction",
    Surgeon: "Mr. Charles Martin",
    Anaesthetist: "Dr. Elizabeth Turner",
  },
];

const mockItems2 = [
  {
    id: "4",
    name: "WILLIAMS, Robert",
    badges: [orange[600]],
    notifications: 0,
    mrn: "345 678 9012",
    dob: "1970-12-12",
    Procedure: "Right Shoulder Arthroscopy",
    Surgeon: "Mr. James Williams",
    Anaesthetist: "Dr. Laura Evans",
  },
];

const mockItems3 = [
  {
    id: "5",
    name: "DAVIS, Emily",
    badges: [green[600]],
    notifications: 0,
    mrn: "456 789 0123",
    dob: "1946-09-23",
    Procedure: "Right Rotator Cuff Repair",
    Surgeon: "Mrs. Patricia Moore",
    Anaesthetist: "Dr. William Scott",
  },
];
const mockItems4 = [
  {
    id: "6",
    name: "THOMPSON, David",
    badges: [red[600]],
    notifications: 0,
    mrn: "567 890 1234",
    dob: "2008-05-17",
    Procedure: "Spinal Fusion",
    Surgeon: "Mr. Richard Lee",
    Anaesthetist: "Dr. David Baker",
  },
  {
    id: "7",
    name: "LEE, Lisa",
    badges: [green[600]],
    notifications: 0,
    mrn: "678 901 2345",
    dob: "1988-03-05",
    Procedure: "Left Total Knee Replacement",
    Surgeon: "Mr. George Clark",
    Anaesthetist: "Dr. Karen Adams",
  },
];
const mockItems5 = [
  {
    id: "8",
    name: "WILSON, James",
    badges: [green[600]],
    notifications: 0,
    mrn: "789 012 3456",
    dob: "1958-07-12",
    Procedure: "Right Carpal Tunnel Release",
    Surgeon: "Ms Emily Harris",
    Anaesthetist: "Dr. Brian Lewis",
  },
];
const mockItems6 = [
  {
    id: "9",
    name: "SINGH, Meeta",
    badges: [green[600]],
    notifications: 0,
    mrn: "890 123 4567",
    dob: "1979-11-29",
    Procedure: "Left Ankle Fracture Repair",
    Surgeon: "Mr. Matthew Wright",
    Anaesthetist: "Dr. Rachel King",
  },
];

const mockColumns = {
  [faker.datatype.uuid()]: {
    title: "Operating Theatre 1",
    description: "",
    items: mockItems1,
  },
  [faker.datatype.uuid()]: {
    title: "Operating Theatre 2",
    description: "",
    items: mockItems2,
  },
  [faker.datatype.uuid()]: {
    title: "Operating Theatre 3",
    description: "",
    items: mockItems3,
  },
  [faker.datatype.uuid()]: {
    title: "Operating Theatre 4",
    description: "",
    items: mockItems4,
  },
  [faker.datatype.uuid()]: {
    title: "Operating Theatre 5",
    description: "",
    items: mockItems5,
  },
  [faker.datatype.uuid()]: {
    title: "Operating Theatre 6",
    description: "",
    items: mockItems6,
  },
};

const onDragEnd = (result: DropResult, columns: any, setColumns: any) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

const Lane = ({ column, children }: LaneProps) => {
  return (
    <Grid item xs={12} sm={6} md={2}>
      <Card mb={6}>
        <CardContent pb={0}>
          <Typography variant="h6" gutterBottom>
            {column.title}
          </Typography>
          <Typography variant="body2" mb={4}>
            {column.description}
          </Typography>
          {children}
        </CardContent>
      </Card>
    </Grid>
  );
};
const ReviewLane = ({ column, children }: LaneProps) => {
  return (
    <Grid item xs={12} sm={6} md={2}>
      <Card mb={6}>
        <CardContent pb={0}>
          <Typography variant="h6" gutterBottom>
            {column.title}
          </Typography>
          <Typography variant="body2" mb={4}>
            {column.description}
          </Typography>
          {children}
        </CardContent>
      </Card>
    </Grid>
  );
};
const ApprovedLane = ({ column, children }: LaneProps) => {
  return (
    <Grid item xs={12} sm={6} md={2}>
      <Card mb={6}>
        <CardContent pb={0}>
          <Typography variant="h6" gutterBottom>
            {column.title}
          </Typography>
          <Typography variant="body2" mb={4}>
            {column.description}
          </Typography>
          {children}
        </CardContent>
      </Card>
    </Grid>
  );
};

const Task = ({ item }: TaskProps) => {
  return (
    <TaskWrapper mb={4}>
      {/* <Link component={NavLink} to="/live/2" color="inherit" underline="none"> */}
      <TaskWrapperContent>
        {item.badges &&
          item.badges.map((color: any, i: number) => (
            <TaskBadge color={color} key={i} />
          ))}

        <Typography variant="h5" sx={{ mt: 1 }}>
          {item.name}
        </Typography>
        <Typography variant="caption">MRN: {item.mrn}</Typography>
        <br />
        <Typography variant="caption">
          DOB: {new Date(item.dob).toLocaleDateString()}
        </Typography>
        {/* <Typography variant="h6">Procedure:</Typography> */}
        <Typography variant="h6" sx={{ mt: 3, mb: 3 }}>
          {item.Procedure}
        </Typography>
        <Typography variant="body1">Surgeon: </Typography>
        <Typography variant="h6">{item.Surgeon}</Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          Anaesthetist:{" "}
        </Typography>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {item.Anaesthetist}
        </Typography>

        {!!item.notifications && item.notifications > 0 && (
          <TaskNotifications>
            <TaskNotificationsAmount>
              {item.notifications}
            </TaskNotificationsAmount>
            <MessageCircleIcon />
          </TaskNotifications>
        )}
      </TaskWrapperContent>
      {/* </Link> */}
    </TaskWrapper>
  );
};
function Tools() {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      flexGrow={1}
      sx={{ pr: 2 }}
    >
      <Box sx={{ pr: 4 }}>
        <Stack
          direction="row"
          spacing={3}
          alignItems="baseline"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Typography variant="h3" color="InfoText">
            Pre-Assesment
          </Typography>
          <Typography variant="h5" color="InfoText">
            Orthopaedics
          </Typography>
          <Typography variant="h5" color="InfoText">
            {new Date().toDateString()}
          </Typography>
          {/* <Typography variant="h5" color="InfoText">
            Location: Room 1
          </Typography> */}
        </Stack>
      </Box>
    </Stack>
  );
}

function Management() {
  const [columns, setColumns] = useState(mockColumns);
  const [documentReady, setDocumentReady] = useState(false);

  useEffect(() => {
    setDocumentReady(true);
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Management" />
      <PageNavbar title="">
        <Tools />
      </PageNavbar>
      <Box sx={{ p: 6 }}>
        {/* <Typography variant="h3" gutterBottom display="inline">
          Discharge Planning
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/">
            Dashboard
          </Link>
          <Typography>Management</Typography>
        </Breadcrumbs> */}

        {/* <Divider my={6} /> */}
        {/* <Typography variant="h4" gutterBottom>
          Tue - August 15th
        </Typography> */}

        <Grid container spacing={6}>
          {!!documentReady && (
            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
            >
              {Object.entries(columns)
                .slice(0, 1)
                .map(([columnId, column]) => (
                  <ReviewLane key={columnId} column={column}>
                    <Droppable droppableId={columnId} key={columnId}>
                      {(provided) => {
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              minHeight: 50,
                            }}
                          >
                            {column.items.map((item, index) => {
                              return (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <Link
                                          component={NavLink}
                                          to={"/assesment/" + item.id}
                                          color="inherit"
                                          underline="none"
                                        >
                                          <Task item={item} />
                                        </Link>
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </ReviewLane>
                ))}
              {Object.entries(columns)
                .slice(1, 2)
                .map(([columnId, column]) => (
                  <ApprovedLane key={columnId} column={column}>
                    <Droppable droppableId={columnId} key={columnId}>
                      {(provided) => {
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              minHeight: 50,
                            }}
                          >
                            {column.items.map((item, index) => {
                              return (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <Link
                                          component={NavLink}
                                          to={"/assesment/" + item.id}
                                          color="inherit"
                                          underline="none"
                                        >
                                          <Task item={item} />
                                        </Link>
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </ApprovedLane>
                ))}
              {Object.entries(columns)
                .slice(2)
                .map(([columnId, column]) => (
                  <Lane key={columnId} column={column}>
                    <Droppable droppableId={columnId} key={columnId}>
                      {(provided) => {
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              minHeight: 50,
                            }}
                          >
                            {column.items.map((item, index) => {
                              return (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <Link
                                          component={NavLink}
                                          to={"/assesment/" + item.id}
                                          color="inherit"
                                          underline="none"
                                        >
                                          <Task item={item} />
                                        </Link>
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </Lane>
                ))}
            </DragDropContext>
          )}
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default Management;
