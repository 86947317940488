const exampleDoctors = [
  {
    id: 1,
    name: "Dr Eric Ries",
    role: "Staff Radiologist",
  },
  {
    id: 2,
    name: "Dr Jane Smith",
    role: "Cardiologist",
  },
  {
    id: 3,
    name: "Dr John Roe",
    role: "Neurologist",
  },
  {
    id: 4,
    name: "Dr Emily Davis",
    role: "Pediatrician",
  },
  {
    id: 5,
    name: "Dr Michael Brown",
    role: "Orthopedic Surgeon",
  },
  {
    id: 6,
    name: "Dr Sarah Wilson",
    role: "Dermatologist",
  },
  {
    id: 7,
    name: "Dr David Johnson",
    role: "General Practitioner",
  },
  {
    id: 8,
    name: "Dr Laura Martinez",
    role: "Oncologist",
  },
  {
    id: 9,
    name: "Dr Robert Garcia",
    role: "Endocrinologist",
  },
  {
    id: 10,
    name: "Dr Linda Rodriguez",
    role: "Gastroenterologist",
  },
  {
    id: 11,
    name: "Dr James Lee",
    role: "Hematologist",
  },
  {
    id: 12,
    name: "Dr Patricia Walker",
    role: "Nephrologist",
  },
  {
    id: 13,
    name: "Dr Charles Hall",
    role: "Ophthalmologist",
  },
  {
    id: 14,
    name: "Dr Barbara Allen",
    role: "Psychiatrist",
  },
  {
    id: 15,
    name: "Dr Christopher Young",
    role: "Pulmonologist",
  },
  {
    id: 16,
    name: "Dr Jessica King",
    role: "Rheumatologist",
  },
  {
    id: 17,
    name: "Dr Daniel Wright",
    role: "Urologist",
  },
  {
    id: 18,
    name: "Dr Karen Scott",
    role: "Allergist",
  },
  {
    id: 19,
    name: "Dr Matthew Green",
    role: "Anesthesiologist",
  },
  {
    id: 20,
    name: "Dr Susan Adams",
    role: "Pathologist",
  },
  {
    id: 21,
    name: "Mr Ronald Baker",
    role: "Physiotherapist",
  },
];

export default exampleDoctors;
