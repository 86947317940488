import React from "react";
import styled from "@emotion/styled";

import { Box, AppBar as MuiAppBar, Stack, Typography } from "@mui/material";

import NavbarNotificationsDropdown from "../NavbarNotificationsDropdown";
import NavbarUserDropdown from "../NavbarUserDropdown";
import Settings from "../../Settings";

import { useTheme } from "@emotion/react"; // Import the useTheme hook
import { useMediaQuery } from "@mui/material"; // Import the useMediaQuery hook

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: rgba(50, 50, 93, 0.025) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.2) 0px 1px 3px -1px;
  margin-bottom: 12px;
`;

type NavbarProps = {
  title?: string;
  leftTools?: React.ReactNode;
  children?: React.ReactNode;
  rightTools?: React.ReactNode;
  breakpointLeft?: string;
  breakpointChildren?: string;
  breakpointRight?: string;
};
type PageToolbarContainerProps = {
  children: React.ReactNode;
};
type TitleProps = {
  title?: string;
};
type ResponsiveToolsProps = {
  children: React.ReactNode;
  breakpoint?: string;
};

const Spacer = () => {
  return <Box sx={{ flexGrow: 1 }} />;
};

const PageToolbarContainer: React.FC<PageToolbarContainerProps> = ({
  children,
}) => {
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      sx={{
        minHeight: 64,
        ml: { xs: 16, sm: 17, xxl: 4 },
        pr: { xs: 1, sm: 3 },
      }}
    >
      {children}
    </Stack>
  );
};

const PageToolbar: React.FC<PageToolbarContainerProps> = ({ children }) => {
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      flexWrap="wrap"
      useFlexGap
      spacing={2}
      sx={{ width: "100%", py: 2 }}
    >
      {children}
    </Stack>
  );
};

const Title: React.FC<TitleProps> = ({ title }) => {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box sx={{ minHeight: 48, display: "flex", alignItems: "center" }}>
      <Typography
        sx={{ lineHeight: 1, pr: 2 }}
        variant={isSmUp ? "h3" : "h4"}
        color="WindowText"
      >
        {title}
      </Typography>
    </Box>
  );
};

const LeftTools: React.FC<ResponsiveToolsProps> = ({
  children,
  breakpoint,
}) => {
  const display = breakpoint
    ? { xs: "none", [breakpoint]: "flex" }
    : { xs: "flex" };
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ display: display, minHeight: 48 }}
    >
      {children}
    </Stack>
  );
};

const ChildTools: React.FC<ResponsiveToolsProps> = ({
  children,
  breakpoint,
}) => {
  const display = breakpoint
    ? { xs: "none", [breakpoint]: "flex" }
    : { xs: "flex" };
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ flexGrow: 1 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ display: display, flexGrow: 1, minHeight: 48 }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

const RightTools: React.FC<ResponsiveToolsProps> = ({
  children,
  breakpoint,
}) => {
  const display = breakpoint
    ? { xs: "none", [breakpoint]: "flex" }
    : { xs: "flex" };
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ display: display, minHeight: 48 }}
    >
      {children}
    </Stack>
  );
};

const TLPagebar: React.FC<NavbarProps> = ({
  title,
  leftTools,
  children,
  rightTools,
  breakpointLeft,
  breakpointChildren,
  breakpointRight,
}) => {
  const displayLeft = breakpointLeft
    ? { xs: "flex", [breakpointLeft]: "none" }
    : { xs: "none" };
  const displayRight = breakpointRight
    ? { xs: "flex", [breakpointRight]: "none" }
    : { xs: "none" };
  const displayChildren = breakpointChildren
    ? { xs: "flex", [breakpointChildren]: "none" }
    : { xs: "none" };
  return (
    <React.Fragment>
      <AppBar position="sticky">
        <PageToolbarContainer>
          <PageToolbar>
            <Title title={title} />
            {leftTools && (
              <LeftTools breakpoint={breakpointLeft}>{leftTools}</LeftTools>
            )}

            <ChildTools breakpoint={breakpointChildren}>{children}</ChildTools>

            {rightTools && (
              <RightTools breakpoint={breakpointRight}>{rightTools}</RightTools>
            )}
          </PageToolbar>
          <Stack direction="row" alignItems="flex-start" sx={{ pt: 2, ml: 2 }}>
            <NavbarNotificationsDropdown />
            <Settings />
            <NavbarUserDropdown />
          </Stack>
        </PageToolbarContainer>
      </AppBar>

      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="center"
        flexWrap="wrap"
        useFlexGap
        spacing={2}
        sx={{ width: "100%", px: 6 }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{ display: displayLeft }}
        >
          {leftTools}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ display: displayChildren }}
        >
          {children}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ display: displayRight }}
        >
          {rightTools}
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

export { Spacer };

export default TLPagebar;
