import React from "react";

import { Box, Stack, Typography } from "@mui/material";

import AiApprovalBox from "../../../../aiBox/AiApprovalBox";
import PictureSwitch from "../../../../patient/mdt/PictureSwitch";
import NursePic from "../../../../../assets/images/avatars/nurse.jpg";

import MarkPic from "../../../../../assets/images/avatars/MarkAppleby.jpg";
import DCashPic from "../../../../../assets/images/avatars/mr-david-cash.jpg";

import IvanovaPic from "../../../../../assets/images/avatars/dobrinka-ivanova.png";
import NickPic from "../../../../../assets/images/avatars/NickTaylor2.png";
// import GuestPic from "../../../../../assets/images/avatars/guest-user.jpg";
import ScottPic from "../../../../../assets/images/avatars/Scott.png";

const Content = () => {
  return (
    <div>
      <h3>Risks:</h3>
      <ul>
        <li>
          Cardiac complications - With his history of myocardial infarction and
          stent placement, he is at higher risk of perioperative cardiac events
          like myocardial infarction or cardiac arrest. His ongoing tobacco use
          also increases this risk.
        </li>
        <li>
          Respiratory complications - His obesity and tobacco use put him at
          increased risk of postoperative pulmonary complications like
          pneumonia, prolonged intubation, or respiratory failure.
        </li>
        <li>
          Thromboembolic events - His reduced mobility puts him at increased
          risk of DVT/PE in the postoperative period.
        </li>
        <li>
          Surgical site infection - His obesity is a risk factor for wound
          complications.
        </li>
        <li>
          Functional decline - With advanced age and living alone, he is at
          higher risk of losing independence after surgery.
        </li>
      </ul>
      <p>
        <br />
      </p>
      <h3>Possible interventions to reduce risks:</h3>
      <ul>
        <li>
          Preoperative cardiology clearance - Given his cardiac history, further
          risk assessment and optimisation by a cardiologist could help reduce
          his cardiac risk.
        </li>
        <li>
          Smoking cessation - Encouraging him to quit smoking at least 4-6 weeks
          before surgery could help reduce his pulmonary and cardiac risks.
        </li>
        <li>
          Prehab physical therapy - Starting PT prior to surgery can help
          optimise his functional status preoperatively.
        </li>
        <li>
          Regional anaesthesia - Use of spinal or epidural anaesthesia may
          reduce some cardiac/pulmonary risks vs general anaesthesia.
        </li>
        <li>
          VTE prophylaxis - Use of mechanical and pharmacological prophylaxis to
          prevent blood clots.
        </li>
        <li>
          Early mobility - Having PT work on ambulation as soon as feasible
          post-op can prevent deconditioning.
        </li>
        <li>
          Discharge planning - Proactive planning for safe discharge destination
          and follow-up care to help maintain independence.
        </li>
      </ul>
    </div>
  );
};

function CarePlan02() {
  return (
    <Box sx={{}}>
      <Typography variant="h4" sx={{ mb: 1 }}>
        Care Plan
      </Typography>
      <Stack
        direction="row"
        spacing={0}
        alignItems="center"
        flexWrap="wrap"
        mb={2}
      >
        <PictureSwitch checked={false} picture={ScottPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={DCashPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={MarkPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={IvanovaPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={NickPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={NursePic}></PictureSwitch>
      </Stack>

      <AiApprovalBox approved={false} approvedBy="" content={<Content />} />
    </Box>
  );
}

export default CarePlan02;
