import React from "react";
import { Helmet } from "react-helmet-async";
import PageNavbar from "../../components/navbar/PageNavbar";
import { Link } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { useState } from "react";
import styled from "@emotion/styled";
import MdtTeam from "../../components/patient/mdt/MdtTeam";

import Comments from "../../components/ramsay/mdt/patientView/Comments";
// import DiscussionCarePlan from "../../components/ramsay/mdt/patientView/DiscussionCarePlan";

// import Rationale from "../../components/ramsay/mdt/patientView/Rationale";
import ReportTimeline from "../../components/ramsay/mdt/patientView/ReportTimeline";
import Risk from "../../components/ramsay/mdt/patientView/Risk";
import VitruvianCondition from "../../components/ramsay/mdt/patientView/VitruvianCondition";
import MeetingCarePlan from "../../components/ramsay/mdt/patientView/MeetingCarePlan";
import Agenda from "../../components/ramsay/mdt/patientView/Agenda";
import { useTheme } from "@emotion/react"; // Import the useTheme hook
import { useMediaQuery } from "@mui/material"; // Import the useMediaQuery hook
import {
  Box,
  Button,
  Card,
  Grid,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
// import { CoPresent } from "@mui/icons-material";

import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import patientData from "../../components/ramsay/patientData/patientData";
import { useParams } from "react-router-dom";

const SecondColumn = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
}));

function Tools() {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      flexGrow={1}
      sx={{ pr: 2 }}
    >
      <Box sx={{ pr: 4 }}>
        <Typography variant={isMdUp ? "h3" : "h5"} color="InfoText">
          Live Meeting
        </Typography>
        <Stack
          direction="row"
          spacing={3}
          alignItems="baseline"
          flexWrap="wrap"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Typography variant={isMdUp ? "h6" : "body1"} color="InfoText">
            Orthopaedics
          </Typography>
          <Typography variant={isMdUp ? "h6" : "body1"} color="InfoText">
            {new Date().toLocaleDateString()}
          </Typography>
          <Typography variant={isMdUp ? "h6" : "body1"} color="InfoText">
            Room 1
          </Typography>
          <Typography
            sx={{ display: { xs: "none", sm: "block" } }}
            variant={isMdUp ? "h6" : "body1"}
            color="InfoText"
          >
            Oaks Hospital
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <MdtTeam />
        </Stack>
      </Box>
    </Stack>
  );
}
function PrevPatientButton(patient: any) {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const thisPatient = patient.patient;

  if (thisPatient && isMdUp) {
    return (
      <Link to={"/live/" + thisPatient?.id}>
        <Button
          sx={{ minWidth: 210 }}
          startIcon={<KeyboardArrowLeft />}
          size="large"
        >
          Prev: {thisPatient?.fullName}
        </Button>
      </Link>
    );
  } else if (thisPatient) {
    return (
      <Link to={"/live/" + thisPatient?.id}>
        <IconButton size="large">
          <KeyboardArrowLeft />
        </IconButton>
      </Link>
    );
  } else if (!thisPatient && isMdUp) {
    return (
      <Button
        sx={{ minWidth: 210 }}
        startIcon={<KeyboardArrowLeft />}
        size="large"
        disabled
      >
        Prev
      </Button>
    );
  } else {
    return (
      <IconButton size="large" disabled>
        <KeyboardArrowLeft />
      </IconButton>
    );
  }
}

function NextPatientButton(patient: any) {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const thisPatient = patient.patient;

  if (thisPatient && isMdUp) {
    return (
      <Link to={"/live/" + thisPatient?.id}>
        <Button
          endIcon={<KeyboardArrowRight />}
          size="large"
          sx={{ minWidth: 210 }}
        >
          Next: {thisPatient?.fullName}
        </Button>
      </Link>
    );
  } else if (thisPatient) {
    return (
      <Link to={"/live/" + thisPatient?.id}>
        <IconButton size="large">
          <KeyboardArrowRight />
        </IconButton>
      </Link>
    );
  } else if (!thisPatient && isMdUp) {
    return (
      <Button
        sx={{ minWidth: 210 }}
        endIcon={<KeyboardArrowRight />}
        size="large"
        disabled
      >
        Next
      </Button>
    );
  } else {
    return (
      <IconButton size="large" disabled>
        <KeyboardArrowRight />
      </IconButton>
    );
  }
}

function LiveMeetingPage() {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const patientId = useParams<{ patientId: string }>();

  let patient = null;
  let prevPatient = null;
  let nextPatient = null;

  let patientIndex: number = -1;
  if (patientId.patientId) {
    patientIndex = +patientId.patientId - 1;
  } else {
    patientIndex = 0;
  }

  patient = patientData[patientIndex];

  prevPatient = patientIndex > 0 ? patientData[patientIndex - 1] : null;
  nextPatient =
    patientIndex < patientData.length - 1
      ? patientData[patientIndex + 1]
      : null;

  return (
    <React.Fragment>
      <Helmet title="Live Meeting" />
      <PageNavbar title="">
        <Tools />
      </PageNavbar>
      <Box sx={{ p: 6 }}>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <MdtTeam />
          </Stack>
        </Box>
        <Grid container classes="l-page-container" spacing={4} columns={24}>
          <Grid item classes="l-patient-card" xs={24} sm={24} md={24} lg={19}>
            <Card sx={{ mb: 3 }}>
              <Grid container spacing={4}>
                <Grid item classes="l-nav" xs={12} sm={12} md={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    sx={{ p: 3 }}
                  >
                    <PrevPatientButton patient={prevPatient} />
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Box>
                        <Typography
                          variant={isMdUp ? "h5" : "body1"}
                          color="InfoText"
                        >
                          {patient.fullName} ({patient.gender})
                        </Typography>
                        <Typography variant="body1" color="#666">
                          NHS: {patient.nhs}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant={isMdUp ? "h5" : "body1"}
                          color="InfoText"
                        >
                          DOB: {new Date(patient.dob).toLocaleDateString()} [
                          {patient.age}
                          yrs]
                        </Typography>

                        <Typography variant="body1" color="#666">
                          Location: Ward A / Bed 12
                        </Typography>
                      </Box>
                    </Stack>

                    <NextPatientButton patient={nextPatient} />
                  </Stack>
                  <Divider />
                </Grid>
                <Grid item classes="l-column" xs={12} sm={12} md={6}>
                  <Box sx={{ p: 6 }}>
                    <VitruvianCondition patient={patient} />
                    <Risk patient={patient} />
                    <ReportTimeline patient={patient} />
                  </Box>
                </Grid>
                <SecondColumn item classes="l-column" xs={12} sm={12} md={6}>
                  <Box sx={{ py: 6, pr: 6, pl: 2 }}>
                    <Agenda patient={patient} />
                    <MeetingCarePlan patient={patient} />
                  </Box>
                </SecondColumn>
              </Grid>
            </Card>
          </Grid>
          <Grid
            classes="l-patient-comments"
            item
            xs={25}
            sm={25}
            md={25}
            lg={5}
          >
            <Comments patient={patient} />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default LiveMeetingPage;
