import React from "react";
import { Helmet } from "react-helmet-async";

import TLPagebar from "../../components/navbar/appBar/TLPageBar";

// import { Spacer } from "../../components/navbar/appBar/TLAppbar";

import { Box, Button, Stack, Typography, Card } from "@mui/material";
import { Groups } from "@mui/icons-material";

function LeftTools() {
  return (
    <Stack direction="row" spacing={2} flexWrap="wrap">
      <Button variant="outlined" startIcon={<Groups />} size="large">
        Left Button 1
      </Button>
      <Button variant="outlined" startIcon={<Groups />} size="large">
        Left Button 2
      </Button>
    </Stack>
  );
}

function RightTools() {
  return (
    <Stack direction="row" spacing={2} flexWrap="wrap">
      <Button variant="outlined" startIcon={<Groups />} size="large">
        Right Button 1
      </Button>
      <Button variant="outlined" startIcon={<Groups />} size="large">
        Right Button 2
      </Button>
    </Stack>
  );
}

function PageToolbarTest() {
  return (
    <React.Fragment>
      <Helmet title="Toolbar Test" />
      <TLPagebar
        title="Toolbar Test"
        leftTools={<LeftTools />}
        breakpointLeft="md"
        rightTools={<RightTools />}
        breakpointRight="lg"
        breakpointChildren="lg"
      >
        <Button variant="outlined" startIcon={<Groups />} size="large">
          Center Button 1
        </Button>
        <Button variant="outlined" startIcon={<Groups />} size="large">
          Center Button 2
        </Button>
      </TLPagebar>
      <Box sx={{ px: 6, py: 3 }}>
        <Card sx={{ mb: 3 }}>
          <Box sx={{ width: "100%", minWidth: "500px", px: 3, pb: 4, pt: 1 }}>
            <Typography variant="body1">Main content</Typography>
          </Box>
        </Card>
      </Box>
    </React.Fragment>
  );
}

export default PageToolbarTest;
