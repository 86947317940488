// react imports
import React from "react";
import { Helmet } from "react-helmet-async";

// MUI imports
import { Box, Breadcrumbs, Grid, Link, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";

// Project imports
import TLPagebar from "../../components/navbar/appBar/TLPageBar";
import CaseDetails from "../../components/caseRender/CaseDetails";
import CaseHistory from "../../components/caseRender/CaseHistory";
import KeyDates from "../../components/caseRender/KeyDates";
import Parties from "../../components/caseRender/Parties";
import CaseDocuments from "../../components/caseRender/Documents";
// import CaseCommentFeed from "../../components/caseRender/CaseCommentFeed";
import Actions from "../../components/caseRender/Actions";

// Styled component for page content
const PageContent = styled(Box)`
  padding: 6px 24px 24px 24px;
`;

function CaseDetail() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Bailey vs. County Hospital</title>
      </Helmet>
      <TLPagebar title="Bailey vs. County Hospital" />
      <PageContent>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 4, px: 1 }}>
          <Link color="inherit" href="/">
            Home
          </Link>
          <Link color="inherit" href="/dashboard">
            Dashboard
          </Link>
          <Typography color="textPrimary">
            Bailey vs. County Hospital
          </Typography>
        </Breadcrumbs>
        <Grid container spacing={6}>
          <Grid
            classes="row-1-col-2"
            item
            xs={12}
            md={12}
            lg={3}
            xl={3}
            xxl={3}
          >
            <Stack spacing={6}>
              <CaseDetails />
              <Parties />
              <CaseHistory />
              <Actions />
            </Stack>
          </Grid>
          <Grid
            classes="row-1-col-1"
            item
            xs={12}
            md={12}
            lg={9}
            xl={9}
            xxl={9}
          >
            <Stack spacing={6}>
              <KeyDates />
              <CaseDocuments />
            </Stack>
          </Grid>
        </Grid>
      </PageContent>
    </React.Fragment>
  );
}

export default CaseDetail;
