import React from "react";
import { Helmet } from "react-helmet-async";
import { Alert, Box, Button, Grid, Stack } from "@mui/material";

import TLPagebar from "../../components/navbar/appBar/TLPageBar";
import { Spacer } from "../../components/navbar/appBar/TLAppbar";

import { Link } from "react-router-dom";

import NewReferral from "../../components/ramsay/dashboard/NewReferral";
import MyPatients from "../../components/ramsay/mdt/MyPatients";
import Reporting from "../../components/ramsay/dashboard/Reporting";
import MdtMeetings from "../../components/ramsay/mdt/MdtMeetings";
import LiveMeeting from "../../components/ramsay/dashboard/LiveMeeting";

function RightTools() {
  return (
    <Stack direction="row" spacing={2} flexWrap="wrap" sx={{ flexGrow: 1 }}>
      <Spacer />
      <NewReferral />
    </Stack>
  );
}

function Dashboard() {
  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <TLPagebar
        title="Dashboard"
        rightTools={<RightTools />}
        breakpointChildren="lg"
      >
        <Alert
          severity="warning"
          action={
            <Link
              target="_blank"
              to="https://www.nice.org.uk/guidance/ng158/chapter/Recommendations#diagnosis-and-initial-management"
            >
              <Button sx={{ ml: 4 }} color="warning" size="small">
                OPEN POLICY
              </Button>
            </Link>
          }
        >
          Please make sure you have read and approved the new VTE policy
        </Alert>
      </TLPagebar>

      <Box sx={{ px: 6, py: 3 }}>
        <Grid container spacing={6} direction="row">
          <Grid item xs={12}>
            <LiveMeeting />
          </Grid>

          <Grid item xs={12} md={6}>
            <MdtMeetings />
          </Grid>
          <Grid item xs={12} md={6}>
            <MyPatients />
          </Grid>

          <Grid item xs={12}>
            <Reporting />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default Dashboard;
