import React from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { AiIcon } from "../../../icons/TLSvgIcons";
import { AddCircle } from "@mui/icons-material";

import patientCarePlanData from "../../patientData/patientCarePlanData";

function DiscussionCarePlan(patient: any) {
  const thisPatientId = patient.patient.id;
  const thisCarePlan = patientCarePlanData.find(
    (carePlan) => carePlan.id === thisPatientId
  );

  return (
    <Box mb={6}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="h4">Draft Discussion Points</Typography>
        <Box>
          <AiIcon />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton>
          <AddCircle />
        </IconButton>
      </Stack>
      <List
        sx={{
          background: "#FFFBF7",
          border: " 1px solid rgb(255, 206, 162)",
          borderRadius: "5px",
          color: "black",
        }}
      >
        {thisCarePlan &&
          thisCarePlan.points.map((point) => (
            <React.Fragment key={point.id}>
              <ListItem sx={{ p: 0 }}>
                <ListItemButton>
                  {/* <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(point.id - 1) !== -1}
                      tabIndex={-1}
                      color="success"
                      disableRipple
                    />
                  </ListItemIcon> */}
                  <ListItemText primary={point.text} />
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          ))}
      </List>
    </Box>
  );
}

export default DiscussionCarePlan;
