import { createSlice } from "@reduxjs/toolkit";

export interface CommentTimelineEmptyState {
  comments: any[];
}

const initialState: CommentTimelineEmptyState = {
  comments: [
    {
      id: 1,
      content:
        "The patient's primary concerns of shortness of breath and fatigue, alongside her significant comorbidities, necessitate a holistic approach. Our priorities should include optimising symptomatic management, discussing her goals of care, and advance care planning. Given her advanced illnesses, a palliative care approach is essential to maximise her quality of life. Coordination between oncology, pulmonary, and palliative care teams will be crucial.",
      by: "Dr Scott Parazynski",
      speciality: "MDT Chair",
      avatar: "",
      date: "27/05/2024",
      replies: [],
    },

    {
      id: 2,
      content:
        "The patient's history of heavy smoking and myocardial infarction places her at an elevated risk for further cardiac events. Although her recent EKG shows sinus tachycardia without ischemic changes, it's imperative we optimise her cardiovascular management. Smoking cessation should be strongly encouraged, and a detailed review of her current medications to manage any cardiac risks is necessary.",
      by: "Dr Mark Appleby",
      speciality: "Cardiology",
      avatar: "",
      date: "28/05/2024",
      replies: [],
    },
    {
      id: 3,
      content:
        "Recent imaging, including the chest X-ray and CT scan, indicate progression of her non-small cell lung cancer. The detailed pulmonary examination suggests worsening COPD. Close monitoring through imaging will be necessary to track any further progression. Regular imaging will also assist in evaluating the effectiveness of palliative treatments and making timely adjustments to her care plan.",
      by: "Dr John Ferrier",
      speciality: "Radiology",
      avatar: "",
      date: "29/05/2024",
      replies: [],
    },
  ],
};

const commentTimelineEmptySlice = createSlice({
  name: "commentTimelineEmpty",
  initialState,
  reducers: {
    changeCommentTimelineEmpty: (state, action) => {
      state.comments = action.payload;
    },
  },
});

export const { changeCommentTimelineEmpty } = commentTimelineEmptySlice.actions;
export default commentTimelineEmptySlice.reducer;
