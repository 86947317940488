const assesmentData = [
  {
    id: 1,
    firstName: "Jane",
    lastName: "Doe",
    fullName: "DOE, Jane",
    age: 68,
    dob: "1956-01-01",
    gender: "Female",
    ethnicity: "Caucasian",
    nhs: "456 789 1234",
    chief_complaint: "Shortness of breath, fatigue",
    conditions: [
      "Stage IV non-small cell lung cancer",
      "COPD",
      "type 2 diabetes mellitus",
    ],
    tlc_upload: true,
    user_reviewed: true,
    team_review: 100,
    mdt_status: "Awaiting MDT Discussion",
    consultant: "Dr Nick Taylor",
    mdtRationale: "",
    mdtContributors: [
      { specialty: "MDT Chair", name: "Chair_1" },
      { specialty: "MDT Coordinator", name: "Coordinator_1" },
      { specialty: "G.P.", name: "GP_1" },
      { specialty: "Oncologist", name: "Oncologist_1" },
      { specialty: "Pulmonary Medicine", name: "Pulmonary_1" },
      { specialty: "Radiation Therapy", name: "Radiation_1" },
      { specialty: "Thoracic Surgery", name: "Thoracic_1" },
      { specialty: "Palliative Care", name: "Palliative_1" },
      { specialty: "Hospice", name: "Hospice_1" },
      { specialty: "Nutritionist", name: "Nutritionist_1" },
      { specialty: "Nursing", name: "Nursing_1" },
      { specialty: "Home Nursing", name: "HomeNursing_1" },
      { specialty: "Social Worker", name: "SocialWorker_1" },
    ],
    pmh: "COPD, NSCLC, T2DM, 60 pack-years tobacco use",
    psh: "Left upper lobectomy, Right inguinal hernia repair; ",
    medications: ["Metformin", "Fluticasone inhaler"],
    allergies: ["None"],
    socialHistory:
      "Lives independently, but finding this increasingly difficult.",
  },
  {
    id: 2,
    firstName: "Sekani",
    lastName: "Abara",
    fullName: "ABARA, Sekani",
    age: 72,
    dob: "1951-03-05",
    gender: "Male",
    ethnicity: "African",
    nhs: "012 345 6789",
    chief_complaint: "Right hip osteoarthritis, pain",
    conditions: [
      "Osteoarthritis",
      "Obesity",
      "Myocardial infarction",
      "Stent-LAD Coronary Artery",
    ],
    tlc_upload: true,
    user_reviewed: true,
    team_review: 80,
    mdt_status: "Case Preparation",
    consultant: "Mr David Cash",
    mdtRationale: "",
    mdtContributors: [
      { specialty: "MDT Chair", name: "Chair_10" },
      { specialty: "MDT Coordinator", name: "Coordinator_10" },
      { specialty: "G.P.", name: "GP_10" },
      { specialty: "Orthopaedic Surgeon", name: "Surgeon_10" },
      { specialty: "Cardiologist", name: "Cardiologist_10" },
      { specialty: "Anesthesiologist", name: "Anesthesiologist_10" },
      { specialty: "Nutritionist", name: "Nutritionist_10" },
      { specialty: "Physical Therapist", name: "Physical_10" },
      { specialty: "Occupational Therapist", name: "Occupational_10" },
      { specialty: "Nursing", name: "Nursing_10" },
      { specialty: "Social Worker", name: "SocialWorker_10" },
    ],
    pmh: "Mr. Sekani Abara is a 72-year-old, moderately obese (BMI = 36) male of South African descent being considered for a Right Total Hip Replacement secondary to osteoarthritis. Patient’s noteworthy past medical history includes: obesity, 50 pack-years of ongoing tobacco use, and a non-ST-elevation myocardial infarction (NSTEMI) 5 years prior, resulting in a successful stenting of his left anterior descending (LAD) coronary artery. He has no cardiac symptoms, no shortness of breath with ambulation or signs of congestive heart failure (no peripheral oedema, pulmonary rales or orthopnea). ",
    psh: "",
    medications: ["Ibuprofen"],
    allergies: ["None"],
    socialHistory:
      "Patient lives alone and independently but is having considerable difficulty climbing the stairs to his 2nd story flat. Pre-operative MDT evaluation was conducted given his cardiac history, obesity and sedentary nature, as well as concern for post-operative support for activities of daily living. His care plan included 2 weeks of prehabilitation, VTE prophylaxis and regional rather than general anaesthesia for his surgical procedure.",
  },

  {
    id: 3,
    firstName: "Sara",
    lastName: "Johnson",
    fullName: "JOHNSON, Sara",
    age: 28,
    dob: "1996-09-23",
    gender: "Female",
    ethnicity: "Caribbean",
    nhs: "234 567 8901",
    chief_complaint: "Seizures, cognitive impairment",
    conditions: [
      "Glioblastoma multiforme (brain tumour)",
      "post-craniotomy complications",
    ],
    tlc_upload: true,
    user_reviewed: true,
    team_review: 70,
    mdt_status: "Case Preparation",
    consultant: "Dr Scott Parazynski",
    mdtRationale:
      "To determine the optimal combination of radiation therapy, chemotherapy, and supportive care.",
    mdtContributors: [
      { specialty: "MDT Chair", name: "Chair_3" },
      { specialty: "MDT Coordinator", name: "Coordinator_3" },
      { specialty: "G.P.", name: "GP_3" },
      { specialty: "Neurologist", name: "Neurologist_3" },
      { specialty: "Neurosurgeon", name: "Neurosurgeon_3" },
      { specialty: "Radiation Oncologist", name: "Radiation_3" },

      { specialty: "Nutritionist", name: "Nutritionist_3" },
      { specialty: "Neuropathologist", name: "Neuropathologist_3" },
      { specialty: "Nursing", name: "Nursing_3" },
      { specialty: "Occupational Therapist", name: "Occupational_3" },
      { specialty: "Physical Therapist", name: "Physical_3" },

      { specialty: "Palliative Care", name: "Palliative_3" },
    ],
    pmh: "Stage III Glioblastoma, Seizure Disorder, Cognitive Impairment",
    psh: "Crainiotomy - left temporal lobe tumour resection",
    medications: ["Levetiracetam"],
    allergies: ["None"],
    socialHistory: "Currently in a skillled nursing facility.",
  },
  {
    id: 4,
    firstName: "Robert",
    lastName: "Williams",
    fullName: "WILLIAMS, Robert",
    age: 54,
    dob: "1970-12-12",
    gender: "Male",
    ethnicity: "Caucasian",
    nhs: "345 678 9012",
    chief_complaint: "Weakness, numbness in extremities",
    conditions: ["Multiple Sclerosis", "Spinal cord compression", "Depression"],
    tlc_upload: true,
    user_reviewed: true,
    team_review: 30,
    mdt_status: "Referral Received",
    consultant: "Mr David Cash",
    mdtRationale:
      "To coordinate rehabilitation, manage symptoms, and provide psychological support.",
    mdtContributors: [
      { specialty: "MDT Chair", name: "Chair_4" },
      { specialty: "MDT Coordinator", name: "Coordinator_4" },
      { specialty: "G.P.", name: "GP_4" },
      { specialty: "Neurologist", name: "Neurologist_4" },
      { specialty: "Psychiatrist", name: "Psychiatrist_4" },
      { specialty: "Rehabilitation Medicine", name: "Rehab_4" },
      { specialty: "Urologist", name: "Urologist_4" },
      { specialty: "Nursing", name: "Nursing_4" },
      { specialty: "Occupational Therapist", name: "Occupational_4" },
      { specialty: "Speech Therapist", name: "Speech_4" },
      { specialty: "Social Worker", name: "SocialWorker_4" },
      { specialty: "Palliative Care", name: "Palliative_4" },
    ],
    pmh: "Multiple Sclerosis, Spinal Cord Compression, Depression",
    psh: "ORIF left tibial fracture repair (age 15)",
    medications: ["Teriflunomide (Aubagio)", "rednisone"],
    allergies: ["None"],
    socialHistory:
      "wheelchair bound, works part time from home as a consultant.",
  },
  {
    id: 5,
    firstName: "Emily",
    lastName: "Davis",
    fullName: "DAVIS, Emily",
    age: 78,
    dob: "1946-09-23",
    gender: "Female",
    ethnicity: "Caucasian",
    nhs: "456 789 0123",
    chief_complaint: "Confusion, falls",
    conditions: ["Vascular dementia", "Osteoporosis", "Parkinson's disease"],
    tlc_upload: true,
    user_reviewed: true,
    team_review: 100,
    mdt_status: "Awaiting MDT Discussion",
    consultant: "Dr Mark Appleby",
    mdtRationale:
      "To assess cognitive function, optimise medication management, and address fall prevention.",
    mdtContributors: [
      { specialty: "MDT Chair", name: "Chair_5" },
      { specialty: "MDT Coordinator", name: "Coordinator_5" },
      { specialty: "G.P.", name: "GP_5" },
      { specialty: "Neurologist", name: "Neurologist_5" },
      { specialty: "Rheumatologist", name: "Rheumatologist_5" },
      { specialty: "Nursing", name: "Nursing_5" },
      { specialty: "Physical Therapist", name: "Physical_5" },
      { specialty: "Occupational Therapist", name: "Occupational_5" },
      { specialty: "Home Nursing", name: "HomeNursing_5" },
      { specialty: "Social Worker", name: "SocialWorker_5" },
      { specialty: "Nursing Facility", name: "NursingFacility_5" },
    ],
    pmh: "Parkinson's Disease, Vasular Dementia, Osteoporosis, Hypertension, Hyperlipidemia",
    psh: "Laparoscopic Cholecystectomy, Left Knee ACL repair",
    medications: ["Levodopa", "Lisinopril", "Risedronate", "Atorvastatin"],
    allergies: ["Penicillin"],
    socialHistory:
      "lives with husband/caretaker at home, but the workload is becoming to difficult for him to manage given his deteriorating physical abilities.",
  },
  {
    id: 6,
    firstName: "David",
    lastName: "Thompson",
    fullName: "THOMPSON, David",
    age: 16,
    dob: "2008-05-17",
    gender: "Male",
    ethnicity: "Caucasian",
    nhs: "567 890 1234",
    chief_complaint: "Severe abdominal pain, vomiting",
    conditions: [
      "Acute lymphoblastic leukemia",
      "Chemotherapy-induced mucositis",
    ],
    tlc_upload: true,
    user_reviewed: true,
    team_review: 50,
    mdt_status: "Information Gathering",
    consultant: "Dr Nick Taylor",
    mdtRationale:
      "To manage complications of cancer treatment, provide nutritional support, and address psychosocial needs.",
    mdtContributors: [
      { specialty: "MDT Chair", name: "Chair_6" },
      { specialty: "MDT Coordinator", name: "Coordinator_6" },
      { specialty: "G.P.", name: "GP_6" },
      { specialty: "Oncologist", name: "Oncologist_6" },
      { specialty: "Paediatrician", name: "Paediatrician_6" },
      { specialty: "Otolaryngologist", name: "Otolaryngologist_6" },
      { specialty: "Nursing", name: "Nursing_6" },
      { specialty: "Nutritionist", name: "Nutritionist_6" },
      { specialty: "Speech Therapist", name: "Speech_6" },
    ],
    pmh: "Acute Lymphoblastic Leukemia, Chemotherapy-induced Mucositis",
    psh: "None",
    medications: ["second phase/consolidation chemo"],
    allergies: ["None"],
    socialHistory:
      "Sophmore in high school, but will have to repeat the grade next year owing to treatments.",
  },
  {
    id: 7,
    firstName: "Lisa",
    lastName: "Lee",
    fullName: "LEE, Lisa",
    age: 36,
    dob: "1988-03-05",
    gender: "Female",
    ethnicity: "Asian",
    nhs: "678 901 2345",
    chief_complaint: "Palpitations, fatigue",
    conditions: [
      "Dilated cardiomyopathy",
      "Congestive heart failure",
      "Kidney disease",
    ],
    tlc_upload: true,
    user_reviewed: true,
    team_review: 60,
    mdt_status: "Information Gathering",
    consultant: "Dr Mark Appleby",
    mdtRationale:
      "To evaluate potential for heart transplantation, manage comorbidities, and provide supportive care.",
    mdtContributors: [
      { specialty: "MDT Chair", name: "Chair_7" },
      { specialty: "MDT Coordinator", name: "Coordinator_7" },
      { specialty: "G.P.", name: "GP_7" },
      { specialty: "Cardiovascular Surgeon", name: "Surgeon_7" },
      { specialty: "Cardiologist", name: "Cardiologist_7" },
      { specialty: "Anesthesiologist", name: "Anesthesiologist_7" },
      { specialty: "Immunologist", name: "Immunologist_7" },
      { specialty: "Nephrologist", name: "Nephrologist_7" },
      { specialty: "Nursing", name: "Nursing_7" },
      { specialty: "Physical Therapist", name: "Physical_7" },
      { specialty: "Nutritionist", name: "Nutritionist_7" },
      { specialty: "Pharmacist", name: "Pharmacist_7" },
      { specialty: "Social Worker", name: "SocialWorker_7" },
      { specialty: "Financial Counsellor", name: "Financial_7" },
    ],
    pmh: "Dilated Cardiomyopathy, Congestive Heart Failure, Kidney Disease",
    psh: "None",
    medications: ["Lanoxin", "Carvedilol", "Eliquis"],
    allergies: ["Aspirin"],
    socialHistory: "On medical disability awaiting heart transplantation.",
  },
  {
    id: 8,
    firstName: "James",
    lastName: "Wilson",
    fullName: "WILSON, James",
    age: 62,
    dob: "1958-07-12",
    gender: "Male",
    ethnicity: "Caucasian",
    nhs: "789 012 3456",
    chief_complaint: "Difficulty swallowing, hoarseness",
    conditions: ["Laryngeal cancer", "Post-surgical complications"],
    tlc_upload: true,
    user_reviewed: true,
    team_review: 100,
    mdt_status: "Awaiting MDT Discussion",
    consultant: "Dr Nick Taylor",
    mdtRationale:
      "To discuss adjuvant therapy options, speech and swallowing rehabilitation, and supportive care.",
    mdtContributors: [
      { specialty: "MDT Chair", name: "Chair_8" },
      { specialty: "MDT Coordinator", name: "Coordinator_8" },
      { specialty: "G.P.", name: "GP_8" },
      { specialty: "Otolaryngologist", name: "Otolaryngologist_8" },
      { specialty: "Pain Management", name: "Pain_8" },
      { specialty: "Oncologist", name: "Oncologist_8" },
      { specialty: "Radiation Therapist", name: "Radiation_8" },
      { specialty: "Palliative Care", name: "Palliative_8" },
      { specialty: "Speech Therapist", name: "Speech_8" },
      { specialty: "Pathologist", name: "Pathologist_8" },

      { specialty: "Nursing", name: "Nursing_8" },

      { specialty: "Social Worker", name: "SocialWorker_8" },
    ],
    pmh: "Stage IV Laryngeal Cancer, 75 pack-years tobacco abuse, Malnutrition, Mucositis",
    psh: "Partial Laryngectomy",
    medications: ["Topical mouthwashes"],
    allergies: ["None"],
    socialHistory: "Just moved into residential hospice by family.",
  },
  {
    id: 9,
    firstName: "Meeta",
    lastName: "Singh",
    fullName: "SINGH, Meeta",
    age: 45,
    dob: "1979-11-29",
    gender: "Female",
    ethnicity: "South Asian",
    nhs: "890 123 4567",
    chief_complaint:
      "Pain, limited mobility, particularly in right hip and left knee",
    conditions: ["Rheumatoid arthritis", "Joint deformities", "Depression"],
    tlc_upload: true,
    user_reviewed: true,
    team_review: 90,
    mdt_status: "Awaiting MDT Discussion",
    consultant: "Dr John Ferrier",
    mdtRationale:
      "To optimise medical management, evaluate surgical interventions, and provide psychological support.",
    mdtContributors: [
      { specialty: "MDT Chair", name: "Chair_9" },
      { specialty: "MDT Coordinator", name: "Coordinator_9" },
      { specialty: "G.P.", name: "GP_9" },
      { specialty: "Rheumatologist", name: "Rheumatologist_9" },
      { specialty: "Orthopaedic Surgeon", name: "Surgeon_9" },
      { specialty: "Anesthesiologist", name: "Anesthesiologist_9" },
      { specialty: "Psychiatrist", name: "Psychiatrist_9" },
      { specialty: "Physical Therapist", name: "Physical_9" },
      { specialty: "Occupational Therapist", name: "Occupational_9" },
      { specialty: "Nursing", name: "Nursing_9" },
      { specialty: "Pharmacist", name: "Pharmacist_9" },
      { specialty: "Social Worker", name: "SocialWorker_9" },
    ],
    pmh: "Severe Rheumatoid Arthritis in multiple joints, particularly the right hip and left knee (but pain is also present in both hands, wrists and ankles), Depression",
    psh: "None",
    medications: ["Corticosteroids", "Rinvoq", "NSAIDs"],
    allergies: ["None"],
    socialHistory: "Lives at home with very supportive extended family.",
  },
  {
    id: 10,
    firstName: "Michael",
    lastName: "Smith",
    fullName: "SMITH, Michael",
    age: 42,
    dob: "1982-03-05",
    gender: "Male",
    ethnicity: "African American",
    nhs: "123 456 7890",
    chief_complaint: "Severe abdominal pain, weight loss;",
    conditions: ["Crohn's disease", "Small bowel obstruction", "Malnutrition"],
    tlc_upload: true,
    user_reviewed: true,
    team_review: 90,
    mdt_status: "Awaiting MDT Discussion",
    consultant: "Dr Scott Parazynski",
    mdtRationale:
      "To evaluate surgical intervention, nutritional support, and pain management.",

    mdtContributors: [
      { specialty: "MDT Chair", name: "Chair_2" },
      { specialty: "MDT Coordinator", name: "Coordinator_2" },
      { specialty: "G.P.", name: "GP_2" },
      { specialty: "Gastroenterologist", name: "Gastro_2" },
      { specialty: "Interventional Pain Management", name: "Pain_2" },
      { specialty: "Colorectal Surgeon", name: "Surgeon_2" },
      { specialty: "Anesthesiologist", name: "Anesthesiologist_2" },
      { specialty: "Addiction Medicine", name: "Addiction_2" },
      { specialty: "Nursing", name: "Nursing_2" },
      { specialty: "Home Nursing", name: "HomeNursing_2" },
      { specialty: "Social Worker", name: "SocialWorker_2" },
    ],
    pmh: "Crohn's disease, small bowel obstruction, malnutrition (BMI 16)",
    psh: "None",
    medications: ["Humira", "Corticosteroids"],
    allergies: ["Acetominophen"],
    socialHistory:
      "On medical disability leave, lives with wife and two young children",
  },
  {
    id: 11,
    firstName: "Martin",
    lastName: "Lachlan",
    fullName: "LACHLAN, Martin",
    age: 78,
    dob: "1946-03-16",
    gender: "Male",
    ethnicity: "Caucasian",
    nhs: "901 234 5678",
    chief_complaint: "Altered mental status, fever",
    conditions: ["Sepsis", "Chronic kidney disease", "Diabetes mellitus;"],
    tlc_upload: true,
    user_reviewed: false,
    team_review: 70,
    mdt_status: "Case Preparation",
    consultant: "Dr Scott Parazynski",
    mdtRationale:
      "To manage sepsis, address comorbidities, and discuss palliative care options.",
    mdtContributors: [
      { specialty: "MDT Chair", name: "Chair_11" },
      { specialty: "MDT Coordinator", name: "Coordinator_11" },
      { specialty: "G.P.", name: "GP_11" },
      { specialty: "Intensivist", name: "Intensivist_11" },
      { specialty: "Infectious Disease", name: "Infectious_11" },
      { specialty: "Nephrologist", name: "Nephrologist_11" },
      { specialty: "Endocrinologist", name: "Endocrinologist_11" },
      { specialty: "Palliative Care", name: "Palliative_11" },
      { specialty: "Nursing", name: "Nursing_11" },
    ],
    pmh: "Poorly controlled Type II Diabetes Mellitus, End Stage Renal Disease, Polycystic Kidney Disease, Hypertension, Recurrent Kidney Stones",
    psh: "PSH: Percutaneous Nephrolithotomy x2",
    medications: ["Insulin", "Metformin", "Lisinopril", "Tolvaptan"],
    allergies: ["None"],
    socialHistory:
      "Family members are with patient in his ICU suite and are discussing end of life issues.",
  },
];

export default assesmentData;
