import styled from "@emotion/styled";

import VitruvianFemaleObeseBase from "./assets/wider-female-base-vitruvian.svg";

import LungLayerFemaleRed from "./LungLayerFemaleRed";
import HerniaRepair from "./HerniaRepair";
import LUpRemoval from "./LUpRemoval";

import "./assets/animation.css";

interface VitruvianProps {
  width?: string;
}

const Vitruvian01: React.FC<VitruvianProps> = ({ width }) => {
  const BaseLayer = styled("div")`
    position: relative;
    width: ${width};
  `;
  return (
    <BaseLayer>
      <img src={VitruvianFemaleObeseBase} alt="Vitruvian" width="100%" />
      <LungLayerFemaleRed />
      <LUpRemoval />
      <HerniaRepair />
    </BaseLayer>
  );
};

export default Vitruvian01;
