// React imports
import React from "react";

// react-pdf imports
import { pdfjs } from "react-pdf";
import { Document, Thumbnail } from "react-pdf";

// MUI imports
import { Box, Button, Link } from "@mui/material";
import PanoramaPhotosphereSelectIcon from "@mui/icons-material/PanoramaPhotosphereSelect";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

// reqired for react-pdf to work
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

interface ThumbnailViewerProps {
  src: string | undefined;
}

export default function ThumbnailViewer({ src }: ThumbnailViewerProps) {
  const link = src ? src : "/pdfs/GP-Referral-Letter.pdf";
  const linkTitle = link.split("/").pop();
  let content = null;

  if (
    link ===
    "https://viewer.dev.jabs.ai/viewer/dicomjson?url=https://viewer.dev.jabs.ai/LIDC-IDRI-0001.json"
  ) {
    content = (
      <Button
        component={Link}
        href={link}
        target="_blank"
        fullWidth
        sx={{ marginTop: 2 }}
        color="info"
        variant="contained"
        startIcon={<PanoramaPhotosphereSelectIcon />}
        endIcon={<PlayArrowIcon />}
      >
        DICOM Viewer
      </Button>
    );
  } else {
    content = (
      <Box>
        <Button
          component={Link}
          href={link}
          target="_blank"
          fullWidth
          sx={{ marginTop: 2, marginBottom: 1 }}
          color="info"
          variant="contained"
          startIcon={<PictureAsPdfIcon />}
          endIcon={<PlayArrowIcon />}
        >
          {linkTitle}
        </Button>
        <Box
          sx={{
            border: "1px solid #aaaaaa",
            borderRadius: "3px",
            padding: "3px",
            marginTop: 1,
          }}
        >
          <Document file={link}>
            <Thumbnail width={520} pageNumber={1} />
            {/* <Page
          width={520}
          pageNumber={1}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        /> */}
          </Document>
        </Box>
      </Box>
    );
  }

  return content;
}
