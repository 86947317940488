import { Box, Typography } from "@mui/material";

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

function ProcedureEmpty() {
  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Procedure
      </Typography>
      <Typography variant="body1">
        <b>Operative Procedure: </b>
      </Typography>
      <Typography variant="body1">
        <b>Surgeon: </b>
      </Typography>
      <Typography variant="body1">
        <b>Anaesthetist: </b>
      </Typography>
      <Typography variant="body1">
        <b>Date of surgery: </b>
        {tomorrow.toLocaleDateString()}
      </Typography>
    </Box>
  );
}
export default ProcedureEmpty;
