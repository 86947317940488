// React imports
import React from "react";

// MUI imports
import { Box, Typography } from "@mui/material";

// Custom imports
import TLIcon from "../icons/TLIcon";

interface TypeIconProps {
  type: string;
} // Interface defining the props for TypeIcon component

const TypeIcon = ({ type }: TypeIconProps) => {
  let iconName = "";
  switch (type) {
    case "Observation":
      iconName = "preview";
      break;
    case "Note":
      iconName = "edit";
      break;
    case "Appointment":
      iconName = "event";
      break;
    case "Procedure":
      iconName = "masks";
      break;
    default:
      iconName = "preview";
      break;
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", color: "#9b9b9b" }}>
      <TLIcon name={iconName} />
      <Typography
        variant="overline"
        sx={{ marginLeft: "5px", fontWeight: 800 }}
      >
        {type}
      </Typography>
    </Box>
  );
}; // TypeIcon component definition

export default TypeIcon;
