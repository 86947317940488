import React from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";

import { Card as MuiCard, LinearProgress } from "@mui/material";
import { DataGrid, GridEventListener, GridToolbar } from "@mui/x-data-grid";

import { spacing } from "@mui/system";
import { useNavigate } from "react-router-dom";

// import mdtListData from "./MdtListData";
import patientData from "../patientData/patientData";

const Card = styled(MuiCard)(spacing);
const CenterElement = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MdtMeetingList = () => {
  const reviewed = useSelector((state: any) => state.reviewedDemoPatient.value);
  const navigate = useNavigate();
  const handleCellClick: GridEventListener<"cellClick"> = (params) => {
    if (params.field !== "actions") {
      navigate(`/patient/${params.id}`);
    }
  };

  return (
    <Card>
      <DataGrid
        sx={{ pt: 1 }}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        rows={patientData}
        columns={[
          { field: "fullName", headerName: "Name", width: 180 },
          {
            field: "mdtRationale",
            headerName: "MDT Rationale",
            minWidth: 150,
            flex: 1,
          },
          {
            field: "consultant",
            headerName: "Consultant",
            minWidth: 100,
            flex: 0.5,
          },
          {
            field: "mdt_status",
            headerName: "Status",
            flex: 0.5,
            minWidth: 100,

            width: 180,
          },
          {
            field: "tlc_upload",
            headerName: "TLC Upload",
            disableColumnMenu: true,
            width: 100,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
              return (
                <CenterElement>
                  {params.value ? (
                    <CheckCircle color="success" />
                  ) : (
                    <RadioButtonUnchecked color="disabled" />
                  )}
                </CenterElement>
              );
            },
          },
          {
            field: "user_reviewed",
            headerName: "Reviewed",
            disableColumnMenu: true,
            width: 100,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
              if (params.id === 2) {
                return (
                  <CenterElement>
                    {reviewed ? (
                      <CheckCircle color="success" />
                    ) : (
                      <RadioButtonUnchecked color="disabled" />
                    )}
                  </CenterElement>
                );
              } else {
                return (
                  <CenterElement>
                    {params.value ? (
                      <CheckCircle color="success" />
                    ) : (
                      <RadioButtonUnchecked color="disabled" />
                    )}
                  </CenterElement>
                );
              }
            },
          },
          {
            field: "team_review",
            headerName: "Team Reviewed",
            disableColumnMenu: true,
            width: 130,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
              if (params.value <= 50) {
                return (
                  <CenterElement>
                    <LinearProgress
                      value={params.value}
                      color="error"
                      variant="determinate"
                      sx={{ height: 12, borderRadius: 5, width: "90%" }}
                    />
                  </CenterElement>
                );
              } else if (params.value <= 89) {
                return (
                  <CenterElement>
                    <LinearProgress
                      value={params.value}
                      color="warning"
                      variant="determinate"
                      sx={{ height: 12, borderRadius: 5, width: "90%" }}
                    />
                  </CenterElement>
                );
              } else {
                return (
                  <CenterElement>
                    <LinearProgress
                      value={params.value}
                      color="success"
                      variant="determinate"
                      sx={{ height: 12, borderRadius: 5, width: "90%" }}
                    />
                  </CenterElement>
                );
              }
            },
          },
        ]}
        onCellClick={handleCellClick}
        pageSizeOptions={[5, 10, 25, 100]}
      />
    </Card>
  );
};

export default MdtMeetingList;
