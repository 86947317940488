// React imports
import React from "react";
import { Helmet } from "react-helmet-async";

// MUI imports
import { Box, Breadcrumbs, Card, Link, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";

// Project imports
import TLPagebar from "../../components/navbar/appBar/TLPageBar";
import ChronologyFullPage from "../../components/chronology/ChronologyFullPage";

// Styled component for page content
const PageContent = styled(Box)`
  padding: 6px 24px 24px 24px;
`;

// Component for displaying patient information on the left side
const LeftTools = () => {
  return (
    <Box sx={{}}>
      <Stack direction="row" spacing={6} alignItems="center" flexWrap="wrap">
        <Box></Box>
        <Box>
          <Typography variant="body1" color="InfoText">
            <strong>LOCATION:</strong> Ward A / Bed 12
          </Typography>
          <Typography variant="body1" color="InfoText">
            <strong>NHS:</strong> 012 345 6789
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" color="InfoText">
            <strong>GENDER:</strong> Male
          </Typography>
          <Typography variant="body1" color="InfoText">
            <strong>DOB:</strong> 05/03/1951 [72yrs]
          </Typography>

          <Typography variant="body1" color="InfoText"></Typography>
        </Box>
        <Box>
          <Typography variant="body1" color="InfoText">
            <strong>WEIGHT:</strong> 108kg [BMI: 36]
          </Typography>
          <Typography variant="body1" color="InfoText">
            <strong>HEIGHT:</strong> 173cm
          </Typography>

          <Typography variant="body1" color="InfoText"></Typography>
        </Box>
      </Stack>
    </Box>
  );
};

// Main component for the Patient Timeline Mock Page
function PatientTimelineMockPage() {
  return (
    <React.Fragment>
      {/* Set the page title */}
      <Helmet title="BAILEY, Elijah" />
      <TLPagebar
        title="BAILEY, Elijah"
        leftTools={<LeftTools />}
        breakpointLeft="md"
      />
      {/* Main content of the page */}
      <PageContent>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 4, px: 1 }}>
          <Link color="inherit" href="/">
            Home
          </Link>
          <Link color="inherit" href="/dashboard">
            Dashboard
          </Link>
          <Link color="inherit" href="/case/4">
            Bailey vs. County Hospital
          </Link>
          <Typography color="textPrimary">Elijah Bailey</Typography>
        </Breadcrumbs>
        <Card sx={{ py: 7, px: 7 }}>
          {/* Chronology component displaying patient events */}
          <ChronologyFullPage title="Patient Chronology" />
        </Card>
      </PageContent>
    </React.Fragment>
  );
}

export default PatientTimelineMockPage;
