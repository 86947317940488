import React from "react";

import MdtTeam from "../../patient/mdt/MdtTeam";
import MdtMeetingList from "../mdt/MdtMeetingList";
import { Link } from "react-router-dom";

import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Groups, Visibility } from "@mui/icons-material";

function LiveMeeting() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          sx={{ pr: 0 }}
        >
          <Box>
            <Typography variant="h3">Next Meeting</Typography>
            <Stack
              direction="row"
              spacing={3}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Typography variant="overline">
                {new Date().toDateString()}
              </Typography>
              <Typography variant="overline">2:30pm</Typography>
              <Typography variant="overline"> Room 1</Typography>
              <Typography variant="overline">
                Oaks Hospital, Colchester, Essex
              </Typography>
            </Stack>
          </Box>
          <Stack direction="row" spacing={2} alignItems="center">
            <MdtTeam />
            <Link to="/live/1">
              <Button
                color="primary"
                variant="contained"
                startIcon={<Groups />}
                size="large"
                disabled
              >
                Join eMDT
              </Button>
            </Link>
          </Stack>
        </Stack>
      </CardContent>
      <Divider />

      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{ pr: 4 }}
        onClick={handleExpandClick}
      >
        <Box
          onClick={handleExpandClick}
          sx={{ width: "85%", px: 4, pb: 4, pt: 1 }}
        >
          <Typography variant="overline">Patients reviewed</Typography>
          <LinearProgress
            color="success"
            variant="determinate"
            value={90}
            sx={{ height: 12, borderRadius: 5, width: "100%" }}
          />
        </Box>
        <Button
          color="primary"
          variant="contained"
          size="large"
          startIcon={<Visibility />}
        >
          Review Patients
        </Button>
      </Stack>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <MdtMeetingList />
      </Collapse>
    </Card>
  );
}

export default LiveMeeting;
