import React from "react";
import { Helmet } from "react-helmet-async";
import PageNavbar from "../../components/navbar/PageNavbar";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
// import { useState } from "react";
import { changeReviewedDemoPatient } from "../../redux/slices/reviewedDemoPatient";

// import NursePic from "../../assets/images/avatars/nurse.jpg";
// import MarkPic from "../../assets/images/avatars/MarkAppleby.jpg";
// import DCashPic from "../../assets/images/avatars/mr-david-cash.jpg";
// import IvanovaPic from "../../assets/images/avatars/dobrinka-ivanova.jpeg";
// import NickPic from "../../assets/images/avatars/NickTaylor.jpg";
// import GuestPic from "../../assets/images/avatars/guest-user.jpg";
// import ScottPic from "../../assets/images/avatars/Scott.png";

import Comments from "../../components/ramsay/mdt/patientView/Comments";
import DiscussionCarePlan from "../../components/ramsay/mdt/patientView/DiscussionCarePlan";
import DraftCarePlan from "../../components/ramsay/mdt/patientView/DraftCarePlan";
// import DraftCarePlan from "../../components/ramsay/mdt/patientView/DraftCarePlan";
// import History from "../../components/ramsay/mdt/patientView/History";
// import Rationale from "../../components/ramsay/mdt/patientView/Rationale";
import ReportTimeline from "../../components/ramsay/mdt/patientView/ReportTimeline";
import Risk from "../../components/ramsay/mdt/patientView/Risk";
import VitruvianCondition from "../../components/ramsay/mdt/patientView/VitruvianCondition";

import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import {
  Groups,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";

// import { Groups } from "@mui/icons-material";
import patientData from "../../components/ramsay/patientData/patientData";
import { useParams } from "react-router-dom";

const SecondColumn = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
}));

function TeamReviewProgress({ amount }: { amount: number }) {
  let color:
    | "error"
    | "warning"
    | "success"
    | "inherit"
    | "primary"
    | "secondary"
    | "info"
    | undefined = undefined;
  if (amount < 50) {
    color = "error";
  } else if (amount < 80) {
    color = "warning";
  } else {
    color = "success";
  }
  return (
    <Box>
      <Typography variant="overline" color="#333" sx={{ lineHeight: 1, pl: 2 }}>
        Team Review Progress
      </Typography>
      <Stack direction="row" spacing={2} alignItems="center">
        <Button sx={{ flexGrow: 1 }}>
          <Box sx={{ width: "100%", minWidth: "300px" }}>
            <LinearProgress
              variant="determinate"
              value={amount}
              color={color}
              sx={{ height: 12, borderRadius: 5, width: "100%" }}
            />
          </Box>
        </Button>
        <Typography variant="body1" color="#333">
          {amount}%
        </Typography>
      </Stack>
    </Box>
  );
}

function Tools() {
  const patientId = useParams<{ patientId: string }>();
  let patient = null;
  let prevPatient = null;
  let nextPatient = null;
  let patientIndex: number = -1;
  if (patientId.patientId) {
    patientIndex = +patientId.patientId - 1;
  } else {
    patientIndex = 0;
  }
  patient = patientData[patientIndex];
  prevPatient = patientIndex > 0 ? patientData[patientIndex - 1] : null;
  nextPatient =
    patientIndex < patientData.length - 1
      ? patientData[patientIndex + 1]
      : null;
  // const [checked, setChecked] = useState(patient.user_reviewed);
  // const handleSwitchChange = () => {
  //   setChecked(!checked);
  // };
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      flexGrow={1}
      sx={{ pr: 2 }}
    >
      <Box sx={{ pr: 4, pl: 4 }}>
        <Stack direction="row" spacing={6} alignItems="center">
          <Box></Box>
          <Box>
            <Typography variant={isMdUp ? "h5" : "body1"} color="InfoText">
              {patient.fullName} ({patient.gender})
            </Typography>
            <Typography variant="body1" color="#666">
              NHS: {patient.nhs}
            </Typography>
          </Box>
          <Box>
            <Typography variant={isMdUp ? "h5" : "body1"} color="InfoText">
              DOB: {new Date(patient.dob).toLocaleDateString()} [{patient.age}
              yrs]
            </Typography>

            <Typography variant="body1" color="#666">
              Location: Ward A / Bed 12
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        <Stack direction="row" spacing={0} alignItems="center">
          {prevPatient && (
            <Link to={"/patient/" + prevPatient?.id}>
              <Button
                sx={{ minWidth: 210 }}
                startIcon={<KeyboardArrowLeft />}
                size="large"
              >
                Prev: {prevPatient?.fullName}
              </Button>
            </Link>
          )}
          {!prevPatient && (
            <Button
              sx={{ minWidth: 210 }}
              startIcon={<KeyboardArrowLeft />}
              size="large"
              disabled
            >
              Prev
            </Button>
          )}
          {nextPatient && (
            <Link to={"/patient/" + nextPatient?.id}>
              <Button
                endIcon={<KeyboardArrowRight />}
                size="large"
                sx={{ minWidth: 210 }}
              >
                Next: {nextPatient?.fullName}
              </Button>
            </Link>
          )}
          {!nextPatient && (
            <Button
              sx={{ minWidth: 210 }}
              endIcon={<KeyboardArrowRight />}
              size="large"
              disabled
            >
              Next
            </Button>
          )}
          <Link to="/mdt/1">
            <IconButton size="large" color="primary">
              <Groups />
            </IconButton>
          </Link>
        </Stack>
      </Box>
    </Stack>
  );
}

function AsyncPatientViewPage() {
  const patientId = useParams<{ patientId: string }>();
  const dispatch = useDispatch();
  const reviewed = useSelector((state: any) => state.reviewedDemoPatient.value);

  const [open, setOpen] = React.useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleChange = () => {
    dispatch(changeReviewedDemoPatient(!reviewed));
    if (!reviewed) setOpen(true);
  };
  let patient = null;
  let prevPatient = null;
  let nextPatient = null;

  let patientIndex: number = -1;
  if (patientId.patientId) {
    patientIndex = +patientId.patientId - 1;
  } else {
    patientIndex = 0;
  }

  patient = patientData[patientIndex];
  prevPatient = patientIndex > 0 ? patientData[patientIndex - 1] : null;
  nextPatient =
    patientIndex < patientData.length - 1
      ? patientData[patientIndex + 1]
      : null;

  return (
    <React.Fragment>
      <Helmet title="Patient View" />
      <PageNavbar title="Review">
        <Tools />
      </PageNavbar>
      <Box sx={{ p: 6 }}>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Stack
            direction="row"
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >
            {prevPatient && (
              <Link to={"/patient/" + prevPatient?.id}>
                <Button
                  sx={{ minWidth: 210 }}
                  startIcon={<KeyboardArrowLeft />}
                  size="large"
                >
                  Prev: {prevPatient?.fullName}
                </Button>
              </Link>
            )}
            {!prevPatient && (
              <Button
                sx={{ minWidth: 210 }}
                startIcon={<KeyboardArrowLeft />}
                size="large"
                disabled
              >
                Prev
              </Button>
            )}
            {nextPatient && (
              <Link to={"/patient/" + nextPatient?.id}>
                <Button
                  endIcon={<KeyboardArrowRight />}
                  size="large"
                  sx={{ minWidth: 210 }}
                >
                  Next: {nextPatient?.fullName}
                </Button>
              </Link>
            )}
            {!nextPatient && (
              <Button
                sx={{ minWidth: 210 }}
                endIcon={<KeyboardArrowRight />}
                size="large"
                disabled
              >
                Next
              </Button>
            )}
            <Link to="/mdt/1">
              <IconButton size="large" color="primary">
                <Groups />
              </IconButton>
            </Link>
          </Stack>
        </Box>
        <Grid classes="l-page-container" container spacing={4} columns={24}>
          <Grid classes="l-patient-card" item xs={24} sm={24} md={24} lg={19}>
            <Card sx={{ mb: 3 }}>
              <Grid classes="l-patient-card" container>
                <Grid classes="l-card-nav" item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      pb: 2,
                      mt: 2,
                      mx: 4,
                      borderColor: "divider",
                    }}
                  >
                    <Grid
                      classes="l-card-nav-inner"
                      justifyContent="space-between"
                      alignItems="center"
                      container
                    >
                      <Grid classes="l-meeting-link" item pb={0}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          {patient.id === 2 && (
                            <Switch
                              color="success"
                              checked={reviewed}
                              onChange={handleChange}
                            />
                          )}
                          {patient.id !== 2 && (
                            <Switch
                              color="success"
                              checked={patient.user_reviewed}
                            />
                          )}
                          <Typography variant="body1" color="#333">
                            Click to show you have reviewed this patient's
                            record
                          </Typography>
                          <Snackbar
                            open={open}
                            autoHideDuration={3000}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            <Alert
                              onClose={handleClose}
                              severity="success"
                              variant="filled"
                              sx={{ width: "100%" }}
                            >
                              You have completed reviewing the record for{" "}
                              {patient.fullName}
                            </Alert>
                          </Snackbar>
                        </Stack>
                      </Grid>

                      <Grid classes="l-next-button" item>
                        <TeamReviewProgress amount={patient.team_review} />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid classes="l-column-2" item xs={12} sm={12} md={6} lg={6}>
                  <Box sx={{ p: 6 }}>
                    <VitruvianCondition patient={patient} />
                    <Risk patient={patient} />
                    <ReportTimeline patient={patient} />
                  </Box>
                </Grid>
                <SecondColumn
                  classes="l-column-1"
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                >
                  <Box sx={{ p: 6 }}>
                    {/* <DraftCarePlan patient={patient} /> */}
                    <DiscussionCarePlan patient={patient} />
                    <DraftCarePlan patient={patient} />
                  </Box>
                </SecondColumn>
              </Grid>
            </Card>
          </Grid>
          <Grid
            classes="l-patient-comments"
            item
            xs={25}
            sm={25}
            md={25}
            lg={5}
          >
            <Comments patient={patient} />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default AsyncPatientViewPage;
