// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  THINLAYER: "THINLAYER",
  RAMSAY: "RAMSAY",
  FIELDFISHER: "FIELDFISHER",
  NORTHWELL: "NORTHWELL",
  PHOENIX: "PHOENIX",
  BEVAN: "BEVAN",
  NHS: "NHS",
  UCLH: "UCLH",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  SPIRE: "SPIRE",
};

export const NEWS = ["#FFFFFF", "#FFF0AB", "#FDC88A", "#F59680"];
