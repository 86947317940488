const eventData = [
  {
    id: 1,
    event_date: "2024-09-14T15:00:00Z",
    event_end: "2024-09-14T16:00:00Z",
    event_type: "Meeting",
    author: "Dr. Emily Carter",
    author_role: "Consultant",
    event_title: "MDT",
    event_abstract:
      "Risks identified, mitigation strategy articulated via Care Plan: Prehabilitiation recommended, VTE prophylaxis, Regional instead of General Anesthesia recommended",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Care Plan", bgcolor: "#CCE2D9", color: "#005a30" },
      { id: 2, name: "Prevention", bgcolor: "#f4cdc6", color: "#2a0b06" },
    ],
    quality: 5,
    reviewed: true,
    notes: null,
  },
  {
    id: 2,
    event_date: "2024-09-17T15:00:00Z", // "2024-09-17T15:00:00Z",
    event_end: "2024-09-17T16:00:00Z", // "2024-09-17T16:00:00Z",
    event_type: "Consultation",
    author: "Dr. Sarah Hughes",
    author_role: "Consultant",
    event_title: "Physio Consultation",
    event_abstract:
      "Met with physiotherapist for general assessment and prescription of basic cardiac pre-habilitation routine: 2x/week in clinic for 2 weeks, with simple, daily home exercises as well",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Prehab", bgcolor: "#e8f1f8", color: "#0c2d4a" },
      { id: 2, name: "Prevention", bgcolor: "#f4cdc6", color: "#2a0b06" },
    ],
    quality: 5,
    reviewed: true,
    notes: null,
  },
  {
    id: 3,
    event_date: "2024-09-19T15:00:00Z", // "2024-09-19T15:00:00Z",
    event_end: "2024-09-19T16:00:00Z", // "2024-09-19T16:00:00Z",
    event_type: "Appointment",
    author: "John Wilkinson",
    author_role: "Physiotherapist",
    event_title: "Physio Clinic Session",
    event_abstract:
      "Patient called clinic to report he was having difficulty with stairs at home, and couldn't get to the clinic for his scheduled pre-habilitation appointment. [No further follow-up was pursued by clinic or patient]",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Accessibility", bgcolor: "#efdfed", color: "#491644" },
      { id: 2, name: "Prehab", bgcolor: "#e8f1f8", color: "#0c2d4a" },
    ],
    quality: 5,
    reviewed: true,
    notes: null,
  },
  {
    id: 4,
    event_date: "2024-09-24T15:00:00Z", // "2024-09-24T15:00:00Z",
    event_end: "2024-09-24T16:00:00Z", // "2024-09-24T16:00:00Z",
    event_type: "Measurement",
    author: "Dr. Anna Thompson",
    author_role: "Junior Doctor",
    event_title: "Pre-operative Labs & EKG",
    event_abstract:
      "Complete blood count, PT, PTT, chem 20 panel and EKG obtained at lab facility (no interval change from those obtained for the MDT)",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Routine", bgcolor: "#fcd6c3", color: "#6e3619" },
      { id: 2, name: "No Change", bgcolor: "#e8f1f8", color: "#0c2d4a" },
    ],
    quality: 1,
    reviewed: false,
    notes: "(No interval change from those obtained for the MDT)",
  },
  {
    id: 5,
    event_date: "2024-09-28T06:45:00Z", // "2024-09-28T06:45:00Z",
    event_end: "2024-09-28T06:45:00Z", // "2024-09-28T06:45:00Z",
    event_type: "Observation",
    author: "Nurse Lisa Brown",
    author_role: "Nurse",
    event_title: "Pre-operative nursing note",
    event_abstract:
      "Brief history and physical note, and description of Right Antecubital IV line insertion (16 gauge) with 1 liter of D5NS hung at tko (to keep open) drip rate.",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Routine", bgcolor: "#fcd6c3", color: "#6e3619" },
      { id: 2, name: "Prep", bgcolor: "#fff7bf", color: "#594d00" },
    ],
    quality: 4,
    reviewed: false,
    notes: null,
  },
  {
    id: 6,
    event_date: "2024-09-28T06:55:00Z", // "2024-09-28T06:55:00Z",
    event_end: "2024-09-28T06:55:00Z", // "2024-09-28T06:55:00Z",
    event_type: "Note",
    author: "Dr. Mark Evans",
    author_role: "Registrar",
    event_title: "Pre-operative assessment by Anesthesia registrar",
    event_abstract:
      "Brief history and physical obtained, but electronic medical records were not available for reference owing to server outage. Evaluated as ASA 1, normal airway, consented for surgery and general anesthesia",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Breach", bgcolor: "#f4cdc6", color: "#2a0b06" },
      { id: 2, name: "Risk", bgcolor: "#f9e1ec", color: "#6b1c40" },
      { id: 3, name: "IT Outage", bgcolor: "#e8f1f8", color: "#0c2d4a" },
    ],
    quality: 5,
    reviewed: true,
    notes:
      " [No mention of cardiac history or VTE prophylaxis; no consideration of regional instead of general anesthesia as per MDT plan]",
  },
  {
    id: 7,
    event_date: "2024-09-28T07:30:00Z", // "2024-09-28T07:30:00Z",
    event_end: "2024-09-28T08:30:00Z", // "2024-09-28T08:30:00Z",
    event_type: "Note",
    author: "Dr. Mark Evans",
    author_role: "Registrar",
    event_title: "Anesthesia Note by Anesthesia Registrar",
    event_abstract:
      "R hip procedure started with induction of general anesthesia (standard protocol) with first incision at 07:40 am; anethesia log and physiologic log (EKG, pulse ox, RR, etc.) begun.",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Anaesthesia", bgcolor: "#f9e1ec", color: "#6b1c40" },
      { id: 2, name: "Breach", bgcolor: "#f4cdc6", color: "#2a0b06" },
    ],
    quality: 5,
    reviewed: false,
    notes:
      "Care being administered by Anesthesia Registrar, while Attending Anesthesiologist was instructing a medical student in another operating theater",
  },

  {
    id: 8,
    event_date: "2024-09-28T08:55:00Z", // "2024-09-28T08:55:00Z",
    event_end: "2024-09-28T08:55:00Z", // "2024-09-28T08:55:00Z",
    event_type: "Note",
    author: "Dr. Mark Evans",
    author_role: "Registrar",
    event_title: "Anesthesia Resuscitation Note",
    event_abstract:
      "Patient reported to have dropped blood pressure followed by two minutes of ventricular tachycardia. CPR initiated per ACLS protocol, cardioverted back into normal sinus rhthym. 1-2mm ST segment elevation across anterior chest leads, suggestive of ischemia. Surgery put on hold, nitrates given. Anesthesia Registrar calls for Attending Anesthesiologist who takes over primary duties at this point.",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Resuscitation", bgcolor: "#e5e6e7", color: "#282d30" },
      { id: 2, name: "Complication", bgcolor: "#fff7bf", color: "#594d00" },
    ],
    quality: 5,
    reviewed: true,
    notes: null,
  },
  {
    id: 9,
    event_date: "2024-09-28T08:55:00Z", // "2024-09-28T08:55:00Z",
    event_end: "2024-09-28T08:55:00Z", // "2024-09-28T08:55:00Z",
    event_type: "Measurement",
    author: "Dr. Alex Patel",
    author_role: "Junior Doctor",
    event_title: "Labs Sent",
    event_abstract: "Electrolytes, CPK-MB, Troponin levels, CBC sent",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Causation", bgcolor: "#cce2d8", color: "#005a30" },
      { id: 2, name: "Complication", bgcolor: "#fff7bf", color: "#594d00" },
      { id: 3, name: "Labs", bgcolor: "#efdfed", color: "#491644" },
    ],
    quality: 5,
    reviewed: true,
    notes: null,
  },
  {
    id: 10,
    event_date: "2024-09-28T08:55:00Z", // "2024-09-28T08:55:00Z",
    event_end: "2024-09-28T08:55:00Z", // "2024-09-28T08:55:00Z",
    event_type: "Observation",
    author: "Dr. Rachel Lee",
    author_role: "Radiologist",
    event_title: "12-lead EKG",
    event_abstract: "EKG as noted above",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Ischemia", bgcolor: "#f4cdc6", color: "#2a0b06" },
      { id: 2, name: "Complication", bgcolor: "#fff7bf", color: "#594d00" },
    ],
    quality: 5,
    reviewed: false,
    notes: "Acute ischemic changes noted",
  },
  {
    id: 11,
    event_date: "2024-09-28T09:00:00Z", // "2024-09-28T09:00:00Z",
    event_end: "2024-09-28T09:00:00Z", // "2024-09-28T09:00:00Z",
    event_type: "Note",
    author: "Dr. Mark Evans",
    author_role: "Registrar",
    event_title: "Procedure Note",
    event_abstract:
      "Right Subclavian Central Line Placed (procedure note) by anesthesia registrar",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Procedure", bgcolor: "#d4ecea", color: "#10403c" },
      { id: 2, name: "Risk", bgcolor: "#f9e1ec", color: "#6b1c40" },
    ],
    quality: 5,
    reviewed: false,
    notes: null,
  },
  {
    id: 12,
    event_date: "2024-09-28T09:10:00Z", // "2024-09-28T09:10:00Z",
    event_end: "2024-09-28T09:10:00Z", // "2024-09-28T09:10:00Z",
    event_type: "Procedure",
    author: "Dr. Emily Carter",
    author_role: "Consultant",
    event_title: "Surgery Recommenced",
    event_abstract:
      "Patient in NSR with stable pressures, and procedure 90% complete - anesthesia note documents discussion to recommence and complete hip replacement surgery, consisting of closing the surgical site per standard methods; ICU alerted the patient to be transferred there post-operatively for observation and 'rule-out myocardial infarction'",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Judgment", bgcolor: "#bbd4ea", color: "#0c2d4a" },
      { id: 2, name: "Procedure", bgcolor: "#d4ecea", color: "#10403c" },
      { id: 3, name: "Completion", bgcolor: "#CCE2D9", color: "#005a30" },
    ],
    quality: 3,
    reviewed: false,
    notes: null,
  },
  {
    id: 13,
    event_date: "2024-09-28T09:30:00Z", // "2024-09-28T09:30:00Z",
    event_end: "2024-09-28T09:30:00Z", // "2024-09-28T09:30:00Z",
    event_type: "Note",
    author: "Dr. Emily Carter",
    author_role: "Consultant",
    event_title: "Anesthesia Post-Operative Note",
    event_abstract: "Surgery completed, prepped for transfer to ICU",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Outcome", bgcolor: "#e8f1f8", color: "#0c2d4a" },
      { id: 2, name: "Procedure", bgcolor: "#d4ecea", color: "#10403c" },
    ],
    quality: 2,
    reviewed: true,
    notes: null,
  },
  {
    id: 14,
    event_date: "2024-09-28T09:45:00Z", // "2024-09-28T09:45:00Z",
    event_end: "2024-09-28T09:45:00Z", // "2024-09-28T09:45:00Z",
    event_type: "Note",
    author: "Dr. Emily Carter",
    author_role: "Consultant",
    event_title: "Surgical Note",
    event_abstract:
      "Mr Cash's writeup of the surgical procedure with mention of the resuscitation; from an orthopedic perspective, the procedure went well",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 2, name: "Transfer", bgcolor: "#d4ecea", color: "#10403c" },
      { id: 1, name: "Monitoring", bgcolor: "#e5e6e7", color: "#282d30" },
    ],
    quality: 1,
    reviewed: true,
    notes: null,
  },
  {
    id: 15,
    event_date: "2024-09-28T10:00:00Z", // "2024-09-28T10:00:00Z",
    event_end: "2024-09-28T10:00:00Z", // "2024-09-28T10:00:00Z",
    event_type: "Note",
    author: "Nurse Lisa Brown",
    author_role: "Nurse",
    event_title: "ICU Nurse Admission Note",
    event_abstract:
      "Arrival in ICU, note documenting stable vital signs on monitor, IV access (R antecubital IV, R subclavian central line), surgical site bandaged, Foley catheter in place, patient somnolent but responsive to voice, appears in no acute distress",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "MI", bgcolor: "#CCE2D9", color: "#005a30" },
      { id: 2, name: "Complication", bgcolor: "#fff7bf", color: "#594d00" },
    ],
    quality: 1,
    reviewed: true,
    notes: null,
  },
  {
    id: 16,
    event_date: "2024-09-28T10:30:00Z", // "2024-09-28T10:30:00Z",
    event_end: "2024-09-28T10:30:00Z", // "2024-09-28T10:30:00Z",
    event_type: "Note",
    author: "Dr. Michael Stone",
    author_role: "ICU Fellow",
    event_title: "ICU Fellow Admission Note",
    event_abstract:
      "Brief note from ICU Intensivist reflecting likely intraoperative myocardial infarction with a brief run of ventricular tachycardia. Patient's mental status is improving, and he is hemodynamically stable. Will monitor carefully, check cardiac blood tests every 8 hours while on a nitroglycerin sliding scale. Cardiology consultation requested and ECHO ordered.",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Plan Missed", bgcolor: "#fcd6c3", color: "#6e3619" },
      { id: 2, name: "Causation", bgcolor: "#cce2d8", color: "#005a30" },
    ],
    quality: 1,
    reviewed: true,
    notes: null,
  },
  {
    id: 17,
    event_date: "2024-09-28T15:30:00Z", // "2024-09-28T15:30:00Z",
    event_end: "2024-09-28T15:30:00Z", // "2024-09-28T15:30:00Z",
    event_type: "Consultation",
    author: "Dr. Susan Harper",
    author_role: "Attending Intensivist",
    event_title: "Cardiology Consult",
    event_abstract:
      "Cardiologist visit: concludes that an intraoperative MI took place, looking at Troponin levels, serial EKGs and the intraoperative tracings showing 2 minutes of ventricular tachycardia. Reviewed prior EHR records and noted MDT care plan recommendation for regional anesthesia, while general anesthesia was recommended. He also noted that pre-habilitation was not performed as recommended, owing to the patient's difficulty getting to the Physio's clinic given his poor mobility and no one being able to get him to his appointments. Plan ECHO, serial EKGs and cardiac blood tests, supportive care.",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Causation", bgcolor: "#cce2d8", color: "#005a30" },
      { id: 2, name: "CHF", bgcolor: "#f4cdc6", color: "#2a0b06" },
      { id: 3, name: "Monitoring", bgcolor: "#e5e6e7", color: "#282d30" },
    ],
    quality: 1,
    reviewed: true,
    notes: null,
  },
  {
    id: 18,
    event_date: "2024-09-28T18:30:00Z", // "2024-09-28T18:30:00Z",
    event_end: "2024-09-28T18:30:00Z", // "2024-09-28T18:30:00Z",
    event_type: "Note",
    author: "Dr. Michael Stone",
    author_role: "ICU Fellow",
    event_title: "Attending Intensivist Shift Note",
    event_abstract:
      "Attending Intensivist: summary of the sequence of events, evolution of cardiac ischemia and current stable hemodynamics, with EKG suggestive of anterior wall ST-elevated MI (STEMI).",
    link_to_pdf: "Patient Record",
    tags: [
      { id: 1, name: "Injury", bgcolor: "#f4cdc6", color: "#2a0b06" },
      { id: 2, name: "MI", bgcolor: "#CCE2D9", color: "#005a30" },
      { id: 3, name: "Labs", bgcolor: "#efdfed", color: "#491644" },
    ],
    quality: 1,
    reviewed: true,
    notes: null,
  },
  {
    id: 19,
    event_date: "2024-09-28T20:00:00Z", // "2024-09-28T20:00:00Z",
    event_end: "2024-09-28T20:00:00Z", // "2024-09-28T20:00:00Z",
    event_type: "Note",
    author: "Dr. Anna Thompson",
    author_role: "Junior Doctor",
    event_title: "ICU Fellow Progress Note",
    event_abstract:
      "SOAP Note with vitals (afebrile), labs and EKG reported. Patient reporting some degree of pain in R hip surgical site, but no bleeding or discharge, and no chest pain, shortness of breath or diaphoresis. Chest clear to auscultation, but 1+ Pitting Edema noted up to the kneecap in bilateral lower extremities.",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Injury", bgcolor: "#f4cdc6", color: "#2a0b06" },
      { id: 2, name: "MI", bgcolor: "#CCE2D9", color: "#005a30" },
      { id: 3, name: "ECHO", bgcolor: "#bbd4ea", color: "#0c2d4a" },
    ],
    quality: 1,
    reviewed: true,
  },
  {
    id: 20,
    event_date: "2024-09-28T20:30:00Z", // "2024-09-28T20:30:00Z",
    event_end: "2024-09-28T20:30:00Z", // "2024-09-28T20:30:00Z",
    event_type: "Measurement",
    author: "Dr Rachel Lee",
    author_role: "Radiologist",
    event_title: "Labs Results",
    event_abstract:
      "Troponin levels peaked at 3.5 ng/mL, with serial EKGs showing no further ischemic changes",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Procedure", bgcolor: "#d4ecea", color: "#10403c" },
      { id: 2, name: "MI", bgcolor: "#CCE2D9", color: "#005a30" },
    ],
    quality: 1,
    reviewed: true,
  },
  {
    id: 21,
    event_date: "2024-09-28T21:15:00Z", // "2024-09-28T21:15:00Z",
    event_end: "2024-09-28T21:15:00Z", // "2024-09-28T21:15:00Z",
    event_type: "Observation",
    author: "Dr. James Oliver",
    author_role: "Consultant",
    event_title: "Portable ECHO",
    event_abstract:
      "Left ventricular systolic dysfunction noted, reduced ejection fraction",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Monitoring", bgcolor: "#e5e6e7", color: "#282d30" },
      { id: 2, name: "Post-Procedure", bgcolor: "#d4ecea", color: "#10403c" },
    ],
    quality: 2,
    reviewed: true,
    notes: null,
  },
  {
    id: 22,
    event_date: "2024-09-28T23:00:00Z", // "2024-09-28T23:00:00Z",
    event_end: "2024-09-28T23:00:00Z", // "2024-09-28T23:00:00Z",
    event_type: "Procedure",
    author: "Nurse Emily Watson",
    author_role: "ICU Nurse",
    event_title: "Cardiac Catheterization & Procedure Note",
    event_abstract:
      "Taken to cath lab given increasing CVP and peripheral edema. 95% occlusion of the Left Anterior Descending (LAD) coronary artery identified and a drug eluting stent was successfully placed.",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Monitoring", bgcolor: "#e5e6e7", color: "#282d30" },
      { id: 2, name: "Routine", bgcolor: "#fcd6c3", color: "#6e3619" },
    ],
    quality: 3,
    reviewed: true,
    notes: null,
  },
  {
    id: 23,
    event_date: "2024-09-29T00:30:00Z", // "2024-09-29T00:30:00Z",
    event_end: "2024-09-29T00:30:00Z", // "2024-09-29T00:30:00Z",
    event_type: "Note",
    author: "Dr. Alex Patel",
    author_role: "Junior Doctor",
    event_title: "ICU Nurse Post-Cardiac Cath Note",
    event_abstract:
      "Patient received back in his ICU bed with groin bag placed over left femoral access; no local bleeding noted; peripheral pulses intact. EKG: NSR without ectopy or acute ischemic changes.",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 3, name: "Labs", bgcolor: "#efdfed", color: "#491644" },
      { id: 2, name: "Routine", bgcolor: "#fcd6c3", color: "#6e3619" },
    ],
    quality: 4,
    reviewed: true,
    notes: null,
  },

  {
    id: 25,
    event_date: "2024-09-29T06:00:00Z", // "2024-09-29T06:00:00Z",
    event_end: "2024-09-29T06:00:00Z", // "2024-09-29T06:00:00Z",
    event_type: "Measurement",
    author: "Dr. Susan Harper",
    author_role: "Attending Intensivist",
    event_title: "Bloods drawn",
    event_abstract: null,
    link_to_pdf: null,
    tags: [
      { id: 1, name: "Monitoring", bgcolor: "#e5e6e7", color: "#282d30" },
      { id: 2, name: "Routine", bgcolor: "#fcd6c3", color: "#6e3619" },
    ],
    quality: 3,
    reviewed: true,
    notes: null,
  },
  {
    id: 26,
    event_date: "2024-09-29T06:15:00Z", // "2024-09-29T06:15:00Z",
    event_end: "2024-09-29T06:15:00Z", // "2024-09-29T06:15:00Z",
    event_type: "Observation",
    author: "Dr. Michael Stone",
    author_role: "ICU Fellow",
    event_title: "EKG taken",
    event_abstract: "No evolution of ischemic changes, NSR without ectopy.",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [{ id: 1, name: "Stability", bgcolor: "#cce2d8", color: "#005a30" }],
    quality: 5,
    reviewed: true,
    notes: null,
  },
  {
    id: 27,
    event_date: "2024-09-29T06:30:00Z", // "2024-09-29T06:30:00Z",
    event_end: "2024-09-29T06:30:00Z", // "2024-09-29T06:30:00Z",
    event_type: "Note",
    author: "Dr. James Oliver",
    author_role: "Consultant",
    event_title: "Attending Intensivist Shift Note",
    event_abstract:
      "SOAP Note detailing the events of last night, including successful cardiac catheterization and stenting. Pain free, with only trace peripheral edema in his lower extremities.",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [{ id: 1, name: "Monitoring", bgcolor: "#e5e6e7", color: "#282d30" }],
    quality: 3,
    reviewed: true,
    notes: null,
  },
  {
    id: 28,
    event_date: "2024-09-29T08:15:00Z", // "2024-09-29T08:15:00Z",
    event_end: "2024-09-29T08:15:00Z", // "2024-09-29T08:15:00Z",
    event_type: "Note",
    author: "Dr. Michael Stone",
    author_role: "ICU Fellow",
    event_title: "ICU Fellow Progress Note",
    event_abstract:
      "More detailed SOAP note from the one above, detailing catheterization, hemodynamics overnight, the morning's labs (Troponin levels peaked last night), and plan for at least one more day in the ICU for observation",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [{ id: 1, name: "Continuity", bgcolor: "#e8f1f8", color: "#0c2d4a" }],
    quality: 2,
    reviewed: true,
    notes: null,
  },
  {
    id: 29,
    event_date: "2024-09-29T09:00:00Z", // "2024-09-29T09:00:00Z",
    event_end: "2024-09-29T09:00:00Z", // "2024-09-29T09:00:00Z",
    event_type: "Note",
    author: "Dr. James Oliver",
    author_role: "Consultant",
    event_title: "Cardiology Consult Follow-Up Note",
    event_abstract:
      "Post-catheterization check - groin without bleeding or hematoma; peripheral pulses intact. No acute ischemic changes on EKG but CVP remains high normal (10-12 mmHg) with trace peripheral edema.",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [{ id: 1, name: "Monitoring", bgcolor: "#e5e6e7", color: "#282d30" }],
    quality: 1,
    reviewed: true,
    notes: null,
  },
  {
    id: 30,
    event_date: "2024-09-29T13:00:00Z", // "2024-09-29T13:00:00Z",
    event_end: "2024-09-29T13:00:00Z", // "2024-09-29T13:00:00Z",
    event_type: "Consultation",
    author: "Dr. James Oliver",
    author_role: "Consultant",
    event_title: "Cardiac Rehabilitation Consultation",
    event_abstract:
      "Cardiac rehab specialist evaluated patient for transfer to inpatient cardiac rehab given the nature of his acute, intraoperative MI and mild CHF.",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [
      { id: 1, name: "Rehabilitation", bgcolor: "#cce2d8", color: "#005a30" },
    ],
    quality: 5,
    reviewed: true,
    notes: null,
  },
  {
    id: 31,
    event_date: "2024-09-29T14:30:00Z", // "2024-09-29T14:30:00Z",
    event_end: "2024-09-29T14:30:00Z", // "2024-09-29T14:30:00Z",
    event_type: "Note",
    author: "Dr William Shatner",
    author_role: "Consultant",
    event_title: "Orthopedic Progress Note",
    event_abstract:
      "Mr. Cash visits patient for post-operative check, with cardiac interventions noted. Cleared for gentle hip physiotherapy as cleared by the ICU team and Cardiologist.",
    link_to_pdf: "/pdfs/Consultation-Note.pdf",
    tags: [{ id: 1, name: "Care Plan", bgcolor: "#CCE2D9", color: "#005a30" }],
    quality: 5,
    reviewed: true,
    notes: null,
  },
];

export default eventData;
