//react imports
import React from "react";

//MUI imports
import { Card } from "@mui/material";

//Project imports
import CardTitle from "../card/CardTitle";

const Actions = () => {
  return (
    <Card>
      <CardTitle title="Actions" />
    </Card>
  );
};

export default Actions;
