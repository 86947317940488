import styled from "@emotion/styled";
import {
  Avatar,
  Box,
  Card as MuiCard,
  CardHeader,
  Collapse,
  Divider as MuiDivider,
  Stack,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useState } from "react";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Reply from "./Reply";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);

interface ClinicianCommentProps {
  content: string;
  by: string;
  avatar: string;
  id: number;
  date: string;
  specialty: string;
  replies: any[];
}

const ClinicianComment = ({
  content,
  by,
  avatar,
  specialty,
  date,
  id,
  replies,
}: ClinicianCommentProps) => {
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let replyCount = replies ? replies.length : 0;
  let replyContent = null;
  let replyCountText = replyCount === 1 ? "reply" : "replies";
  if (replyCount > 0) {
    replyContent = (
      <Box sx={{ width: "100%", paddingBottom: 0, paddingTop: 0, paddingX: 4 }}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography variant="body1" gutterBottom>
            {replyCount} {replyCountText}
          </Typography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Stack>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider mt={2} mb={0} />
          {replies.map((reply: any, index: number) => (
            <Box key={index} sx={{ position: "relative" }}>
              <Stack direction="row" pt={4} spacing={3} alignItems="flex-start">
                <Avatar alt={reply.by} src={reply.avatar} />
                <Box sx={{ width: "100%" }}>
                  <Typography variant="h5">{reply.by}</Typography>
                  <Typography
                    variant="subtitle2"
                    pb={2}
                    sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                  >
                    {reply.speciality}
                  </Typography>
                  <Divider mt={0} mb={2} />
                  <Typography variant="body1" gutterBottom>
                    {reply.content}
                  </Typography>
                </Box>
              </Stack>
              <Typography
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 38,
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                {new Date(reply.date).toLocaleDateString()}
              </Typography>
            </Box>
          ))}
          <Reply position={id - 1} open={false} />
        </Collapse>
      </Box>
    );
  } else {
    replyContent = (
      <Box sx={{ width: "100%", paddingBottom: 0, paddingTop: 0, paddingX: 4 }}>
        <Reply position={id - 1} open={false} />
      </Box>
    );
  }

  return (
    <Card variant="outlined" sx={{ mb: 3, position: "relative" }}>
      <CardHeader
        avatar={<Avatar alt={by} src={avatar} />}
        title={by}
        subheader={specialty}
      />
      <Typography
        sx={{
          position: "absolute",
          right: 24,
          top: 38,
          color: "rgba(0, 0, 0, 0.6)",
        }}
      >
        {date}
      </Typography>
      <Divider mt={0} mb={0} />
      <Box sx={{ paddingBottom: 0, paddingTop: 4, paddingX: 4 }}>
        <Typography variant="body1" gutterBottom>
          {content}
        </Typography>
      </Box>
      <Box>{replyContent}</Box>
    </Card>
  );
};

export default ClinicianComment;
