import React from "react";

import { Box, Stack, Typography } from "@mui/material";

import AiApprovalBox from "../../../../aiBox/AiApprovalBox";
import PictureSwitch from "../../../../patient/mdt/PictureSwitch";
import NursePic from "../../../../../assets/images/avatars/nurse.jpg";

import MarkPic from "../../../../../assets/images/avatars/MarkAppleby.jpg";
import DCashPic from "../../../../../assets/images/avatars/mr-david-cash.jpg";

import IvanovaPic from "../../../../../assets/images/avatars/dobrinka-ivanova.png";
import NickPic from "../../../../../assets/images/avatars/NickTaylor2.png";
// import GuestPic from "../../../../../assets/images/avatars/guest-user.jpg";
import ScottPic from "../../../../../assets/images/avatars/Scott.png";

const Content = () => {
  return <div></div>;
};

function CarePlan01() {
  return (
    <Box sx={{}}>
      <Typography variant="h4" sx={{ mb: 1 }}>
        Care Plan
      </Typography>
      <Stack
        direction="row"
        spacing={0}
        alignItems="center"
        flexWrap="wrap"
        mb={2}
      >
        <PictureSwitch checked={false} picture={ScottPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={DCashPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={MarkPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={IvanovaPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={NickPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={NursePic}></PictureSwitch>
      </Stack>

      <AiApprovalBox approved={false} approvedBy="" content={<Content />} />
    </Box>
  );
}

export default CarePlan01;
