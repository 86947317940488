//react imports
import React from "react";

//MUI imports
import { Box, Button, Card, Link, Typography } from "@mui/material";
import { Person, PlayArrow } from "@mui/icons-material";

//Project imports
import CardTitle from "../card/CardTitle";

const Parties = () => {
  return (
    <Card>
      <CardTitle title="Parties" />
      <Box sx={{ px: 5, py: 6 }}>
        <Typography variant="overline">Legal Teams</Typography>
        {/* Plaintiff*/}
        <Box sx={{ display: "flex", pb: 2, pt: 4 }}>
          <Box sx={{ width: "85px", flexShrink: 0, pr: 2 }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 700, textAlign: "right" }}
            >
              Plaintiff:
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">
              Kensington Law Chambers <br />
              55 Piccadilly, <br />
              London, W1J 0DX <br />
              +44 20 8495 3344
              <br />
              enquiries@kensingtonlaw.co.uk
            </Typography>
          </Box>
        </Box>
        {/* Respondent*/}
        <Box sx={{ display: "flex", pb: 6 }}>
          <Box sx={{ width: "85px", flexShrink: 0, pr: 2 }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 700, textAlign: "right" }}
            >
              Respondent:
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">
              Westminster Legal Group
              <br />
              120 Victoria Street, <br />
              London, SW1E 5LA
              <br />
              +44 20 7610 4567
              <br />
              support@westminsterlg.co.uk
            </Typography>
          </Box>
        </Box>
        <Typography variant="overline">Patients</Typography>
        <Button
          component={Link}
          href="/chronology"
          startIcon={<Person />}
          endIcon={<PlayArrow />}
          variant="contained"
          fullWidth
          sx={{ mt: 1 }}
        >
          ELIJAH BAILEY DETAILS
        </Button>
      </Box>
    </Card>
  );
};

export default Parties;
