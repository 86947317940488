// react imports
import React from "react";
import { useState } from "react";

// MUI imports
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Stack,
  TextField,
} from "@mui/material";
import { DateTimeRangePicker } from "@mui/x-date-pickers-pro/DateTimeRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { DateRange } from "@mui/x-date-pickers-pro/models";

// Icons
import { AttachFile, AddBox } from "@mui/icons-material";

// Interface for the props
interface NewCaseProps {
  patient: string;
  rationale: string;
}

// Component for the New Case Dialog
function NewCase() {
  const [open, setOpen] = useState(false);
  const [dateValues, setDateValues] = useState<DateRange<Date>>([null, null]);
  const [formValues, setFormValues] = useState<NewCaseProps>({
    patient: "",
    rationale: "",
  });

  // Function to handle the patient change
  const handlePatientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, patient: event.target.value });
  };

  // Function to handle the rationale change
  const handleRationaleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormValues({ ...formValues, rationale: event.target.value });
  };

  // Function to handle the dialog open
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Function to handle the dialog close
  const handleClose = () => {
    setFormValues({ patient: "", rationale: "" });
    setOpen(false);
  };

  // Function to handle the dialog submit
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(formValues);
    handleClose();
  };

  // Return the JSX for the New Case Dialog
  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<AddBox />}
        onClick={handleClickOpen}
      >
        New Case
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Slide}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) =>
            handleSubmit(event),
        }}
      >
        {/* <form onSubmit={handleSubmit}> */}
        <DialogTitle
          id="alert-dialog-slide-title"
          sx={{ py: 5, fontWeight: 800, fontSize: "1.5em" }}
        >
          New Case
        </DialogTitle>
        <DialogContent>
          <Stack spacing={6}>
            <DialogContentText id="alert-dialog-slide-description">
              Placeholder form components for creating a new case
            </DialogContentText>

            <TextField
              fullWidth
              label="Claimant"
              variant="filled"
              value={formValues.patient}
              onChange={handlePatientChange}
            />
            <TextField
              fullWidth
              label="Case"
              variant="filled"
              value={formValues.rationale}
              onChange={handleRationaleChange}
            />
            <TextField
              fullWidth
              label="Lawyer"
              variant="filled"
              value={formValues.rationale}
              onChange={handleRationaleChange}
            />
            <TextField
              fullWidth
              label="Category"
              variant="filled"
              value={formValues.rationale}
              onChange={handleRationaleChange}
            />
            <TextField
              fullWidth
              label="Phase"
              variant="filled"
              value={formValues.rationale}
              onChange={handleRationaleChange}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimeRangePicker
                localeText={{ start: "Start Date", end: "End Date" }}
                value={dateValues}
                onChange={(newValue) => {
                  setDateValues(newValue);
                }}
              />
            </LocalizationProvider>
            {/* <FormControlLabel control={<Checkbox />} label="Add attachments" /> */}
            <Button
              variant="contained"
              color="primary"
              startIcon={<AttachFile />}
            >
              Attach File
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>
    </React.Fragment>
  );
}

// Export the NewCase component
export default NewCase;
