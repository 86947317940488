import React from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

import Checkbox from "@mui/material/Checkbox";

import patientCarePlanData from "../../patientData/patientCarePlanData";

function Agenda(patient: any) {
  const thisPatientId = patient.patient.id;
  const thisCarePlan = patientCarePlanData.find(
    (carePlan) => carePlan.id === thisPatientId
  );

  return (
    <Box mb={6}>
      <Stack direction="row" spacing={1}>
        <Typography variant="h4">Agenda</Typography>
      </Stack>
      <List>
        {thisCarePlan &&
          thisCarePlan.points.map((point) => (
            <React.Fragment key={point.id}>
              <Divider />
              <ListItem sx={{ p: 0 }}>
                <ListItemButton>
                  <ListItemIcon>
                    <Checkbox
                      edge="end"
                      tabIndex={-1}
                      color="success"
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={point.text} />
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          ))}
      </List>
    </Box>
  );
}

export default Agenda;
