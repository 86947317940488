import { Box } from "@mui/material";

import RiskAssessmentEmpty from "../risk/RiskAssesmentEmpty";
import RiskAssessment01 from "../risk/RiskAssesment01";
import RiskAssessment02 from "../risk/RiskAssesment02";

const RiskContent = (thisPatient: any) => {
  switch (thisPatient.id) {
    case 1:
      return <RiskAssessment01 />;
    case 2:
      return <RiskAssessment02 />;
    default:
      return <RiskAssessmentEmpty />;
  }
};
function Risk(patient: any) {
  const thisPatient = patient.patient;

  return <Box mb={6}>{RiskContent(thisPatient)}</Box>;
}

export default Risk;
