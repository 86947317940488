import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Outlet, useLocation } from "react-router-dom";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import NavToggle from "../components/navbar/NavToggle";
import tlcItems from "../components/sidebar/Ramsay/tlcItems";

import TLCSidebar from "../components/sidebar/Ramsay/TLCSidebar";
import Footer from "../components/Footer";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("xl")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  ${(props) => props.theme.breakpoints.up("xl")} {
    max-width: calc(100% - ${drawerWidth}px);
  }
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  fill: currentColor;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
`;

interface DashboardType {
  children?: React.ReactNode;
}

const RamsayTLC: React.FC<DashboardType> = ({ children }) => {
  const router = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Close mobile menu when navigation occurs
  useEffect(() => {
    setMobileOpen(false);
  }, [router.pathname]);

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: "block", xl: "none" } }}>
          <TLCSidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={tlcItems}
          />
        </Box>
        <Box sx={{ display: { xs: "none", xl: "block" } }}>
          <TLCSidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={tlcItems}
          />
        </Box>
      </Drawer>
      <AppContent>
        <NavToggle onDrawerToggle={handleDrawerToggle} size="xl" />
        <MainContent p={isLgUp ? 7 : 5}>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
      {/* <Settings /> */}
    </Root>
  );
};

export default RamsayTLC;
