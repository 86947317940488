import React from "react";
import styled from "@emotion/styled";
import { Groups } from "@mui/icons-material";

import {
  Card as MuiCard,
  CardHeader,
  LinearProgress,
  Paper as MuiPaper,
} from "@mui/material";
import {
  DataGrid,
  gridClasses,
  GridEventListener,
  GridToolbar,
} from "@mui/x-data-grid";

import { spacing } from "@mui/system";
import { useNavigate } from "react-router-dom";

import mdtMeetingsData from "./MdtMeetingsData";

const Card = styled(MuiCard)(spacing);

// const Chip = styled(MuiChip)<{ color: string }>`
//   height: 20px;
//   padding: 4px 0;
//   font-size: 90%;
//   background-color: ${(props) =>
//     props.theme.palette[props.color ? props.color : "primary"].light};
//   color: ${(props) => props.theme.palette.common.white};
// `;

const Paper = styled(MuiPaper)(spacing);
const CenterElement = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MdtMeetings = () => {
  const navigate = useNavigate();
  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    navigate(`/mdt/${params.id}`);
  };

  return (
    <Card mb={3}>
      <CardHeader avatar={<Groups color="primary" />} title="Meetings" />
      <Paper>
        <DataGrid
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          }}
          rows={mdtMeetingsData}
          columns={[
            { field: "name", headerName: "Date", width: 100 },
            {
              field: "patients",
              headerName: "#Patients",
              width: 100,
              align: "center",
            },
            { field: "dept", headerName: "Department", flex: 1 },
            {
              field: "reviewed",
              headerName: "Reviewed",
              disableColumnMenu: true,
              flex: 1,
              headerAlign: "center",
              align: "center",
              renderCell: (params) => {
                if (params.value <= 49) {
                  return (
                    <CenterElement>
                      <LinearProgress
                        value={params.value}
                        color="error"
                        variant="determinate"
                        sx={{ height: 12, borderRadius: 5, width: "90%" }}
                      />
                    </CenterElement>
                  );
                } else if (params.value <= 79) {
                  return (
                    <CenterElement>
                      <LinearProgress
                        value={params.value}
                        color="warning"
                        variant="determinate"
                        sx={{ height: 12, borderRadius: 5, width: "90%" }}
                      />
                    </CenterElement>
                  );
                } else {
                  return (
                    <CenterElement>
                      <LinearProgress
                        value={params.value}
                        color="success"
                        variant="determinate"
                        sx={{ height: 12, borderRadius: 5, width: "90%" }}
                      />
                    </CenterElement>
                  );
                }
              },
            },
          ]}
          onRowClick={handleRowClick}
          pageSizeOptions={[5, 10, 25, 100]}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          sx={{
            [`& .${gridClasses.columnSeparator}`]: {
              [`&:not(.${gridClasses["columnSeparator--resizable"]})`]: {
                display: "none",
              },
            },
          }}
        />
      </Paper>
    </Card>
  );
};

export default MdtMeetings;
