// Data
let id = 0;
function createData(
  name: string,
  patients: number,
  dept: string,
  reviewed: number
) {
  id += 1;
  return { id, name, patients, dept, reviewed };
}
const addWeeksToDate = (dateObj: Date, numberOfWeeks: number) => {
  dateObj.setDate(dateObj.getDate() + numberOfWeeks * 7);
  return dateObj;
};

const mdtMeetingsData = [
  createData(new Date().toLocaleDateString(), 12, "Orthopaedics", 90),
  createData(
    addWeeksToDate(new Date(), 1).toLocaleDateString(),
    10,
    "Oncology",
    80
  ),
  createData(
    addWeeksToDate(new Date(), 2).toLocaleDateString(),
    11,
    "Orthopaedics",
    70
  ),
  createData(
    addWeeksToDate(new Date(), 3).toLocaleDateString(),
    11,
    "Oncology",
    50
  ),
  createData(
    addWeeksToDate(new Date(), 4).toLocaleDateString(),
    8,
    "Orthopaedics",
    10
  ),
];

export default mdtMeetingsData;
