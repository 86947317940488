import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import VitruvianBenign from "../../../vitruvian/VitruvianBenign";
import Vitruvian01 from "../../../vitruvian/Vitruvian01";
// import Vitruvian from "../../../vitruvian/Vitruvian";
import VitruvianFemaleBlank from "../../../vitruvian/VitruvianFemaleBlank";
import VitruvianMaleBlank from "../../../vitruvian/VitruvianMaleBlank";
import History from "./History";

const VitruvianPicker = (thisPatient: any) => {
  switch (thisPatient.id) {
    case 1:
      return <Vitruvian01 />;
    case 2:
      return <VitruvianBenign />;
    case 3:
      return <VitruvianFemaleBlank />;
    case 4:
      return <VitruvianMaleBlank />;
    case 5:
      return <VitruvianFemaleBlank />;
    case 6:
      return <VitruvianMaleBlank />;
    case 7:
      return <VitruvianFemaleBlank />;
    case 8:
      return <VitruvianMaleBlank />;
    case 9:
      return <VitruvianFemaleBlank />;
    case 10:
      return <VitruvianMaleBlank />;
    case 11:
      return <VitruvianMaleBlank />;
    default:
      return <VitruvianFemaleBlank />;
  }
};

function VitruvianCondition(patient: any) {
  const thisPatient = patient.patient;
  let allergyContent = null;
  if (thisPatient.allergies && thisPatient.allergies.length > 0) {
    allergyContent = (
      <div>
        <Stack direction="row" spacing={2} mb={0} useFlexGap flexWrap="wrap">
          {thisPatient.allergies.map((allergy: any) => (
            <Chip color="info" size="small" label={allergy} key={allergy} />
          ))}
        </Stack>
      </div>
    );
  } else {
    allergyContent = <Chip color="info" size="small" label="None" />;
  }

  let medicationContent = null;
  if (thisPatient.medications && thisPatient.medications.length > 0) {
    medicationContent = (
      <div>
        <Stack direction="row" spacing={2} mb={0} useFlexGap flexWrap="wrap">
          {thisPatient.medications.map((medication: any) => (
            <Chip
              variant="outlined"
              size="small"
              label={medication}
              key={medication}
            />
          ))}
        </Stack>
      </div>
    );
  } else {
    medicationContent = <Chip color="info" size="small" label="None" />;
  }

  let conditionContent = null;
  if (thisPatient.conditions && thisPatient.conditions.length > 0) {
    conditionContent = (
      <div>
        <Stack direction="row" spacing={2} mb={0} useFlexGap flexWrap="wrap">
          {thisPatient.conditions.map((condition: any) => (
            <Chip
              color="primary"
              size="small"
              label={condition}
              key={condition}
            />
          ))}
        </Stack>
      </div>
    );
  } else {
    conditionContent = <Chip color="info" size="small" label="None" />;
  }
  return (
    <Box mb={6}>
      <Grid classes="l-columns" container spacing={6}>
        <Grid classes="l-vitruvian" item xs={4} sm={4}>
          {VitruvianPicker(thisPatient)}
        </Grid>
        <Grid item xs={8} sm={8}>
          <Typography variant="h4" gutterBottom>
            Conditions
          </Typography>
          {conditionContent}
          <Typography sx={{ mt: 5 }} variant="h4" gutterBottom>
            Medications
          </Typography>
          {medicationContent}
          <Typography sx={{ mt: 5 }} variant="h4" gutterBottom>
            Allergies
          </Typography>
          {allergyContent}
          <History patient={thisPatient} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default VitruvianCondition;
