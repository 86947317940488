import React from "react";
import styled from "@emotion/styled";

import { Grid, Avatar, Typography } from "@mui/material";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const TLCFooter: React.FC = ({ ...rest }) => {
  return (
    <Footer {...rest}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Avatar alt="Guest user" src="/static/img/avatars/guest-user.jpg" />
        </Grid>
        <Grid item>
          <FooterText sx={{ textTransform: "capitalize" }} variant="body2">
            Mrs Eleanor Arroway [TLC]
          </FooterText>
        </Grid>
      </Grid>
    </Footer>
  );
};

export default TLCFooter;
