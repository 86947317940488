let id = 0;
function createData(
  mdt: Date,
  name: string,
  complaint: string,
  tlc_upload: boolean,
  user_reviewed: boolean,
  team_review: number
) {
  id += 1;
  return { id, mdt, name, complaint, tlc_upload, user_reviewed, team_review };
}
const addWeeksToDate = (dateObj: Date, numberOfWeeks: number) => {
  dateObj.setDate(dateObj.getDate() + numberOfWeeks * 7);
  return dateObj;
};

const myPatientData = [
  createData(
    addWeeksToDate(new Date(), 0),
    "Jane Doe",
    "COPD, NSCLC, T2DM",
    true,
    true,
    100
  ),
  createData(
    addWeeksToDate(new Date(), 0),
    "Sekani Abara",
    "Osteoarthritis Right Hip",
    true,
    true,
    100
  ),
  createData(
    addWeeksToDate(new Date(), 0),
    "Susan Calvin",
    "COPD, Respiratory Failure",
    true,
    true,
    100
  ),
  createData(
    addWeeksToDate(new Date(), 1),
    "William Turner",
    "Atrial Fibrillation",
    true,
    true,
    100
  ),
  createData(
    addWeeksToDate(new Date(), 1),
    "Ethan Marcotte",
    "Traumatic Brain Injury",
    true,
    true,
    100
  ),
  createData(
    addWeeksToDate(new Date(), 1),
    "David Lee",
    "Chrohn's Disease",
    true,
    true,
    100
  ),
  createData(
    addWeeksToDate(new Date(), 2),
    "Sarah Johnson",
    "Asthma",
    true,
    true,
    100
  ),
  createData(
    addWeeksToDate(new Date(), 2),
    "Maria Rodriguez",
    "Pneumonia",
    true,
    true,
    100
  ),
  createData(
    addWeeksToDate(new Date(), 2),
    "James Brown",
    "Left Hip Fracture",
    true,
    true,
    100
  ),

  createData(
    addWeeksToDate(new Date(), 3),
    "Emily Wilson",
    "Cholecystitis",
    true,
    true,
    100
  ),
  createData(
    addWeeksToDate(new Date(), 3),
    "Michael Chang",
    "Intercranial Mass",
    true,
    true,
    100
  ),

  createData(
    addWeeksToDate(new Date(), 4),
    "Lisa Patel",
    "NSAID-induced Ulcer",
    true,
    true,
    100
  ),
];

export default myPatientData;
