// React imports
import React from "react";
// MUI imports
import { Box, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";

interface TagProps {
  id: number;
  name: string;
  color?: string;
  bgcolor?: string;
  fullWidth?: boolean;
  icon?: React.ReactNode;
}

const TagContainer = styled(Box)<{ color?: string; bgcolor?: string }>`
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.color};
  border-radius: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Tag = ({ name, color, bgcolor, fullWidth, icon }: TagProps) => {
  const tagWidth = fullWidth ? "100%" : "auto";
  const tagRightPadding = icon ? "2px" : "8px";
  return (
    <TagContainer
      color={color}
      bgcolor={bgcolor}
      sx={{ width: tagWidth, pr: tagRightPadding, pl: "8px", py: "2px" }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography
          variant="body2"
          sx={{
            fontWeight: 700,
            textAlign: "center",
            textOverflow: "ellipsis",
            overflow: "hidden",
            flexGrow: 1,
          }}
        >
          {name}
        </Typography>
        <Box sx={{ color: "#00000040", display: "flex", alignItems: "center" }}>
          {icon && icon}
        </Box>
      </Stack>
    </TagContainer>
  );
};

export default Tag;
