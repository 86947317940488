// react imports
import React from "react";

// MUI imports
import { Box, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";

interface CardTitleProps {
  title: string;
  children?: React.ReactNode;
}

const StyledBox = styled(Box)(({ theme }) => ({
  padding: "15px 20px",
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
}));

const CardTitle: React.FC<CardTitleProps> = ({ title, children }) => {
  return (
    <StyledBox>
      <Stack direction="row" spacing={2}>
        {children}
        <Typography variant="h4">{title}</Typography>
      </Stack>
    </StyledBox>
  );
};

export default CardTitle;
