import React from "react";

import { Box, Stack, Typography } from "@mui/material";
import { AiIcon } from "../../../../icons/TLSvgIcons";

const Content = () => {
  return <div></div>;
};

function DraftCareEmpty() {
  return (
    <Box>
      <Stack direction="row" spacing={1}>
        <Typography variant="h4">Draft Care Plan</Typography>
        <Box>
          <AiIcon />
        </Box>
      </Stack>
      <Box
        sx={{
          background: "#FFFBF7",
          border: " 1px solid rgb(255, 206, 162)",
          borderRadius: "5px",
          color: "black",
          px: 4,
          py: 2,
        }}
      >
        <Content />
      </Box>
    </Box>
  );
}

export default DraftCareEmpty;
