//react imports
import React from "react";

//MUI imports
import { Box, Card, Stack } from "@mui/material";
import { RichTreeViewPro } from "@mui/x-tree-view-pro";
import { TreeViewBaseItem } from "@mui/x-tree-view-pro";
import {
  DataGridPro,
  GridEventListener,
  GridToolbar,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";
import FolderIcon from "@mui/icons-material/Folder";

//Project imports
import CardTitle from "../card/CardTitle";
import Tag from "../chronology/Tag";

const ITEMS: TreeViewBaseItem[] = [
  {
    id: "pre-op",
    label: "Pre-Operative",
    children: [
      { id: "subfolder-1", label: "Subfolder 1" },
      { id: "subfolder-2", label: "Subfolder 2" },
      { id: "subfolder-3", label: "Subfolder 3" },
    ],
  },
  {
    id: "intra-op",
    label: "Intra-Operative",
    children: [
      { id: "subfolder-4", label: "Subfolder 4" },
      { id: "subfolder-5", label: "Subfolder 5" },
    ],
  },
  {
    id: "post-op",
    label: "Post-Operative",
    children: [{ id: "subfolder-6", label: "Subfolder 6" }],
  },
  {
    id: "Legal Documentation",
    label: "Tree View",
    children: [{ id: "subfolder-7", label: "Subfolder 7" }],
  },
];

const CaseDocuments = () => {
  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    console.log(params.id);
  };

  return (
    <Card>
      <CardTitle title="Case Documents">
        <FolderIcon color="action" />
      </CardTitle>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            p: 5,
            borderRight: 1,
            borderColor: "divider",
            width: "190px;",
            flexShrink: 0,
          }}
        >
          <RichTreeViewPro
            items={ITEMS}
            itemsReordering
            selectedItems={"subfolder-1"}
            defaultExpandedItems={["pre-op"]}
            experimentalFeatures={{
              indentationAtItemLevel: true,
              itemsReordering: true,
            }}
          />
        </Box>
        <Box sx={{ flexGrow: 1, minHeight: 500 }}>
          <DataGridPro
            disableColumnMenu
            disableColumnSelector
            checkboxSelection
            slots={{ toolbar: GridToolbar }} // Set the toolbar slot to GridToolba
            slotProps={{
              toolbar: {
                showQuickFilter: true, // Show the quick filter in the toolbar
              },
            }}
            sx={{
              pt: 1,
              border: "none",
            }}
            initialState={{
              pinnedColumns: {
                left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
              }, // Set the pinned columns
              sorting: { sortModel: [{ field: "doc_id", sort: "asc" }] }, // Set the sorting column
            }}
            rows={[
              {
                id: 1,
                doc_id: "DOC-001",
                doc_name: "MDT CarePlan",
                overview: "Overview of DMDT risk mitigation strategy",
                tags: [
                  {
                    id: 1,
                    name: "Care Plan",
                    color: "#005a30",
                    bgcolor: "#CCE2D9",
                  },
                ],
                version: "v2.1",
                updated: "2024-09-14",
              },
              {
                id: 2,
                doc_id: "DOC-002",
                doc_name: "Pre-op Assessment ",
                overview: "Anesthetist’s evaluation pre-surgery",
                tags: [
                  {
                    id: 1,
                    name: "Assessment",
                    bgcolor: "#efdfed",
                    color: "#491644",
                  },
                ],
                version: "v1.0",
                updated: "2024-09-28",
              },
              {
                id: 3,
                doc_id: "DOC-003",
                doc_name: "Physio Consultation",
                overview: "Recommendations for cardiac prehabilitation ",
                tags: [
                  {
                    id: 1,
                    name: "Physio",
                    bgcolor: "#fff7bf",
                    color: "#594d00",
                  },
                ],
                version: "v1.0",
                updated: "2024-09-17",
              },
              {
                id: 4,
                doc_id: "DOC-004",
                doc_name: "ICU Post-op Note",
                overview: "Initial ICU admission notes",
                tags: [
                  {
                    id: 1,
                    name: "ICU",
                    bgcolor: "#f4cccc",
                    color: "#611f1a",
                  },
                ],
                version: "v1.1",
                updated: "2024-09-30",
              },
              {
                id: 5,
                doc_id: "DOC-005",
                doc_name: "Cardiology Consultation",
                overview: "Details of cardiology post-op assessment",
                tags: [
                  {
                    id: 1,
                    name: "Cardiology",
                    bgcolor: "#e8f1f8",
                    color: "#0c2d4a",
                  },
                ],
                version: "v1.0",
                updated: "2024-10-01",
              },
            ]}
            columns={[
              { field: "doc_id", headerName: "Id", width: 100 },
              { field: "doc_name", headerName: "Name", flex: 1 },
              { field: "overview", headerName: "Overview", flex: 2 },
              {
                field: "tags",
                headerName: "Tags",
                width: 120,
                renderCell: (params) => {
                  const { row } = params;
                  return (
                    <Stack
                      spacing={1}
                      sx={{
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      {row.tags &&
                        row.tags.map((tag: any, index: number) => (
                          <Tag
                            key={index}
                            id={tag.id}
                            name={tag.name}
                            color={tag.color}
                            bgcolor={tag.bgcolor}
                            fullWidth={true}
                          />
                        ))}
                    </Stack>
                  );
                },
                valueGetter: (row: any[] | undefined) => {
                  if (!row) {
                    return "";
                  }
                  return row.map((tag) => tag.name).join(", ");
                },
              },
              { field: "version", headerName: "Version", width: 70 },
              {
                field: "updated",
                headerName: "Updated",
                width: 100,
                type: "date",
                valueFormatter: (params) => {
                  return params ? new Date(params).toLocaleDateString() : "N/A";
                },
              },
            ]}
            onRowClick={handleRowClick}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default CaseDocuments;
