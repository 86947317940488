import { createSlice } from "@reduxjs/toolkit";
// import eventDataSekari from "../../components/chronology/eventDataSekari";
import eventData from "../../assets/fakeData/eventData";

export interface MockTimelineState {
  mockTimeline: any[];
}

const initialState: MockTimelineState = {
  mockTimeline: eventData,
};

const mockTimelineSlice = createSlice({
  name: "mockTimeline",
  initialState,
  reducers: {
    changeCommentTimeline: (state, action) => {
      state.mockTimeline = action.payload;
    },
  },
});

export const { changeCommentTimeline } = mockTimelineSlice.actions;
export default mockTimelineSlice.reducer;
