import styled from "@emotion/styled";

import VitruvianFemaleBase from "./assets/vitruvian-base.png";

import "./assets/animation.css";

interface VitruvianProps {
  width?: string;
}

const VitruvianFemaleBlank: React.FC<VitruvianProps> = ({ width }) => {
  const BaseLayer = styled("div")`
    position: relative;
    width: ${width};
  `;
  return (
    <BaseLayer>
      <img src={VitruvianFemaleBase} alt="Vitruvian" width="100%" />
    </BaseLayer>
  );
};

export default VitruvianFemaleBlank;
