import React from "react";
import { Helmet } from "react-helmet-async";
import PageNavbar from "../../components/navbar/PageNavbar";
import MdtMeetingList from "../../components/ramsay/mdt/MdtMeetingList";
import MdtTeam from "../../components/patient/mdt/MdtTeam";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  LinearProgress,
  Card,
} from "@mui/material";
import { Groups } from "@mui/icons-material";

function Tools() {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      flexGrow={1}
      sx={{ pr: 2 }}
    >
      <Box sx={{ pr: 4 }}>
        <Stack
          direction="row"
          spacing={3}
          alignItems="baseline"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Typography variant="h3" color="InfoText">
            {new Date().toDateString()}
          </Typography>
          <Typography variant="h5" color="InfoText">
            Orthopaedics
          </Typography>
          <Typography variant="h5" color="InfoText">
            2:30pm
          </Typography>
          <Typography variant="h5" color="InfoText">
            Location: Room 1
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <MdtTeam />
          <Link to="/live/1">
            <Button
              color="primary"
              variant="contained"
              startIcon={<Groups />}
              size="large"
              disabled
            >
              Join eMDT
            </Button>
          </Link>
        </Stack>
      </Box>
    </Stack>
  );
}

function MeetingListPage() {
  return (
    <React.Fragment>
      <Helmet title="Meeting List" />
      <PageNavbar title="">
        <Tools />
      </PageNavbar>
      <Box sx={{ p: 6 }}>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <MdtTeam />
            <Link to="/live/1">
              <Button
                color="primary"
                variant="contained"
                startIcon={<Groups />}
                size="large"
                disabled
              >
                Join eMDT
              </Button>
            </Link>
          </Stack>
        </Box>
        <Card sx={{ mb: 3 }}>
          <Box sx={{ width: "100%", minWidth: "500px", px: 3, pb: 4, pt: 1 }}>
            <Typography variant="overline">Patients reviewed</Typography>
            <LinearProgress
              color="success"
              variant="determinate"
              value={90}
              sx={{ height: 6, borderRadius: 3, width: "100%" }}
            />
          </Box>

          <MdtMeetingList />
        </Card>
      </Box>
    </React.Fragment>
  );
}

export default MeetingListPage;
