import React, { forwardRef } from "react";
import styled from "@emotion/styled";
import { NavLink, NavLinkProps } from "react-router-dom";
import { rgba, darken } from "polished";

import {
  Chip,
  Collapse,
  ListItemProps,
  ListItemAvatar,
  Avatar,
  ListItemButton,
  ListItemText,
  Box,
} from "@mui/material";

import { ExpandLess, ExpandMore } from "@mui/icons-material";

const CustomRouterLink = forwardRef<any, NavLinkProps>((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
));

type ItemType = {
  activeclassname?: string;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  depth: number;
};

const ListIcon = styled(Avatar)`
  background: ${(props) => darken(0.03, props.theme.sidebar.background)};
  color: ${(props) => props.theme.sidebar.color};
  opacity: 0.5;
  border: 1px solid ${(props) => props.theme.sidebar.color};
  min-width: 0;
  .active & {
    opacity: 1;
    border: 1px solid ${(props) => props.theme.palette.secondary.main};
  }
`;

const ListItem = styled(ListItemButton)<ItemType>`
  padding-top: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-left: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 3 : 4)};
  padding-right: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 0 : 7)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  border-radius: ${(props) => props.depth && props.depth > 0 && "8px 0 0 8px"};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 1;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.1);
    color: ${(props) => props.theme.sidebar.color};
  }
  &.${(props) => props.activeclassname} {
    background-color: ${(props) => props.theme.sidebar.header.background};
    span {
      color: ${(props) => props.theme.sidebar.header.color};
    }
  }
`;
const ListItemTextBox = styled(Box)`
  padding-left: 0;
  padding-right: ${(props) => props.theme.spacing(0)};
  padding-top: ${(props) => props.theme.spacing(1)};
  padding-bottom: ${(props) => props.theme.spacing(1)};
`;

const Item = styled(ListItemButton)<ItemType>`
  padding-top: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 4)};
  padding-bottom: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 4)};
  padding-left: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? "23px" : 6)};
  padding-right: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
  border-radius: ${(props) => props.depth && props.depth > 0 && "8px 0 0 8px"};

  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  span.material-icons {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.6;
  }

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.6;
    border-bottom: 1px solid transparent;
  }
  &:first-of-type {
    border-radius: ${(props) =>
      props.depth && props.depth > 0 && "8px 0 0 8px"};
  }
  &:hover {
    background: rgba(0, 0, 0, 0.1);
    color: ${(props) => props.theme.sidebar.color};
  }
  &.${(props) => props.activeclassname} {
    background-color: ${(props) => props.theme.sidebar.footer.background};
    span {
      color: ${(props) => props.theme.sidebar.color};
    }
    svg {
      color: ${(props) => props.theme.sidebar.color};
      opacity: 1;
      border-bottom: 1px solid ${(props) => props.theme.palette.secondary.main};
      position: relative;
    }
  }
`;
const AddButton = styled(Item)<ItemType>`
  justify-content: center;
  padding-x: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  &:first-of-type {
    border-radius: 0 0 0 8px;
  }
`;
const TopButton = styled(Item)<ItemType>`
  justify-content: center;
  padding-x: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  &:first-of-type {
    border-radius: 8px 0 0 0;
  }
  svg {
    height: 24px;
    width: 24px;
  }
`;

type TitleType = {
  depth: number;
};

const Title = styled(ListItemText)<TitleType>`
  margin: 0;
  span {
    color: ${(props) =>
      rgba(
        props.theme.sidebar.color,
        props.depth && props.depth > 0 ? 0.7 : 1
      )};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;
const ListTitle = styled(ListItemText)<TitleType>`
  margin: 0;
  span {
    color: ${(props) =>
      rgba(
        props.theme.sidebar.color,
        props.depth && props.depth > 0 ? 0.7 : 1
      )};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)} 0 0;
  }
`;
const ListTitle2 = styled(ListItemText)<TitleType>`
  margin: 0;
  opacity: 1;
  span {
    color: ${(props) =>
      rgba(
        props.theme.sidebar.color,
        props.depth && props.depth > 0 ? 0.7 : 1
      )};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)} 0 0;
  }
`;

const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

type SidebarNavListItemProps = ListItemProps & {
  className?: string;
  depth: number;
  href: string;
  icon: React.FC<any>;
  badge?: string;
  open?: boolean;
  title: string;
  category?: string;
  amount?: number;
};

const SidebarNavListItem: React.FC<SidebarNavListItemProps> = (props) => {
  const {
    title,
    href,
    depth = 0,
    children,
    icon: Icon,
    badge,
    amount,
    category,
    open: openProp = false,
  } = props;

  const [open, setOpen] = React.useState(openProp);

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  if (children) {
    return (
      <React.Fragment>
        <Item
          sx={{
            borderTop: "1px solid rgba(255,255,255,0.1)",
          }}
          depth={depth}
          onClick={handleToggle}
        >
          {Icon && <Icon />}
          <Title depth={depth}>
            {title}
            {badge && <Badge label={badge} />}
          </Title>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Item>
        <Collapse in={open}>{children}</Collapse>
      </React.Fragment>
    );
  }
  if (category === "Home") {
    return (
      <React.Fragment>
        <ListItem
          depth={depth}
          component={CustomRouterLink}
          to={href}
          activeclassname="active"
        >
          <ListItemAvatar>
            <ListIcon>{Icon && <Icon />}</ListIcon>
          </ListItemAvatar>
          <ListItemTextBox>
            <ListTitle depth={depth}>
              {title}
              {badge && <Badge label={badge} />}
            </ListTitle>
          </ListItemTextBox>
        </ListItem>
      </React.Fragment>
    );
  }
  if (category === "Button") {
    return (
      <React.Fragment>
        <AddButton
          depth={depth}
          component={CustomRouterLink}
          to={href}
          activeclassname="active"
        >
          {Icon && <Icon />}
        </AddButton>
      </React.Fragment>
    );
  }
  if (category === "TopButton") {
    return (
      <React.Fragment>
        <TopButton
          depth={depth}
          component={CustomRouterLink}
          to={href}
          activeclassname="active"
        >
          {Icon && <Icon />}
        </TopButton>
      </React.Fragment>
    );
  }

  if (amount) {
    return (
      <React.Fragment>
        <ListItem
          depth={depth}
          component={CustomRouterLink}
          to={href}
          activeclassname="active"
        >
          <ListItemAvatar>
            <ListIcon>{Icon && <Icon />}</ListIcon>
          </ListItemAvatar>
          <ListItemTextBox>
            <ListTitle depth={depth}>
              {title}
              {badge && <Badge label={badge} />}
            </ListTitle>
            <ListTitle2 depth={depth}>
              {amount} {category && "-"} {category}
            </ListTitle2>
          </ListItemTextBox>
        </ListItem>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Item
        depth={depth}
        component={CustomRouterLink}
        to={href}
        activeclassname="active"
      >
        {Icon && <Icon />}
        <Title depth={depth}>
          {title}
          {badge && <Badge label={badge} />}
        </Title>
      </Item>
    </React.Fragment>
  );
};

export default SidebarNavListItem;
