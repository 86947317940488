import React from "react";
import styled from "@emotion/styled";

import { Drawer as MuiDrawer } from "@mui/material";

import { SidebarItemsType } from "../../../types/sidebar";
import SidebarNav from "../SidebarNav";

import TLCFooter from "./TLCFooter";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

export type SidebarProps = {
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
  onClose?: () => void;
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  showFooter?: boolean;
};

const TLCSidebar: React.FC<SidebarProps> = ({
  items,
  showFooter = true,
  ...rest
}) => {
  return (
    <Drawer variant="permanent" {...rest}>
      <SidebarNav items={items} />
      {!!showFooter && <TLCFooter />}
    </Drawer>
  );
};

export default TLCSidebar;
