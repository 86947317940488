import { createSlice } from "@reduxjs/toolkit";

export interface ReviewedDemoPatientState {
  value: boolean;
}

const initialState: ReviewedDemoPatientState = {
  value: false,
};

const reviewedDemoPatientSlice = createSlice({
  name: "reviewedDemoPatient",
  initialState,
  reducers: {
    changeReviewedDemoPatient: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeReviewedDemoPatient } = reviewedDemoPatientSlice.actions;

export default reviewedDemoPatientSlice.reducer;
