import React from "react";
import { useState } from "react";

import NursePic from "../../../assets/images/avatars/nurse.jpg";
import MarkPic from "../../../assets/images/avatars/MarkAppleby.jpg";
import DCashPic from "../../../assets/images/avatars/mr-david-cash.jpg";
import IvanovaPic from "../../../assets/images/avatars/dobrinka-ivanova.jpeg";
import NickPic from "../../../assets/images/avatars/NickTaylor.jpg";
import GuestPic from "../../../assets/images/avatars/guest-user.jpg";
import ScottPic from "../../../assets/images/avatars/Scott.png";

import {
  Avatar,
  AvatarGroup,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider as MuiDivider,
  Popover,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";

import styled from "@emotion/styled";

const Divider = styled(MuiDivider)(spacing);

function MdtTeam() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const teamPicSize = 32;
  return (
    <div>
      <Button aria-describedby={id} onClick={handleClick}>
        <AvatarGroup max={8}>
          <Avatar
            alt="Dr Scott Parazynski"
            src={ScottPic}
            sx={{ width: teamPicSize, height: teamPicSize }}
          />
          <Avatar
            alt="Mr David Cash"
            src={DCashPic}
            sx={{ width: teamPicSize, height: teamPicSize }}
          />
          <Avatar
            alt="Dr NickTaylor"
            src={NickPic}
            sx={{ width: teamPicSize, height: teamPicSize }}
          />
          <Avatar
            alt="Dr Mark Appleby"
            src={MarkPic}
            sx={{ width: teamPicSize, height: teamPicSize }}
          />
          <Avatar
            alt="Dr John Ferrier"
            src={GuestPic}
            sx={{ width: teamPicSize, height: teamPicSize }}
          />
          <Avatar
            alt="Dobrinka Ivanova"
            src={IvanovaPic}
            sx={{ width: teamPicSize, height: teamPicSize }}
          />
          <Avatar
            alt="Ms Janice Smith"
            src={NursePic}
            sx={{ width: teamPicSize, height: teamPicSize }}
          />
        </AvatarGroup>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            bgcolor: "background.paper",
            borderRadius: 1,
            width: 300,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Dr Scott Parazynski" src={ScottPic} />
              </ListItemAvatar>
              <ListItemText
                primary="Dr Scott Parazynski"
                secondary="MDT Chair"
              />
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Mr David Cash" src={DCashPic} />
              </ListItemAvatar>
              <ListItemText primary="Mr David Cash" secondary="Orthopaedics" />
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Dr NickTaylor" src={NickPic} />
              </ListItemAvatar>
              <ListItemText primary="Dr Nick Taylor" secondary="G.P." />
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Dr Mark Appleby" src={MarkPic} />
              </ListItemAvatar>
              <ListItemText primary="Dr Mark Appleby" secondary="Cardiology" />
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Dr John Ferrier" src={GuestPic} />
              </ListItemAvatar>
              <ListItemText primary="Dr John Ferrier" secondary="Radiology" />
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Dobrinka Ivanova" src={IvanovaPic} />
              </ListItemAvatar>
              <ListItemText
                primary="Dobrinka Ivanova"
                secondary="Physiotherapy"
              />
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Ms Janice Smith" src={NursePic} />
              </ListItemAvatar>
              <ListItemText primary="Ms Janice Smith" secondary="Nursing" />
            </ListItem>
          </List>
        </Box>
      </Popover>
    </div>
  );
}

export default MdtTeam;
