import React from "react";
import styled from "@emotion/styled";

import NavbarNotificationsDropdown from "../NavbarNotificationsDropdown";

import { Box, AppBar as MuiAppBar, Stack, Typography } from "@mui/material";

import NavbarUserDropdown from "../NavbarUserDropdown";
import { useTheme } from "@emotion/react"; // Import the useTheme hook
import { useMediaQuery } from "@mui/material"; // Import the useMediaQuery hook

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: rgba(50, 50, 93, 0.025) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.2) 0px 1px 3px -1px;
  margin-bottom: 12px;
`;

const Spacer = () => {
  return <Box sx={{ flexGrow: 1 }} />;
};

type PageToolbarContainerProps = {
  children: React.ReactNode;
};

const PageToolbarContainer: React.FC<PageToolbarContainerProps> = ({
  children,
}) => {
  return (
    <Stack direction="row" sx={{ minHeight: 64, px: 3, ml: { xs: 13, xl: 0 } }}>
      {children}
    </Stack>
  );
};

const PageToolbar: React.FC<PageToolbarContainerProps> = ({ children }) => {
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      flexWrap="wrap"
      sx={{ width: "100%", py: 2 }}
    >
      {children}
    </Stack>
  );
};

const FixedToolsContainer: React.FC<PageToolbarContainerProps> = ({
  children,
}) => {
  return (
    <Stack direction="row" sx={{ ml: 2 }}>
      {children}
    </Stack>
  );
};

type ResponsiveToolbarContainerProps = {
  children: React.ReactNode;
  breakpoint?: string;
};

const ResponsiveToolsContainer: React.FC<ResponsiveToolbarContainerProps> = ({
  children,
  breakpoint,
}) => {
  const display = breakpoint
    ? { xs: "none", [breakpoint]: "flex" }
    : { xs: "flex" };
  return (
    <Stack direction="row" flexGrow="1" alignItems="center">
      <Stack
        direction="row"
        flexGrow="1"
        alignItems="center"
        sx={{ minHeight: 48, ml: 2, display: display }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

type TitleProps = {
  title?: string;
};

const Title: React.FC<TitleProps> = ({ title }) => {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box sx={{ minHeight: 48, display: "flex", alignItems: "center", pl: 2 }}>
      <Typography
        sx={{ lineHeight: 1 }}
        variant={isSmUp ? "h3" : "h4"}
        color="WindowText"
      >
        {title}
      </Typography>
    </Box>
  );
};

type NavbarProps = {
  title?: string;
  children?: React.ReactNode;
  fixedTools?: React.ReactNode;
  responsiveTools?: React.ReactNode;
  breakpoint?: string;
};

const TLAppbar: React.FC<NavbarProps> = ({
  title,
  children,
  breakpoint,
  fixedTools,
  responsiveTools,
}) => {
  const display = breakpoint
    ? { xs: "flex", [breakpoint]: "none" }
    : { xs: "none" };
  return (
    <React.Fragment>
      <AppBar position="sticky">
        <PageToolbarContainer>
          <PageToolbar>
            <Title title={title} />
            {children}
            <ResponsiveToolsContainer breakpoint={breakpoint}>
              {responsiveTools}
            </ResponsiveToolsContainer>

            <FixedToolsContainer>{fixedTools}</FixedToolsContainer>
          </PageToolbar>
          <Stack direction="row" alignItems="flex-start" sx={{ pt: 2, ml: 2 }}>
            <NavbarNotificationsDropdown />
            <NavbarUserDropdown />
          </Stack>
        </PageToolbarContainer>
      </AppBar>
      <Box sx={{ px: 6, display: display }}>{responsiveTools}</Box>
    </React.Fragment>
  );
};

export { Spacer };

export default TLAppbar;
