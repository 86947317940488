import { Card as MuiCard, CardHeader } from "@mui/material";
import { spacing } from "@mui/system";
import { useSelector } from "react-redux";

import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import styled from "@emotion/styled";
import TLIcon from "../../icons/TLIcon";
import {
  DataGrid,
  gridClasses,
  GridEventListener,
  GridToolbar,
} from "@mui/x-data-grid";

import { useNavigate } from "react-router-dom";
import myPatientData from "../patientData/MyPatientData";

const CenterElement = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Card = styled(MuiCard)(spacing);

const MyPatients = () => {
  const reviewed = useSelector((state: any) => state.reviewedDemoPatient.value);
  const navigate = useNavigate();
  const handleCellClick: GridEventListener<"cellClick"> = (params) => {
    if (params.field !== "actions") {
      navigate(`/patient/${params.id}`);
    }
  };

  return (
    <Card mb={3}>
      <CardHeader
        title="My Patients"
        avatar={<TLIcon name="personal_injury" />}
      />
      <DataGrid
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
        rows={myPatientData}
        columns={[
          {
            field: "mdt",
            headerName: "MDT",
            width: 100,
            valueFormatter: (params) => {
              return params ? new Date(params).toLocaleDateString() : "N/A";
            },
          },
          { field: "name", headerName: "Name", width: 150 },
          {
            field: "complaint",
            headerName: "Complaint",
            flex: 1,
          },
          {
            field: "tlc_upload",
            headerName: "TLC Upload",
            disableColumnMenu: true,
            width: 100,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
              if (params.value) {
                return (
                  <CenterElement>
                    <CheckCircle color="success" />
                  </CenterElement>
                );
              } else {
                return (
                  <CenterElement>
                    <RadioButtonUnchecked color="disabled" />
                  </CenterElement>
                );
              }
            },
          },
          {
            field: "user_reviewed",
            headerName: "Reviewed",
            disableColumnMenu: true,
            width: 100,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
              if (params.id === 2) {
                return (
                  <CenterElement>
                    {reviewed ? (
                      <CheckCircle color="success" />
                    ) : (
                      <RadioButtonUnchecked color="disabled" />
                    )}
                  </CenterElement>
                );
              } else if (params.value) {
                return (
                  <CenterElement>
                    <CheckCircle color="success" />
                  </CenterElement>
                );
              } else {
                return (
                  <CenterElement>
                    <RadioButtonUnchecked color="disabled" />
                  </CenterElement>
                );
              }
            },
          },
        ]}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 25, 100]}
        className={gridClasses.root}
        onCellClick={handleCellClick}
        sx={{
          [`& .${gridClasses.columnSeparator}`]: {
            [`&:not(.${gridClasses["columnSeparator--resizable"]})`]: {
              display: "none",
            },
          },
        }}
      />
    </Card>
  );
};

export default MyPatients;
