import React from "react";

// Importing necessary components from MUI
import { Box, Card, Grid, Link, Typography, Stack } from "@mui/material";
import styled from "@emotion/styled"; // Importing styled from @emotion/styled

// Importing custom component
import ChronologyEventMarker from "./ChronologyEventMarker";
import Tag from "./Tag";

// Interface defining the props for ChronologyEvent component
interface ChronologyEventProps {
  event: any;
  lineType: string;
}

// Styled component for ChronologyCard
const ChronologyCard = styled(Card)(({ theme }) => ({
  padding: "15px",
  flexGrow: 1,
  marginBottom: "15px",
}));

// Styled component for DateHolder
const DateHolder = styled(Box)(({ theme }) => ({
  width: "100px",
  paddingTop: "16px",
  flexShrink: 0,
}));

// ChronologyEvent component definition
const ChronologyEvent = ({ event, lineType }: ChronologyEventProps) => {
  return (
    <Box sx={{ maxWidth: "860px", margin: "auto" }}>
      {/* Stack container for date holder and event marker */}
      <Stack direction="row">
        {/* Date holder displaying event date, time, and type */}
        <DateHolder>
          <Typography variant="h5" align="right">
            {event.event_date &&
              new Date(event.event_date).toLocaleDateString()}
            {!event.event_date && "No date"}
          </Typography>
          <Typography variant="body1" align="right">
            {event.event_date &&
              new Date(event.event_date).toLocaleTimeString()}
            {!event.event_date && "No time"}
          </Typography>
          <Typography
            variant="body1"
            color="GrayText"
            align="right"
            sx={{ textTransform: "uppercase" }}
          >
            {event.event_type && event.event_type}
          </Typography>
        </DateHolder>

        {/* Event marker */}
        <ChronologyEventMarker lineType={lineType} />

        {/* Chronology card displaying event details */}
        <ChronologyCard>
          <Grid container spacing={4} columns={12}>
            {/* Grid item for event title, author, and author role */}
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
              <Typography variant="h5" sx={{ textWrap: "balance" }}>
                {event.event_title}
              </Typography>
              <Typography variant="body1">
                <strong>{event.author && event.author}</strong>
              </Typography>
              <Typography
                variant="body1"
                color="GrayText"
                sx={{ textTransform: "uppercase" }}
              >
                {event.author_role && event.author_role}
              </Typography>
            </Grid>
            {/* Grid item for event abstract and link to PDF */}
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8} xxl={7}>
              <Typography variant="body1" sx={{ pt: "3px", pb: 3 }}>
                {event.event_abstract && event.event_abstract}
                {!event.event_abstract && "--"}
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                sx={{ flexWrap: "wrap", pb: 3 }}
              >
                {event.tags &&
                  event.tags.map((tag: any, index: number) => (
                    <Tag
                      key={index}
                      id={tag.id}
                      name={tag.name}
                      color={tag.color}
                      bgcolor={tag.bgcolor}
                    />
                  ))}
              </Stack>
              <Link
                href={event.link_to_pdf}
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
                underline="hover"
              >
                VIEW SOURCE
              </Link>
            </Grid>
          </Grid>
        </ChronologyCard>
      </Stack>
    </Box>
  );
};

// Exporting the ChronologyEvent component as default
export default ChronologyEvent;
