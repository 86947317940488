import React from "react";

import { Grid } from "@mui/material";

import Stats from "../../../pages/dashboards/Default/Stats";
import { green, red } from "@mui/material/colors";

function Reporting() {
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={12} md={6} lg={3} xl>
        <Stats
          title="Patients Pending Review"
          amount="23"
          chip="Today"
          percentagetext="+26%"
          percentagecolor={green[500]}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3} xl>
        <Stats
          title="Patients in Review"
          amount="12"
          chip="Today"
          percentagetext="-14%"
          percentagecolor={red[500]}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3} xl>
        <Stats
          title="Care Plans Approved"
          amount="17"
          chip="Monthly"
          percentagetext="+18%"
          percentagecolor={green[500]}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3} xl>
        <Stats
          title="Patients Discharged"
          amount="45"
          chip="Monthly"
          percentagetext="-9%"
          percentagecolor={red[500]}
        />
      </Grid>
    </Grid>
  );
}

export default Reporting;
