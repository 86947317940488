import { Box, Typography } from "@mui/material";

function History(patient: any) {
  return (
    <Box mb={6} mt={5}>
      <Typography variant="h4" gutterBottom>
        Summary
      </Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        {patient.patient.mdtRationale}
      </Typography>

      <Typography sx={{ mb: 2 }} variant="body1">
        {patient.patient.pmh}
      </Typography>
      {/* <Typography variant="h4" gutterBottom>
        Surgical History
      </Typography> */}
      <Typography sx={{ mb: 2 }} variant="body1">
        {patient.patient.psh}
      </Typography>
      {/* <Typography variant="h4" gutterBottom>
        Social History
      </Typography> */}
      <Typography variant="body1">{patient.patient.socialHistory}</Typography>
    </Box>
  );
}

export default History;
