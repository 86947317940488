//react imports
import React from "react";

//MUI imports
import { Box, Card } from "@mui/material";
import { DataGridPro, GridEventListener } from "@mui/x-data-grid-pro";
import EventIcon from "@mui/icons-material/Event";

//Project imports
import CardTitle from "../card/CardTitle";
import Tag from "../chronology/Tag";

const KeyDates = () => {
  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    console.log(params.id);
  };

  return (
    <Card>
      <CardTitle title="Key Dates">
        <EventIcon color="action" />
      </CardTitle>
      <Box sx={{ px: 5, py: 5 }}>
        <DataGridPro
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          hideFooter
          hideFooterPagination
          hideFooterRowCount
          hideFooterSelectedRowCount
          rows={[
            {
              id: 1,
              event: "GP SAR Response Due",
              date: "2024-09-29",
              owner: "Dr Emily Carter",
              status: "Pending",
              flag: {
                name: "High Priority",
                id: 1,
                bgcolor: "#fcd6c3",
                color: "#6e3619",
              },
            },
            {
              id: 2,
              event: "Trust SAR Response Due",
              date: "2024-09-29",
              owner: "Dr Emily Carter",
              status: "Pending",
              flag: {
                name: "High Priority",
                id: 1,
                bgcolor: "#fcd6c3",
                color: "#6e3619",
              },
            },
            {
              id: 3,
              event: "Discovery Submission Due",
              date: "2024-10-05",
              owner: "Dr. Susan Harper",
              status: "Upcoming",
              flag: {
                name: "Moderate",
                id: 1,
                bgcolor: "#CCE2D9",
                color: "#005a30",
              },
            },
            {
              id: 4,
              event: "Medical Expert Report Due",
              date: "2024-10-07",
              owner: "Dr Carter",
              status: "Complete",
              flag: {
                name: "High Priority",
                id: 1,
                bgcolor: "#fcd6c3",
                color: "#6e3619",
              },
            },
            {
              id: 5,
              event: "Physio Assessment Follow-Up",
              date: "2024-10-10",
              owner: "John Wilkinson",
              status: "Overdue",
              flag: {
                name: "Critical",
                id: 1,
                bgcolor: "#D31411",
                color: "#ffffff",
              },
            },
            {
              id: 6,
              event: "Final Report Submission",
              date: "2024-10-15",
              owner: "Dr. Mark Evans",
              status: "Pending",
              flag: {
                name: "Moderate",
                id: 1,
                bgcolor: "#CCE2D9",
                color: "#005a30",
              },
            },
          ]}
          columns={[
            { field: "event", headerName: "Event", flex: 2 },
            {
              field: "date",
              headerName: "Date",

              type: "date",
              valueFormatter: (params) => {
                return params ? new Date(params).toLocaleDateString() : "N/A";
              },
            },
            { field: "owner", headerName: "Owner", flex: 1 },
            { field: "status", headerName: "Status", flex: 1 },
            {
              field: "flag",
              headerName: "Flag",
              flex: 1,
              renderCell: (params) => {
                const { row } = params;
                return (
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Tag
                      id={row.flag.id}
                      name={row.flag.name}
                      color={row.flag.color}
                      bgcolor={row.flag.bgcolor}
                      fullWidth={true}
                    />
                  </Box>
                );
              },
            },
          ]}
          onRowClick={handleRowClick}
          sx={{}}
        />
      </Box>
    </Card>
  );
};

export default KeyDates;
