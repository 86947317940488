import React from "react";

import { Box, Stack, Typography } from "@mui/material";
import { AiIcon } from "../../../../icons/TLSvgIcons";

const Content = () => {
  return (
    <div>
      <p>
        The main priorities for this patient are optimal symptomatic management,
        discussing goals of care, advance care planning, and providing her with
        support to maximise her remaining quality of life through a palliative
        care approach given her advanced illnesses. Close communication between
        her oncology, pulmonary, and palliative care teams will be essential.
      </p>

      <h3>Possible interventions to reduce risks:</h3>
      <ul>
        <li>
          Smoking cessation counsellilng/therapy, even at this stage, can
          provide some benefit.
        </li>
        <li>Pulmonary rehabilitation to optimise remaining lung function.</li>
        <li>
          Nutritional support for weight maintenance during cancer treatment.
        </li>
        <li>
          Careful medication review to ensure no drugs worsen respiratory
          status.
        </li>
        <li>
          Advance care planning to ensure her wishes regarding code status,
          intubation, etc. are documented.
        </li>
        <li>
          Hospice care evaluation if she opts to pursue comfort measures only.
        </li>
      </ul>
    </div>
  );
};

function DraftCarePlan01() {
  return (
    <Box>
      <Stack direction="row" spacing={1}>
        <Typography variant="h4">Draft Care Plan</Typography>
        <Box>
          <AiIcon />
        </Box>
      </Stack>
      <Box
        sx={{
          background: "#FFFBF7",
          border: " 1px solid rgb(255, 206, 162)",
          borderRadius: "5px",
          color: "black",
          px: 4,
          py: 2,
        }}
      >
        <Content />
      </Box>
    </Box>
  );
}

export default DraftCarePlan01;
