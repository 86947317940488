import * as React from "react";

import { Box, Typography } from "@mui/material";

import * as icons from "../../../../patient/timeline/TimelineIcons";
import ReportCard from "./ReportCard";
// import ChestXray from "../../../../../assets/images/effusion-left.jpg";
import ChestXray from "../../../../../assets/images/chestXray.jpeg";
import ChestCT from "../../../../../assets/images/janeDoeCT.png";

import EKG from "../../../../../assets/images/ekg.png";
// import PETScan from "../../../../../assets/images/cardiac-pet-scan.png";

const MDTReports01 = () => {
  const letterText = `I am writing to request a review of my patient, Mrs. Jane Doe, by Ramsay’s Multidisciplinary Team (MDT) for further evaluation and consideration of palliative care.

  Jane is a 68-year-old female with a history of Stage IV non-small cell lung cancer, chronic obstructive pulmonary disease (COPD), and type 2 diabetes mellitus. During her recent clinic visit, she reported progressive worsening of her shortness of breath, now present at rest, and increased dependence on inhalers.
  
  Physical Examination:
  - General Appearance: Ill-appearing, dyspneic, and pale
  - Vital Signs:
    - Heart Rate: 95 bpm, regular
    - Blood Pressure: 104/68 mmHg
    - Respiratory Rate: 24 breaths/min with audible wheezing
    - Oxygen Saturation: 85% on room air
    - Weight: 171 lbs, BMI: 36
  - Respiratory Exam:
    - Decreased breath sounds bilaterally
    - Prolonged expiratory phase
    - Diffuse wheezing and crackles
  - Cardiovascular Exam: Tachycardic
  - Oedema: Pitting oedema of the lower extremities to mid-shin
  
  Jane's condition has deteriorated significantly, and she is now experiencing respiratory distress at rest, likely due to the progression and/or metastasis of her lung cancer, exacerbated by her underlying COPD.
  
  Given Jane's advanced stage of lung cancer, multiple comorbidities, and declining functional status, I kindly request a comprehensive review by the MDT to explore potential treatment options, including palliative care services, to ensure her comfort and quality of life.
  
  
  Thank you for your attention to this matter. Please do not hesitate to contact me if you require any additional information.
  `;
  const pulmonaryText = `I am writing regarding our shared patient, Jane Doe, a 68-year-old female with Stage IV non-small cell lung cancer who has had significant disease progression despite multiple lines of therapy.

  Jane initially presented in June 2022 with cough, weight loss, and a 5.2 cm left upper lobe mass. She underwent left upper lobectomy which showed pT3N2M0 moderately differentiated adenocarcinoma. Adjuvant chemotherapy with cisplatin/pemetrexed and radiation were completed in early 2023. 
  
  Unfortunately, her cancer recurred within months. She received second-line chemotherapy with docetaxel but had to discontinue due to toxicity after 4 cycles. CT imaging from 15/05/2024 shows:
  
  - 7.2 cm left lower lobe mass 
  - 4.1 cm right upper lobe mass
  - Numerous bilateral pulmonary nodules
  - Mediastinal lymphadenopathy
  - Liver lesion concerning for metastasis 
  
  Her functional status has deteriorated significantly with increasing shortness of breath at rest, hypoxia requiring supplemental oxygen, poor oral intake, and an ECOG performance of 3-4.  
  
  Given her worsening clinical status and progressive disease refractory to standard chemotherapies, I recommend Jane's case be discussed at the next Multidisciplinary Team meeting. Palliative treatment options, including palliative radiotherapy, should be explored to provide symptom relief. Evaluation for any appropriate clinical trials in the UK testing novel agents for advanced non-small cell lung cancer would also be reasonable.  
  
  Please let me know if any additional information is needed regarding Jane's care. I appreciate your attention to this complex case.
  
`;
  const labsText = `
  Comments:
The results show anemia, elevated inflammatory markers, uncontrolled diabetes, and significantly elevated tumour markers consistent with Stage IV non-small cell lung cancer

Complete Blood Count:
- WBC: 12.5 x 109/L (4.0 - 11.0 x 109/L)	[H]
- RBC: 3.2 x 1012/L (3.8 - 5.4 x 1012/L) 	[L]
- Hemoglobin: 9.8 g/dL (12.0 - 15.5 g/dL)	[L]
- Hematocrit: 30.2% (36.0 - 48.0%)		[L]
- Platelets: 520 x 109/L (150 - 450 x 109/L)	[H]

Coagulation Studies:
- PT: 12.5 sec (9.0 - 12.5 sec)
- PTT: 29.8 sec (25.0 - 35.0 sec)  

Chemistry 20:
- Sodium: 138 mmol/L (135 - 145 mmol/L)
- Potassium: 4.2 mmol/L (3.5 - 5.0 mmol/L)
- Chloride: 102 mmol/L (98 - 107 mmol/L)  
- CO2: 26 mmol/L (21 - 32 mmol/L)
- Anion Gap: 10 (7 - 16)
- BUN: 22 mg/dL (6 - 20 mg/dL)
- Creatinine: 1.2 mg/dL (0.5 - 1.2 mg/dL)
- eGFR: 45 mL/min (>60 mL/min)		[L]
- Glucose: 212 mg/dL (65 - 99 mg/dL)	[H]
- Calcium: 8.5 mg/dL (8.6 - 10.3 mg/dL)
- 
- HbA1c: 8.2% (4.0 - 6.0%)			[H]

Urinalysis:
- Glucose: 3+ (Negative)			[H]
- Ketones: Negative  
- Protein: 1+ (Negative)		[H]
- Blood: Negative
- pH: 6.0 (5.0 - 8.0)

Non-Small Cell Lung Cancer Markers:
- CEA: 125 ng/mL (0 - 5 ng/mL)
- SCC: 4.2 ng/mL (0.0 - 1.5 ng/mL)  
- CYFRA 21-1: 19.5 ng/mL (0.0 - 3.3 ng/mL)
- SLX: 85 U/mL (0 - 65 U/mL)
- CA19-9: 520 U/mL (0 - 37 U/mL)


`;

  const ekgText = `
Indications: Shortness of breath, tachycardia
Procedure: 12-lead resting electrocardiogram
Findings: Sinus tachycardia with a ventricular rate of 106 bpm Normal axis Normal PR interval Normal QRS duration No pathologic Q waves No ST segment elevation or depression No evidence of acute or ongoing ischemic changes Normal R wave progression Normal QTc interval
Impression:
1. Sinus tachycardia, likely physiologic response to respiratory distress
2. No electrocardiographic evidence of acute or ongoing myocardial ischemia or infarction
Recommendations:
Repeat EKG as needed to evaluate further clinical changes
Proceed with additional diagnostic testing as clinically indicated to evaluate underlying lung disease
`;

  const xRayText = `
Indication: Shortness of breath, hypoxia

Findings:
- Low lung volumes with flattening of the hemidiaphragms
- Blunting of the left costophrenic angle
- Left apical pleural capping
- Shift of the mediastinum and trachea to the left
- Patchy opacities in the left lung base and right mid/upper lung zones
- Right perihilar opacity/mass
- Ill-defined left hilar prominence

Impression:
1. Findings consistent with extensive left lung disease and probable left pleural effusion
2. Right perihilar mass
3. Diffuse patchy opacities, likely representing pulmonary metastases
4. Mediastinal shift to the left, consistent with prior left lung surgery
Overall appearance is consistent with progressive malignant disease in the setting of known Stage
IV non-small cell lung cancer status post chemotherapy and radiation.

Recommendations:
- Correlate with recent CT imaging
- Consider thoracentesis if symptomatic pleural effusion
- Follow up with oncology for further evaluation and management
`;
  const radiationText = `
I was asked to evaluate Jane Doe (DOB 02/03/1956, NHS# 456 789 1234) regarding consideration of palliative radiation therapy for her widely metastatic non-small cell lung cancer.

This 68-year-old female was diagnosed in 2022 with stage IIIA lung adenocarcinoma and underwent left upper lobectomy followed by adjuvant chemoradiation. Unfortunately, she had rapid disease progression and now has a large left lower lobe primary tumour, right upper lobe mass, extensive bilateral pulmonary nodules, mediastinal lymphadenopathy, and liver metastases.

Despite multiple lines of systemic therapy, her functional status continues to decline with worsening shortness of breath, hypoxia, poor oral intake, and poor performance status (ECOG 3-4). Palliative interventions should be considered to improve her quality of life.
 
Potential radiation treatment options include:

1. Palliative external beam radiation (10-20 Gy in 5-10 fractions) to the large left lower lobe mass to help relieve her symptoms of dyspnea and chest pain.

2. Stereotactic body radiation therapy (SBRT) to the isolated right upper lobe mass and/or oligometastatic liver lesion.  

3. Endobronchial brachytherapy to help relieve bronchial obstruction and improve airway patency if needed.

4. Palliative radiation (20-30 Gy in 5-10 fractions) to symptomatic sites of osseous metastases if present.

I would recommend proceeding with a palliative course of radiation therapy focused on the sites causing the most severe symptoms. Close multidisciplinary collaboration is essential to osing her quality of life.

Please let me know if any other information is needed regarding potential radiation treatment for Ms. Doe.
`;
  const pathologyText = `
Specimen: Left Upper Lobe Lung Resection

Clinical History: 62-year-old female with 60 pack-year smoking history presenting with cough and body weight loss. CT scan showed a 5.2 cm mass in the left upper lobe.

Gross Description: The specimen is received fresh consisting of a 16.5 x 11.0 x 5.5 cm left upper lobe of lung with attached pleural surface. Sectioning reveals a firm, tan-yellow, stellate mass measuring 5.2 x 4.8 x 4.0 cm. The mass appears to invade the visceral pleura.  

Microscopic Description: Sections show a moderately differentiated non-small cell lung carcinoma, predominately composed of glandular and solid patterns with focal areas of keratinization. The tumour cells have large nuclei with prominent nucleoli and moderate amounts of eosinophilic cytoplasm. Mitotic figures are readily identified. The tumour extends to the visceral pleural surface. Lymphovascular invasion is present. 

tumour Stage: pT3 pN2 (Level 7 lymph nodes positive, 2/12) M0  

Final Diagnosis: 
- Moderately differentiated invasive adenocarcinoma of the lung, 5.2 cm
- Pathologic stage IIIa (T3N2M0)  
- Vascular/lymphatic invasion present
- Margins involved by tumour (visceral pleural margin)

Comment: The findings are consistent with an advanced, locally invasive non-small cell lung cancer. Adjuvant chemotherapy is recommended given the nodal involvement and margin positivity.

`;
  const ctText = `
  Clinical History: 68-year-old female with Stage IV non-small cell lung cancer, status post left upper lobectomy in 2022 followed by chemotherapy and radiation. Now with increasing shortness of breath.

Technique: CT scan of the chest with and without IV contrast.
Findings:
Chest: 
- Status post left upper lobectomy with postoperative volume loss and shift of mediastinum to the left
- Large heterogeneous mass in the left lower lobe measuring 7.2 x 6.5 x 5.8 cm with areas of necrosis
- Bulky left hilar and subcarinal lymphadenopathy up to 3.5 cm
- Right upper lobe mass measuring 4.1 x 3.7 cm 
- Numerous bilateral pulmonary nodules ranging from 5-15 mm
- Moderate left pleural effusion with compressive atelectasis
- No significant mediastinal or pericardial lymphadenopathy

Abdomen:
- Hypodense lesion in liver segment 8 measuring 2.7 cm, consistent with metastasis
- Slightly enlarged adrenal glands bilaterally 

Impression:
1. Progression of non-small cell lung cancer with:
    - Left lower lobe primary mass
    - Right upper lobe mass
    - Numerous bilateral pulmonary nodules
    - Hilar and mediastinal lymphadenopathy 
    - Left pleural effusion
2. Liver lesion concerning for metastasis
3. Findings consistent with Stage IV (T4 N3 M1c) disease

Recommendations: Correlate with clinical status. Oncology follow up for consideration of further systemic therapy and/or palliative treatment options.
`;
  return (
    <Box>
      <Typography variant="h4" sx={{ pl: 2, mt: 4, mb: 4 }}>
        Report Timeline
      </Typography>
      {/* <Typography variant="body2" sx={{ pl: 2, mb: 1 }}>
        January 20th, 2019
      </Typography> */}
      <ReportCard
        title={new Date("2024-05-14").toLocaleDateString() + " -  Chest X-Ray"}
        content={xRayText}
        color="#777777"
        bgColor="#F8F9FC"
        speciality="Consultant Radiologist"
        icon={<icons.XrayIcon />}
        picture={ChestXray}
        open={false}
        key={1}
        date="2024-05-14"
        time="13:00"
        by="Dr Mathew Jones"
        url="https://viewer.dev.jabs.ai/viewer/dicomjson?url=https://viewer.dev.jabs.ai/LIDC-IDRI-0001.json"
      />
      <ReportCard
        title={new Date("2024-05-15").toLocaleDateString() + " -  Chest CT"}
        content={ctText}
        color="#777777"
        bgColor="#F8F9FC"
        speciality="Consultant Radiologist"
        icon={<icons.XrayIcon />}
        picture={ChestCT}
        open={false}
        key={2}
        date="2024-05-15"
        time="13:00"
        by="Dr. Sarah Williams"
        url="https://viewer.dev.jabs.ai/viewer/dicomjson?url=https://viewer.dev.jabs.ai/LIDC-IDRI-0001.json"
      />
      <ReportCard
        title={
          new Date("2024-05-20").toLocaleDateString() + "-  Pulmonary Consult"
        }
        content={pulmonaryText}
        color="#777777"
        bgColor="#F8F9FC"
        icon="LungIcon"
        speciality="Pulmonary Oncologist"
        open={false}
        key={3}
        date="2024-05-20"
        time="13:00"
        by="Dr Samantha Lee"
      />
      <ReportCard
        title={
          new Date("2022-05-22").toLocaleDateString() +
          " -  Oncology | Radiation Therapy"
        }
        content={radiationText}
        color="#777777"
        bgColor="#F8F9FC"
        icon="OncologyIcon"
        speciality="Oncology"
        open={false}
        key={4}
        date="2024-05-22"
        time="13:00"
        by="Dr. Robert Singh"
      />
      <ReportCard
        title={
          new Date("2024-05-24").toLocaleDateString() + "-  GP Visit & Referral"
        }
        content={letterText}
        color="#777777"
        bgColor="#F8F9FC"
        speciality="G.P."
        icon="medical_services"
        open={false}
        key={5}
        date="2024-05-24"
        time="13:00"
        by="Dr Elise Dallas"
      />
      <ReportCard
        title={new Date("2024-05-24").toLocaleDateString() + " - EKG"}
        content={ekgText}
        bgColor="#F8F9FC"
        speciality="Cardiologist"
        icon={<icons.MonitorHeart />}
        picture={EKG}
        open={false}
        key={6}
        date="2024-05-24"
        time="13:00"
        by="Dr. Rajesh Kumar"
        url="https://viewer.dev.jabs.ai/viewer/dicomjson?url=https://viewer.dev.jabs.ai/LIDC-IDRI-0001.json"
      />
      <ReportCard
        title={
          new Date("2022-05-25").toLocaleDateString() + " -  Surgical Pathology"
        }
        content={pathologyText}
        color="#777777"
        bgColor="#F8F9FC"
        icon="biotech"
        speciality="Surgical Pathologist"
        open={false}
        key={7}
        date="2024-05-25"
        time="13:00"
        by="Dr. Robert Singh"
      />
      <ReportCard
        title={new Date("2024-05-25").toLocaleDateString() + "-  Labs"}
        content={labsText}
        color="#777777"
        bgColor="#F8F9FC"
        icon="science"
        speciality="Laboratory"
        open={false}
        key={8}
        date="2024-05-25"
        time="13:00"
        by="The Doctors Laboratory"
      />
    </Box>
  );
};

export default MDTReports01;
