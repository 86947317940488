import React from "react";

import { Box, Stack, Typography } from "@mui/material";

import AiApprovalBox from "../../../../aiBox/AiApprovalBox";
import PictureSwitch from "../../../../patient/mdt/PictureSwitch";
import NursePic from "../../../../../assets/images/avatars/nurse.jpg";

import MarkPic from "../../../../../assets/images/avatars/MarkAppleby.jpg";
import DCashPic from "../../../../../assets/images/avatars/mr-david-cash.jpg";

import IvanovaPic from "../../../../../assets/images/avatars/dobrinka-ivanova.png";
import NickPic from "../../../../../assets/images/avatars/NickTaylor2.png";
// import GuestPic from "../../../../../assets/images/avatars/guest-user.jpg";
import ScottPic from "../../../../../assets/images/avatars/Scott.png";

const Content = () => {
  return (
    <div>
      <ul>
        <li>
          Preoperative cardiology clearance - Given his cardiac history, further
          risk assessment and optimisation by a cardiologist was conducted,
          including a PET scan and prehabilitation.
        </li>
        <li>
          Smoking cessation - He was encouraged to quit smoking at least 4-6
          weeks before surgery could help reduce his pulmonary and cardiac
          risks, and he reports he’s cut back his smoking considerably.
        </li>
        <li>
          Prehab physical therapy - Physiotherapy was begun 2 weeks prior to his
          surgical date to optimise his functional status preoperatively.
        </li>
        <li>
          Regional anaesthesia - Use of spinal or epidural anaesthesia may
          reduce some cardiac/pulmonary risks vs general anaesthesia, and this
          has been discussed with the patient.
        </li>
        <li>
          VTE prophylaxis - Use of mechanical and pharmacological prophylaxis to
          prevent blood clots is planned.
        </li>
      </ul>
    </div>
  );
};

function CarePlanAssesment() {
  return (
    <Box sx={{}}>
      <Typography variant="h4" sx={{ mb: 1 }}>
        Pre-Op Care Plan
      </Typography>
      <Stack
        direction="row"
        spacing={0}
        alignItems="center"
        flexWrap="wrap"
        mb={2}
      >
        <PictureSwitch checked={false} picture={ScottPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={DCashPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={MarkPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={IvanovaPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={NickPic}></PictureSwitch>
        <PictureSwitch checked={false} picture={NursePic}></PictureSwitch>
      </Stack>

      <AiApprovalBox approved={false} approvedBy="" content={<Content />} />
    </Box>
  );
}

export default CarePlanAssesment;
