import React from "react";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  Collapse,
  Divider,
  Typography,
} from "@mui/material";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReportCard from "../patientView/reports/ReportCard";
import { AiIcon } from "../../../icons/TLSvgIcons";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const RiskMatrixContainer = styled(Box)(({ theme }) => ({
  display: "grid",

  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
  gridTemplateRows: "69px 69px 69px 69px 69px 69px",
}));

const RiskMatrixCell = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  padding: 5,
}));
const VTE = () => {
  const content = `
  ### DVT: 2 ((Moderate Probability)
  - Reduced mobility —> 1
  - Obesity —> 1

  ### PE: 4 (Moderate Probability)
  - Reduced mobility —> 1
  - PE is the most likely diagnosis —> 3

  ### Summary
  The patient has a moderate probability of both DVT and PE due to reduced mobility and obesity, with additional consideration of his high risk for PE. Given his complex medical history, including cardiac and respiratory risks, careful monitoring and prophylactic measures are essential.

  `;
  return (
    <ReportCard
      title="VTE Risk Assessment: Moderate"
      content={content}
      open={false}
      padding={2}
    />
  );
};
const ASA = () => {
  const content = `A serious condition that has an impact on a person's overall health`;
  return (
    <ReportCard title="ASA: 3" content={content} open={false} padding={2} />
  );
};

const CFS = () => {
  const content = `
### Clinical Frailty Scale (CFS): 4

(Vulnerable)

The patient demonstrates evidence of moderate frailty. He may require some assistance with instrumental activities of daily living (IADLs) or mobility tasks due to his history of cardiac complications, obesity, tobacco use, reduced mobility, and advanced age. These factors increase his risk of perioperative complications and functional decline. Therefore, proactive interventions and close monitoring are essential to optimise his perioperative care and minimise adverse outcomes.
  `;
  return (
    <ReportCard
      title="Clinical Frailty Scale (CFS): 4"
      content={content}
      open={false}
      padding={2}
    />
  );
};

const CCI = () => {
  const content = `
### Charlson Comorbidity Index (CCI): 4 points
### Estimated 10-year mortality: 53%

- INPUTS: Age —> 3 = 70–79 years
- Myocardial infarction —> 1 = Yes
- CHF —> 0 = No
- Peripheral vascular disease —> 0 = No
- CVA or TIA —> 0 = No
- Dementia —> 0 = No
- COPD —> 0 = No
- Connective tissue disease —> 0 = No
- Peptic ulcer disease —> 0 = No
- Liver disease —> 0 = None
- Diabetes mellitus —> 0 = None or diet controlled
- Hemiplegia —> 0 = No
- Moderate to severe CKD —> 0 = No
- Solid u —> 0 = None
- Leukemia —> 0 = No
- Lymphoma —> 0 = No
- AIDS —> 0 = No
  `;
  return (
    <ReportCard
      title="Charlson Comorbidity Index: 4"
      content={content}
      open={false}
      padding={2}
    />
  );
};

const RiskAssessment02 = () => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const comboHeader = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 3,
      }}
    >
      <AiIcon />
      Draft Risk Assessment
    </Box>
  );

  return (
    <React.Fragment>
      <Typography variant="h4" gutterBottom>
        Risks
      </Typography>
      <Card variant="outlined" sx={{ mb: 2, borderColor: "#FFCEA2" }}>
        <CardHeader
          sx={{
            backgroundColor: "#FFFBF7",
            color: "#b35500",
            p: 2,
          }}
          title={comboHeader}
          action={
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider />
          <RiskMatrixContainer>
            <RiskMatrixCell sx={{ bgcolor: "primary.main" }}></RiskMatrixCell>
            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                INSIGNIFICANT
              </Typography>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                (1)
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                MINOR
              </Typography>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                (2)
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                SIGNIFICANT
              </Typography>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                (3)
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                MAJOR
              </Typography>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                (4)
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                SEVERE
              </Typography>
              <Typography
                sx={{ lineHeight: 1, fontSize: "10px", fontWeight: 800 }}
              >
                (5)
              </Typography>
            </RiskMatrixCell>

            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                ALMOST CERTAIN (5)
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FEFE00",
              }}
            ></RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FFA500",
              }}
            ></RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FF0000",
              }}
            ></RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#AA0000",
              }}
            ></RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#AA0000",
              }}
            ></RiskMatrixCell>

            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                LIKELY (4)
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FEFE00",
              }}
            ></RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FEFE00",
              }}
            ></RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FFA500",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                FRAILTY
                <br />
                OBESITY
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FF0000",
              }}
            ></RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#AA0000",
              }}
            ></RiskMatrixCell>

            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                MODERATE (3)
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#92D051",
              }}
            ></RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FEFE00",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                LIVING ALONE
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FEFE00",
              }}
            >
              <Typography
                aria-owns={open ? "athritis" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                ARTHRITIS
              </Typography>
              {/* <Popover
              id="arthritis"
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 3 }}>Athritis mitigation</Typography>
            </Popover> */}
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FFA500",
              }}
            ></RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FF0000",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                ISCHEMIA
              </Typography>
            </RiskMatrixCell>

            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                UNLIKELY (2)
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#00be53",
              }}
            ></RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#92D051",
              }}
            ></RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FEFE00",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                INFECTION <br />
                DECUBITI
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FEFE00",
              }}
            ></RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FFA500",
              }}
            ></RiskMatrixCell>

            <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                RARE (1)
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#00be53",
              }}
            ></RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#00be53",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                OXYGENATION
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#92D051",
              }}
            ></RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FEFE00",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                BLOOD LOSS
              </Typography>
            </RiskMatrixCell>
            <RiskMatrixCell
              sx={{
                borderBottom: "1px solid #0000001F",
                borderRight: "1px solid #0000001F",
                backgroundColor: "#FEFE00",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1.2,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                P-EMBOLISM
              </Typography>
            </RiskMatrixCell>
          </RiskMatrixContainer>
        </Collapse>
      </Card>
      <CCI />
      <VTE />
      <CFS />
      <ASA />
    </React.Fragment>
  );
};

export default RiskAssessment02;
