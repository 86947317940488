import { Box } from "@mui/material";
import Procedure02 from "./procedures/Procedure02";
import ProcedureEmpty from "./procedures/ProcedureEmpty";

const ProcedureContent = (thisPatient: any) => {
  switch (thisPatient.id) {
    case 1:
      return <ProcedureEmpty />;
    case 2:
      return <Procedure02 />;
    default:
      return <ProcedureEmpty />;
  }
};

const Procedure = (patient: any) => {
  return <Box mb={8}>{ProcedureContent(patient.patient)}</Box>;
};

export default Procedure;
