import React from "react";
import { useDispatch } from "react-redux";
import { changeEvent } from "../../redux/slices/event";

import { Box } from "@mui/material";
// import { Search } from "@mui/icons-material";

import ChronologyEvent from "./ChronologyEvent";
import { Timeline } from "../../types/extraction";

// Interface for the component props
interface TimelineProps {
  timeline: Timeline;
  handleOpen: () => void;
}

// Component to render a vertical timeline
const VerticalTimelinePlain = ({ timeline, handleOpen }: TimelineProps) => {
  let content = null;
  const dispatch = useDispatch();

  const handleEventClick = (id: number) => {
    dispatch(changeEvent(id));
    handleOpen();
  };
  // Sort and map the timeline events
  content = (
    <React.Fragment>
      {timeline.map((event: any, index) => {
        // Determine the line type for the event
        var lineType = "middle";

        if (timeline.length === 1) {
          lineType = "single";
        } else if (index === 0) {
          lineType = "top";
        } else if (index === timeline.length - 1) {
          lineType = "bottom";
        }

        // Render the event with the appropriate line type
        return (
          <Box key={index} onClick={() => handleEventClick(event.id)}>
            <ChronologyEvent lineType={lineType} event={event} />
          </Box>
        );
      })}
    </React.Fragment>
  );

  return content;
};

export default VerticalTimelinePlain;
