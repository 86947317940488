import DraftCarePlanEmpty from "./carePlans/DraftCarePlanEmpty";
import DraftCarePlan01 from "./carePlans/DraftCarePlan01";
import DraftCarePlan02 from "./carePlans/DraftCarePlan02";

import { Box } from "@mui/material";

const DraftCarePlanContent = (thisPatient: any) => {
  switch (thisPatient.id) {
    case 1:
      return <DraftCarePlan01 />;
    case 2:
      return <DraftCarePlan02 />;
    default:
      return <DraftCarePlanEmpty />;
  }
};
function DraftCarePlan(patient: any) {
  const thisPatient = patient.patient;
  return <Box mb={6}>{DraftCarePlanContent(thisPatient)}</Box>;
}

export default DraftCarePlan;
