import CommentTimeline from "./CommentTimeline";
import CommentTimelineEmpty from "./CommentTimelineEmpty";

function Comments(patient: any) {
  const thisPatient = patient.patient;

  return (
    <div>
      {thisPatient.id === 2 && <CommentTimeline />}
      {thisPatient.id !== 2 && <CommentTimelineEmpty />}
    </div>
  );
}

export default Comments;
