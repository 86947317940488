const patientCarePlanData = [
  {
    id: 1,
    text: `The main priorities for this patient are optimal symptomatic management, discussing goals of care, advance care planning, and providing her with support to maximise her remaining quality of life through a palliative care approach given her advanced illnesses. Close communication between her oncology, pulmonary, and palliative care teams will be essential.                                                                                                                                                                                                                                                                                                                                                                                                                                                                               Interventions to Consider:                                                              *Smoking cessation counselling/therapy, even at this stage, can provide some benefit.
        *Pulmonary rehabilitation to optimise remaining lung function.
        *Nutritional support for weight maintenance during cancer treatment.
        *Careful medication review to ensure no drugs worsen respiratory status.
        *Advance care planning to ensure her wishes regarding code status, intubation, etc. are documented.
        Hospice care evaluation if she opts to pursue comfort measures only.`,
    points: [
      {
        id: 1,
        text: `Focus on symptomatic management such as smoking cessation therapy, pulmonary rehabilitation, and nutritional support. `,
      },
      {
        id: 2,
        text: `Discuss and document care preferences, including code status and intubation decisions.`,
      },
      {
        id: 3,
        text: `Medication review for potential impacts on respiratory status.`,
      },
      {
        id: 4,
        text: `Consider palliative and hospice care for comfort measures and provide emotional and psychological support.`,
      },
    ],
  },
  {
    id: 2,
    text: `Cardiac complications - With his history of myocardial infarction and stent placement, he is at higher risk of perioperative cardiac events like myocardial infarction or cardiac arrest. His ongoing tobacco use also increases this risk.
    Respiratory complications - His obesity and tobacco use put him at increased risk of postoperative pulmonary complications like pneumonia, prolonged intubation, or respiratory failure.
    Thromboembolic events - His reduced mobility puts him at increased risk of DVT/PE in the postoperative period.
    Surgical site infection - His obesity is a risk factor for wound complications.
    Functional decline - With advanced age and living alone, he is at higher risk of losing independence after surgery.
    Preoperative cardiology clearance - Given his cardiac history, further risk assessment and optimisation by a cardiologist could help reduce his cardiac risk.
    Smoking cessation - Encouraging him to quit smoking at least 4-6 weeks before surgery could help reduce his pulmonary and cardiac risks.
    Prehab physical therapy - Starting PT prior to surgery can help optimise his functional status preoperatively.
    Regional anaesthesia - Use of spinal or epidural anaesthesia may reduce some cardiac/pulmonary risks vs general anaesthesia.
    VTE prophylaxis - Use of mechanical and pharmacological prophylaxis to prevent blood clots.
    Early mobility - Having PT work on ambulation as soon as feasible post-op can prevent deconditioning.
    Discharge planning - Proactive planning for safe discharge destination and follow-up care to help maintain independence.`,
    points: [
      {
        id: 1,
        text: `Assess and mitigate perioperative cardiac risks, including myocardial infarction or cardiac arrest, due to previous myocardial infarction, stent placement, and ongoing tobacco use.`,
      },
      {
        id: 2,
        text: `Address increased risk of postoperative pulmonary complications such as pneumonia, prolonged intubation, or respiratory failure due to obesity and tobacco use.`,
      },
      {
        id: 3,
        text: `Implement measures to prevent DVT/PE in the postoperative period due to reduced mobility.`,
      },
      {
        id: 4,
        text: `Plan interventions to mitigate the risk of functional decline post-surgery, considering his advanced age and living situation.`,
      },
    ],
  },
  {
    id: 3,
    text: `Close monitoring by the neuro-oncology, radiation oncology, pharmacy, rehabilitation services, palliative care, and supportive care teams is crucial. Proactive management of both disease and treatment-related adverse effects is essential.
    The decision must balance efficacy of anti-cancer therapy against the potential for worsening quality of life, while respecting the patient's goals and preferences through a shared decision-making process.                                                                               
    Radiation:
Hippocampal-sparing techniques
Prophylactic anti-seizure medications
Steroids to manage cerebral oedema
Chemotherapy:
Growth factors for myelosuppression
Aggressive anti-emetic, anti-diarrheal regimens
Dose reductions as needed
Multidisciplinary Approach:
Seizure management (anti-epileptics, avoiding precipitants)
VTE prophylaxis
Early rehabilitation (PT/OT/Speech)
Nutritional support
Psycho-oncology for coping strategies
Palliative care involvement
Advance care planning`,
    points: [
      {
        id: 1,
        text: `Close monitoring by the neuro-oncology, radiation oncology, pharmacy, rehabilitation services, palliative care, and supportive care teams is crucial. Proactive management of both disease and treatment-related adverse effects is essential.`,
      },
      {
        id: 2,
        text: `The decision must balance efficacy of anti-cancer therapy against the potential for worsening quality of life, while respecting the patient's goals and preferences through a shared decision-making process.`,
      },
      {
        id: 3,
        text: `Radiation:
            Hippocampal-sparing techniques
Prophylactic anti-seizure medications
Steroids to manage cerebral edema`,
      },
      {
        id: 4,
        text: `Chemotherapy:
                Growth factors for myelosuppression,
                Aggressive anti-emetic, anti-diarrheal regimens;
Dose reductions as needed`,
      },
      {
        id: 5,
        text: `Seizure management (anti-epileptics, avoiding precipitants)`,
      },
      {
        id: 6,
        text: `VTE prophylaxis`,
      },
      {
        id: 7,
        text: `Early rehabilitation (PT/OT/Speech)`,
      },
      {
        id: 8,
        text: `Nutritional support`,
      },
      {
        id: 9,
        text: `Psycho-oncology for coping strategies`,
      },
      {
        id: 10,
        text: `Palliative care involvement`,
      },
      {
        id: 11,
        text: `Advance care planning`,
      },
    ],
  },
  {
    id: 4,
    text: `Frequent interdisciplinary communication and a patient-centred, holistic approach addressing all domains is key. Building a therapeutic alliance, setting realistic goals, providing emotional support, and maximizing functional independence and quality of life should be prioritized.                         Rehabilitation:
    Physical and occupational therapy for mobility, transfers, splinting
    Therapeutic modalities (electrical stimulation, ultrasound)
    Assistive devices and home modifications
    Nursing:
    Frequent repositioning and specialised mattress/cushions
    Bladder management programme (intermittent catheterisation)
    Bowel programme
    Respiratory therapy/incentive spirometry
    Medical Management:
    Anti-spasticity medications (baclofen, tizanidine)
    UTI prevention and treatment
    VTE prophylaxis
    Nutrition support
    Psychological:
    Psychotherapy (CBT, supportive)
    Medication for depression/anxiety if indicated
    Peer support groups
    Caregiver education and respite
    Multidisciplinary Involvement`,
    points: [
      {
        id: 1,
        text: `Frequent interdisciplinary communication and a patient-centred, holistic approach addressing all domains is key. Building a therapeutic alliance, setting realistic goals, providing emotional support, and maximizing functional independence and quality of life should be prioritized.`,
      },
      {
        id: 2,
        text: `Rehabilitation: Physical and occupational therapy for mobility, transfers, splinting; Therapeutic modalities (electrical stimulation, ultrasound); Assistive devices and home modifications`,
      },
      {
        id: 3,
        text: `Nursing: Frequent repositioning and specialised mattress/cushions; Bladder management programme (intermittent catheterisation); Bowel programme; Respiratory therapy/incentive spirometry`,
      },
      {
        id: 4,
        text: `Medical Management: Anti-spasticity medications (baclofen, tizanidine); UTI prevention and treatment; VTE prophylaxis; Nutrition support`,
      },
      {
        id: 5,
        text: `Psychological: Psychotherapy (CBT, supportive); Medication for depression/anxiety if indicated; Peer support groups; Caregiver education and respite`,
      },
    ],
  },
  {
    id: 5,
    text: `The key is a multidisciplinary, patient-centred approach coordinating her complex medical issues while supporting her frail husband as caregiver. Mitigating falls, polypharmacy, and cognitive/functional decline can help her remain at home safely for as long as possible while reducing caregiver strain. Close communication and involvement of community resources is essential.              Cognitive/Functional Assessment:
    Neuropsychological testing to assess deficits
    Occupational therapy home evaluation
    Medication Optimisation:
    Medication reconciliation by pharmacist
    Simplify/consolidate regimen if possible
    Ensure appropriate dosing of Parkinson's medications
    Avoid medications increasing fall/fracture risk
    Fall Prevention:
    Home safety evaluation by PT/OT
    Balance/strength training
    Assistive devices (walker, shower chair)
    Vitamin D/calcium for osteoporosis
    Coordinated Care:
    Involve geriatrics, neurology, pharmacy
    Monitor for medication side effects
    Aspiration precautions
    Nutritional support
    Caregiver education and respite services
    Discuss goals of care
    Community Resources:
    Social work for long-term care planning
    Adult day services for socialization
    Home health aide services
    Meal delivery`,
    points: [
      {
        id: 1,
        text: `The key is a multidisciplinary, patient-centred approach coordinating her complex medical issues while supporting her frail husband as caregiver. Mitigating falls, polypharmacy, and cognitive/functional decline can help her remain at home safely for as long as possible while reducing caregiver strain. Close communication and involvement of community resources is essential.`,
      },
      {
        id: 2,
        text: `Cognitive/Functional Assessment: Neuropsychological testing to assess deficits; Occupational therapy home evaluation`,
      },
      {
        id: 3,
        text: `Medication Optimisation: Medication reconciliation by pharmacist; Simplify/consolidate regimen if possible; Ensure appropriate dosing of Parkinson's medications; Avoid medications increasing fall/fracture risk`,
      },
      {
        id: 4,
        text: `Fall Prevention: Home safety evaluation by PT/OT; Balance/strength training; Assistive devices (walker, shower chair); Vitamin D/calcium for osteoporosis`,
      },
      {
        id: 5,
        text: `Coordinated Care: Involve geriatrics, neurology, pharmacy; Monitor for medication side effects; Aspiration precautions; Nutritional support`,
      },
      {
        id: 6,
        text: `Caregiver education and respite services; Discuss goals of care`,
      },
      {
        id: 7,
        text: `Community Resources: Social work for long-term care planning; Adult day services for socialization; Home health aide services; Meal delivery`,
      },
    ],
  },
  {
    id: 6,
    text: `Close communication and coordination between the cancer team, supportive care services, nutrition, oral care, and psychosocial support is crucial. Aggressively treating his symptoms while providing nutritional support, oral care, and psychosocial interventions can help optimise his care during this intensive phase of cancer treatment.
    Cancer Treatment:
    Antiemetic medications for nausea/vomiting
    Growth factors to mitigate myelosuppression
    Hydration and monitoring for tumour lysis syndrome
    Nutritional Support:
    Nasogastric or gastrostomy tube feeding
    Total parenteral nutrition (TPN) if unable to tolerate enteral feeds
    Aggressive replacement of fluid/electrolyte losses
    Oral Care:
    Topical anaesthetics/coating agents for mucositis pain
    Dental/oral surgery consultation
    Antifungal and antimicrobial mouth rinses
    Psychosocial Support:
    Child life services
    Psycho-oncology counselling
    Tutoring for missed school
    Peer support groups
    Multidisciplinary Involvement:
    Oncologists
    Gastroenterologists
    Nutritionists
    Dentists/Oral surgeons
    Pain management
    Psychologists/Social workers
    Child life specialists`,
    points: [
      {
        id: 1,
        text: `Close communication and coordination between the cancer team, supportive care services, nutrition, oral care, and psychosocial support is crucial. Aggressively treating his symptoms while providing nutritional support, oral care, and psychosocial interventions can help optimise his care during this intensive phase of cancer treatment.`,
      },
      {
        id: 2,
        text: `Cancer Treatment: Antiemetic medications for nausea/vomiting; Growth factors to mitigate myelosuppression; Hydration and monitoring for tumour lysis syndrome`,
      },
      {
        id: 3,
        text: `Nutritional Support: Nasogastric or gastrostomy tube feeding; Total parenteral nutrition (TPN) if unable to tolerate enteral feeds; Aggressive replacement of fluid/electrolyte losses`,
      },
      {
        id: 4,
        text: `Oral Care: Topical anaesthetics/coating agents for mucositis pain; Dental/oral surgery consultation; Antifungal and antimicrobial mouth rinses`,
      },
      {
        id: 5,
        text: `Psychosocial Support: Child life services; Psycho-oncology counselling; Tutoring for missed school; Peer support groups`,
      },
      {
        id: 6,
        text: `Multidisciplinary Involvement: Oncologists; Gastroenterologists; Nutritionists; Dentists/Oral surgeons; Pain management; Psychologists/Social workers; Child life specialists`,
      },
    ],
  },
  {
    id: 7,
    text: `Tight coordination between the heart failure, nephrology, transplant surgery, anaesthesia, pharmacy, and allied health teams is essential. Optimising her CHF and renal status pretransplant, exercising judicious intraoperative management, preventing rejection and infection post-transplant, and providing psychosocial support will be crucial for a successful outcome. Clear communication of risks, benefits, and anticipated rehabilitation course is also very important. With a comprehensive multidisciplinary approach, her comorbidities can potentially be mitigated to proceed with a life-saving heart transplant.                                                                                                                                                                      Pre-Transplant Optimisation:
    Diuresis and optimisation of CHF/volume status
    Nutritional support
    Erythropoietin for anemia of CKD
    Treat/optimise any reversible renal insults
    Psychosocial/financial support evaluation
    Intraoperative Management:
    Meticulous surgical haemostasis
    Lung protective ventilation strategies
    Renal protective strategies (avoid nephrotoxins, fluid optimisation)
    Neurological monitoring
    Post-Transplant Care:
    Tailored immunosuppression to avoid nephrotoxicity
    Antimicrobial prophylaxis regimen
    Frequent monitoring for rejection
    Physical therapy and cardiac rehabilitation
    Psychosocial and adherence support`,
    points: [
      {
        id: 1,
        text: `Tight coordination between the heart failure, nephrology, transplant surgery, anaesthesia, pharmacy, and allied health teams is essential.`,
      },
      {
        id: 2,
        text: `Optimising her CHF and renal status pretransplant, exercising judicious intraoperative management, preventing rejection and infection post-transplant, and providing psychosocial support will be crucial for a successful outcome.`,
      },
      {
        id: 3,
        text: `Pre-Transplant Optimisation: Diuresis and optimisation of CHF/volume status; Nutritional support; Erythropoietin for anemia of CKD; Treat/optimise any reversible renal insults; Psychosocial/financial support evaluation`,
      },
      {
        id: 4,
        text: `Intraoperative Management: Meticulous surgical haemostasis; Lung protective ventilation strategies; Renal protective strategies (avoid nephrotoxins, fluid optimisation); Neurological monitoring`,
      },
      {
        id: 5,
        text: `Post-Transplant Care: Tailored immunosuppression to avoid nephrotoxicity; Antimicrobial prophylaxis regimen; Frequent monitoring for rejection; Physical therapy and cardiac rehabilitation; Psychosocial and adherence support`,
      },
    ],
  },
  {
    id: 8,
    text: `The key is providing aggressive supportive care through a multi-pronged approach to maintain his nutrition/hydration, manage his severe mucositis, initiate speech/swallowing rehab early, and address his symptom burden and psychological needs. Close communication between oncology, surgery, rehab services, nutrition, palliative care, and support services is crucial. Preserving his quality of life should be the main focus through this intensive treatment phase, in addition to treating his cancer. Clearly discussing goals of care is also very important.                                                                                                                                                                                                 Adjuvant Therapy Considerations:
    Chemotherapy (e.g. cisplatin, 5-FU, taxanes)
    Targeted therapies (e.g. cetuximab)
    Immunotherapy (e.g. pembrolizumab, nivolumab)                                                               Nutrition/Hydration Support:
    Nasogastric or gastrostomy tube feeding
    IV fluid hydration
    Vitamin/mineral supplementation
    Airway/Breathing Support:
    Humidified air/oxygen
    Suctioning/pulmonary toilet
    Tracheostomy if needed
    Mucositis/Infection Management:
    Topical anaesthetics/coating agents
    Antimicrobial mouth rinses
    Anti-fungal therapies
    Prophylactic antibiotics
    Speech/Swallowing Therapy:
    Speech language pathology evaluation
    Swallow studies and therapy
    Speaking valve for tracheostomy
    Symptom Management:
    Pain control
    Anti-emetics, anti-diarrheals
    Psycho-oncology counselling `,
    points: [
      {
        id: 1,
        text: `The key is providing aggressive supportive care through a multi-pronged approach to maintain his nutrition/hydration, manage his severe mucositis, initiate speech/swallowing rehab early, and address his symptom burden and psychological needs.`,
      },
      {
        id: 2,
        text: `Close communication between oncology, surgery, rehab services, nutrition, palliative care, and support services is crucial.`,
      },
      {
        id: 3,
        text: `Adjuvant Therapy Considerations: Chemotherapy (e.g. cisplatin, 5-FU, taxanes); Targeted therapies (e.g. cetuximab); Immunotherapy (e.g. pembrolizumab, nivolumab)`,
      },
      {
        id: 4,
        text: `Nutrition/Hydration Support: Nasogastric or gastrostomy tube feeding; IV fluid hydration; Vitamin/mineral supplementation`,
      },
      {
        id: 5,
        text: `Airway/Breathing Support: Humidified air/oxygen; Suctioning/pulmonary toilet; Tracheostomy if needed`,
      },
      {
        id: 6,
        text: `Mucositis/Infection Management: Topical anaesthetics/coating agents; Antimicrobial mouth rinses; Anti-fungal therapies; Prophylactic antibiotics`,
      },
      {
        id: 7,
        text: `Speech/Swallowing Therapy: Speech language pathology evaluation; Swallow studies and therapy; Speaking valve for tracheostomy`,
      },
      {
        id: 8,
        text: `Symptom Management: Pain control; Anti-emetics, anti-diarrheals; Psycho-oncology counselling`,
      },
    ],
  },
  {
    id: 9,
    text: `The key is a coordinated, holistic approach optimising her RA control, perioperative planning for potential surgeries, intensive rehabilitation, and robust psychological/social support. Cultural competency and an interdisciplinary team addressing her medical, surgical, functional, and mental health needs are crucial. Close communication, setting realistic expectations, and engaging the patient and family in a shared decision-making process are also vital for this young woman with signficant healthcare needs.                                                                                                                             Medical Optimisation:
    Aggressive RA treatment with DMARDs, biologics as appropriate
    Prophylactic treatments (antibiotics, anticoagulation)
    Optimise nutrition, diabetes/comorbidity management
    Surgical Planning:
    Judicious perioperative immunosuppression management
    Meticulous aseptic techniques
    Antibiotic and antithrombotic prophylaxis
    Staged procedures if indicated
    Postoperative rehabilitation (PT, OT, assistive devices)
    Psychological Support:
    Counselling for coping with chronic illness
    Cognitive behavioral therapy
    Family therapy, caregiver support
    Integration with cultural beliefs/practices
    Involvement of medical psychology/psychiatry`,
    points: [
      {
        id: 1,
        text: `The key is a coordinated, holistic approach optimising her RA control, perioperative planning for potential surgeries, intensive rehabilitation, and robust psychological/social support.`,
      },
      {
        id: 2,
        text: `Cultural competency and an interdisciplinary team addressing her medical, surgical, functional, and mental health needs are crucial.`,
      },
      {
        id: 3,
        text: `Medical Optimisation: Aggressive RA treatment with DMARDs, biologics as appropriate; Prophylactic treatments (antibiotics, anticoagulation); Optimise nutrition, diabetes/comorbidity management`,
      },
      {
        id: 4,
        text: `Surgical Planning: Judicious perioperative immunosuppression management; Meticulous aseptic techniques; Antibiotic and antithrombotic prophylaxis; Staged procedures if indicated`,
      },
      {
        id: 5,
        text: `Postoperative rehabilitation (PT, OT, assistive devices)`,
      },
      {
        id: 6,
        text: `Psychological Support: Counselling for coping with chronic illness; Cognitive behavioral therapy; Family therapy, caregiver support; Integration with cultural beliefs/practices`,
      },
    ],
  },
  {
    id: 10,
    text: `The key priorities are controlling his acute symptoms, optimising his nutritional status, medical management of the Crohn's flare, and multidisciplinary planning for potential surgical intervention if medical management fails to improve his condition. Close monitoring and a team-based approach will be crucial given his multiple complex issues.

    Potential Interventions:
    *Multidisciplinary management involving gastroenterology, surgery, nutrition, pain service
    *Corticosteroids and biologics to control Crohn's disease flare
    *Parenteral nutrition if unable to tolerate enteral feeds
    *Opioid and non-opioid analgesia for adequate pain control
    *Surgical intervention (resection, strictureplasty) if medical management fails
    *Nutritional prehabilitation if surgery is pursue`,
    points: [
      {
        id: 1,
        text: `The key priorities are controlling his acute symptoms, optimising his nutritional status, medical management of the Crohn's flare, and multidisciplinary planning for potential surgical intervention if medical management fails to improve his condition. Close monitoring and a team-based approach will be crucial given his multiple complex issues.`,
      },
      {
        id: 2,
        text: `Multidisciplinary management involving gastroenterology, surgery, nutrition, pain service`,
      },
      {
        id: 3,
        text: `Corticosteroids and biologics to control Crohn's disease flare`,
      },
      {
        id: 4,
        text: `Parenteral nutrition if unable to tolerate enteral feeds`,
      },
      {
        id: 5,
        text: `Opioid and non-opioid analgesia for adequate pain control`,
      },
      {
        id: 6,
        text: `Surgical intervention (resection, strictureplasty) if medical management fails`,
      },
      {
        id: 7,
        text: `Nutritional prehabilitation if surgery is pursued`,
      },
    ],
  },
  {
    id: 11,
    text: `Given his very complex medical issues and tenuous clinical status, a multi-faceted and interdisciplinary approach is required. Addressing the acute sepsis is the top priority, but careful management of his comorbidities like ESRD and metabolic derangements is essential. Palliative care involvement to evaluate his goals and perform advance care planning should also occur early.  Frequent communication between all teams is crucial for care coordination. Engaging the patient/family in shared decision-making will be vital as well.                                                                                                                           Sepsis Management:
    Source control measures (drainage procedures, nephrolithotomy, etc.)
    Broadest spectrum antibiotics covering all potential sources
    Hemodynamic support with vasopressors, fluids as appropriate
    Multidisciplinary ICU care
    Renal Support:
    Nephrology consultation for management of ESRD/polycystic kidney disease
    Consideration of urgent dialysis initiation
    Avoidance of nephrotoxic medications
    Metabolic/Glycemic Control:
    Tight glucose monitoring and insulin therapy
    Electrolyte, acid-base, and fluid balance management
    Delirium Prevention/Management:
    Frequent reorientation, maintenance of day-night routine
    Minimization of medications that worsen delirium
    Physical/occupational therapy evaluation
    Palliative Care Involvement:
    Goals of care discussion with patient/family
    Pain and symptom management
    Psychosocial, spiritual, and caregiver support
    Hospice referral if pursuing comfort-focused approach`,
    points: [
      {
        id: 1,
        text: `Given his very complex medical issues and tenuous clinical status, a multi-faceted and interdisciplinary approach is required. Addressing the acute sepsis is the top priority, but careful management of his comorbidities like ESRD and metabolic derangements is essential.`,
      },

      {
        id: 3,
        text: `Sepsis Management: Source control measures (drainage procedures, nephrolithotomy, etc.); Broadest spectrum antibiotics covering all potential sources; Hemodynamic support with vasopressors, fluids as appropriate; Multidisciplinary ICU care`,
      },
      {
        id: 4,
        text: `Renal Support: Nephrology consultation for management of ESRD/polycystic kidney disease; Consideration of urgent dialysis initiation; Avoidance of nephrotoxic medications`,
      },
      {
        id: 5,
        text: `Metabolic/Glycemic Control: Tight glucose monitoring and insulin therapy; Electrolyte, acid-base, and fluid balance management`,
      },
      {
        id: 6,
        text: `Delirium Prevention/Management: Frequent reorientation, maintenance of day-night routine; Minimization of medications that worsen delirium; Physical/occupational therapy evaluation`,
      },
      {
        id: 7,
        text: `Palliative Care Involvement: Goals of care discussion with patient/family; Pain and symptom management; Psychosocial, spiritual, and caregiver support; Hospice referral if pursuing comfort-focused approach`,
      },
    ],
  },
];

export default patientCarePlanData;
